import React, { PureComponent } from "react";

import {
  Box,
  Button,
  H1,
  H2,
  Text,
  Divider,
  UI,
  Portal,
} from "../../../../components/Dashboard";

import Crossfade from "../../../../components/Crossfade/Crossfade";

import { UserBox } from "../containers";
import AddUserForm from "../AddUserForm";

class UsersBox extends PureComponent {
  // this functionality should be moved into own component
  state = { toggle: false };

  toggleModal = () => {
    const { toggle } = this.state;
    this.setState({ toggle: !toggle });
  };

  stop = (event) => {
    event.stopPropagation();
  };

  renderUserList = () => {
    const { allUsers } = this.props;
    return allUsers.map((user) => {
      return (
        <Box key={user.id} marginRight={2} marginBottom={2}>
          <UserBox user={user} />
        </Box>
      );
    });
  };

  render() {
    const { toggle } = this.state;
    const { title, canAddPeople } = this.props;
    return (
      <Box marginBottom={5}>
        <Portal
          isOpen={toggle}
          render={({ isOpen }) => {
            return (
              <Crossfade in={isOpen} timeout={250} unmountOnExit>
                <Box
                  onMouseDown={this.toggleModal}
                  position="fixed"
                  display="flex"
                  justify="center"
                  alignItems="center"
                  width="100%"
                  height="100%"
                >
                  <Box
                    onMouseDown={this.stop}
                    position="relative"
                    minWidth="50%"
                    maxWidth="90%"
                    minHeight="50%"
                    maxHeight="90%"
                    alignSelf="auto"
                    background={UI.colors.darkGray}
                    paddingVertical={4}
                    paddingHorizontal={5}
                    elevated
                  >
                    <Box
                      display="flex"
                      justifyContent="center"
                      flexDirection="column"
                      alignItems="center"
                      position="absolute"
                      top="0"
                      bottom="0"
                      right="0"
                      left="0"
                      zIndex={1}
                      background="rgba(48,48,49,0.8)"
                    >
                      <Text
                        marginBottom={3}
                        paddingHorizontal={4}
                        color="offWhite"
                        semiBold
                        center
                      >
                        You currently access VesselFront via a demo account.
                        Upgrading to a professional account will allow you to
                        invite people from inside and outside of your
                        organization to the Vessel Views of your choice.
                      </Text>
                      <Button secondary onClick={this.toggleModal}>
                        Dismiss
                      </Button>
                    </Box>
                    <H2>Add People</H2>
                    <Divider />
                    <AddUserForm closeOnSubmit={this.toggleModal} />
                  </Box>
                </Box>
              </Crossfade>
            );
          }}
        />
        <Box
          justify="between"
          display="flex"
          small={{ "justify-content": "center" }}
        >
          <H1>{title}</H1>
          {/*{canAddPeople && (*/}
          {/*  <Button rounded secondary onClick={this.toggleModal}>*/}
          {/*    Add People*/}
          {/*  </Button>*/}
          {/*)}*/}
        </Box>
        <Divider />
        <Box
          display="flex"
          width="100%"
          wrap
          small={{ "justify-content": "center" }}
        >
          {this.renderUserList()}
        </Box>
      </Box>
    );
  }
}

export default UsersBox;
