import { connect } from "react-redux";

import { getAssetForDocument } from "../../../../entities/documents/reducers";
import * as actions from "../../../../entities/documents/actions";

import Item from "../components/Item";

const mapStateToProps = (state, ownProps) => {
  const asset = getAssetForDocument(state, ownProps.document.assetId);
  return {
    id: ownProps.document.id,
    displayName: ownProps.document.displayName || asset.filename,
    description: ownProps.document.description,
    url: `https://d2r03fb27n6wbj.cloudfront.net/${
      // eslint-disable-next-line no-useless-escape
      asset.url && asset.url.match(/([^\/]*\/[^\/]*\/[^\/]*\/[^\/]*)$/)[0]
    }`,
    date: ownProps.document.createdAt,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: (values) =>
    dispatch(
      actions.documentUpdateRequest({
        documentId: ownProps.document.id,
        values,
      })
    ),
  onDelete: () =>
    dispatch(
      actions.documentDeleteRequest({
        documentId: ownProps.document.id,
      })
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Item);
