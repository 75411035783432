import dayjs from "dayjs";

// Private constants
export const MONTH_NAMES = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

export const DAY_NAMES = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

// Utility functions

export const getMonthLength = date => {
  const month = date.month();
  switch (month) {
    case 0:
    case 2:
    case 4:
    case 6:
    case 7:
    case 9:
    case 11:
      return 32;
    case 3:
    case 5:
    case 8:
    case 10:
      return 31;
    case 1:
      if (date.isLeap()) {
        return 29;
      } else {
        return 28;
      }
    default:
      return null;
  }
};

export const isSameDate = (date1, date2) => {
  if (date1 && date2) {
    const dateObj1 = date1.toObject();
    const dateObj2 = date2.toObject();
    return (
      dateObj1.years === dateObj2.years &&
      dateObj1.months === dateObj2.months &&
      dateObj1.date === dateObj2.date
    );
  }
  return false;
};

export const isInMonth = (date1, date2) => {
  if (date1 && date2) {
    const dateObj1 = date1.toObject();
    const dateObj2 = date2.toObject();
    return dateObj1.months === dateObj2.months;
  }
  return false;
};

export const getDaysOfMonth = date => {
  const setDate = dayjs()
    .set("month", date.month())
    .set("year", date.year());
  const firstDay = setDate.startOf("month");
  const firstDate = setDate.set("date", 1);

  let days = [];
  for (let i = 0; i < 42; i++) {
    if (i >= firstDay) {
      days.push(firstDate.add(i, "day"));
    } else {
      days.push(firstDate.subtract(firstDate.day() - i, "day"));
    }
  }

  return days;
};
