import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { Box, Text, UI } from "../../../components/Dashboard";
import Image from "../../../components/Image/Image";

import { getThumbnailForScan } from "../../../entities/scans/reducer";

const { colors } = UI;

const StyleLink = styled(Link)`
  color: ${colors.lightblue};
  transition: all 125ms ease-in;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 300;

  @media (max-width: 768px) {
    text-align: center;
    width: 100%;
  }
  &:hover {
    color: ${colors.black};
    text-decoration: none;
  }
`;

class ScanListItem extends PureComponent {
  render() {
    const { img, title, link, active, scan } = this.props;
    return (
      <Box
        display="flex"
        alignItems="start"
        marginBottom={1}
        background={active ? colors.lightGray : colors.darkGray}
        elevated={active}
        medium={{
          flexDirection: 'column',
          width: '100%'
        }}
      >
        <Box
          width={170}
          medium={{
            width: '100%'
          }}
        >
          <Image src={img} noMargin />
        </Box>
        <Box
          display="flex"
          direction="col"
          paddingHorizontal={1}
          paddingVertical={1}
          alignItems="start"
          justify="between"
        >
          <Text
            bold
            color="white"
            medium={{
              textAlign: 'center',
              width: '100%'
            }}
          >
            {title}
          </Text>
          <Text
            feather
            medium={{
              textAlign: 'center',
              width: '100%'
            }}
          >
            {scan.area}m<sup>2</sup>
          </Text>
          {active ? (
            <Text
              feather
              medium={{
                textAlign: 'center',
                width: '100%'
              }}
            >
              Currently Viewing
            </Text>
          ) : (
            <StyleLink to={link}>View</StyleLink>
          )}
        </Box>
      </Box>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { scan, vesselId } = props;
  return {
    img: getThumbnailForScan(state, scan.thumbnail),
    title: scan.title,
    link: `/dashboard/vessels/${vesselId}/${scan.title}`
  };
};

export default connect(mapStateToProps)(ScanListItem);
