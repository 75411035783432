import styled from "styled-components";

const Button = styled.button`
  display: block;
  width: 100%;
  height: 48px;
  border: none;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  color: #fff;
  background: #0b39b2;
  margin: 0;
  margin-top: 56px;
  margin-bottom: 16px;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  transition: all 50ms ease-in-out;
  position: ${props => props.position ? props.position : 'unset'};
  
  &:focus {
    outline: 0;
  }

  &:active {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  }

  &:disabled {
    opacity: 0.75;
    cursor: not-allowed;
  }
`;

export default Button;
