import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Card } from "../../../../components/Dashboard";

import { getType } from "../../../../entities/types/reducer";
import { getFlag } from "../../../../entities/flags/reducer";
import {
  getScanLinks,
  getNumberOfScansForVessel,
  getVesselImage,
  getVesselVisits
} from "../../../../global/rootReducer";

const mapStateToProps = (state, props) => {
  const { history } = props;
  const goTo = () => {
    const path = `/dashboard/vessels/${props.vessel.id}`;
    history.push(path);
  };
  return {
    avatar: getVesselImage(state, props.vessel),
    title: props.vessel.name,
    subTitle:
      getType(state, props.vessel.type) &&
      getType(state, props.vessel.type).description,
    other:
      getFlag(state, props.vessel.flag) &&
      getFlag(state, props.vessel.flag).description,
    actionItems: getScanLinks(state, props.vessel.id),
    moreItems: [
      { name: "Go to vessel", path: `/dashboard/vessels/${props.vessel.id}` },
      { name: "Go to library", path: "/dashboard/library" },
      { name: "Request mapping", path: "" }
    ],
    bottomSheet: [
      {
        description: "Views",
        value: getNumberOfScansForVessel(state, props.vessel.id)
      },
      {
        description: "Visits",
        value: getVesselVisits(state, props.vessel.id)
      }
    ],
    onClickCard: goTo
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(Card)
);
