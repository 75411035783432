import axios from "axios";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { UI } from "../../../components/Dashboard";

import { getVesselById } from "../../../global/rootReducer";

const { colors } = UI;

const Container = styled.div`
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transform: ${props =>
    props.toggle && !props.exit
      ? "translateX(10%) scale(0.75)"
      : props.toggle && props.exit
      ? "translateX(10%) scale(0)"
      : "translateX(0%) scale(1)"};
  transition: transform 0.5s cubic-bezier(0.45, 0.24, 0.66, 0.89);

  & > svg {
    height: 100%;
    width: 100%;
  }

  & > svg .onHover {
    fill: ${colors.offWhite};
  }

  & > svg .onHover:hover {
    fill: ${colors.lightblue};
    cursor: pointer;
  }

  @media (max-width: 768px) and (orientation: portrait) {
    transform: unset;
    width: 100%;
    height: auto;
    transition: unset;
  }
`;

const Ship = ({ onClick, toggle, exit, closeTooltip, ga }) => {
  const [ga2, setGa2] = useState("");
  useEffect(() => {
    axios.get(ga.url).then(res => setGa2(res.data));
  }, [ga]);
  const onClickContainer = event => {
    if (event.target.dataset.viewId) {
      onClick(event, event.target.dataset.viewId);
    } else {
      closeTooltip(event);
    }
  };
  const createMarkup = () => {
    return { __html: ga2 };
  };
  return (
    <Container
      toggle={toggle}
      exit={exit}
      onClick={onClickContainer}
      dangerouslySetInnerHTML={createMarkup()}
    />
  );
};

const mapStateToProps = (state, props) => {
  const vessel = getVesselById(state, props.vesselId);
  const ga = vessel ? vessel.ga : "";
  return {
    ga
  };
};

export default connect(
  mapStateToProps,
  null
)(Ship);
