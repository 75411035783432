import React, { useState, useEffect } from "react";
import DropToUpload from "react-drop-to-upload";
import styled from "styled-components";

import {
  Box,
  H4,
  Button,
  Avatar,
  UI,
  Spinner
} from "../../../../components/Dashboard";
import Input from "../../../../components/Input/Input";

const HelpBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 16px;
  border: 2px dashed ${UI.colors.ghost};
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: ${UI.colors.ghost};
  width: 240px;
`;

const Profile = ({
  fullname,
  email,
  userGroup,
  avatar,
  logo,
  userId,
  updateUser,
  updateAvatar,
  isProfileUpdating
}) => {
  const [inputValues, setInputValues] = useState({});
  useEffect(
    () =>
      setInputValues({
        fullname,
        email,
        userGroup,
        thumb: avatar || logo
      }),
    [fullname, email, userGroup]
  );

  const handleChange = event => {
    setInputValues({
      ...inputValues,
      [event.target.name]: event.target.value
    });
  };

  const handleDropDataURI = (dataURIs, files) => {
    setInputValues({
      ...inputValues,
      thumb: dataURIs[0],
      avatar: files[0]
    });
  };

  const onAvatarSave = () => {
    updateAvatar(userId, {avatar: inputValues.avatar});
  }

  const handleSubmit = () => {
    updateUser(userId, inputValues);
  };

  return (
    <Box display="flex" justify="between" flex="grow" wrap>
      <Box
        display="flex"
        direction="col"
        align="start"
        minWidth={320}
        marginBottom={4}
      >
        <H4 marginBottom={4} semiBold>
          Profile
        </H4>
        <Input
          type="text"
          name="fullname"
          label="Full name"
          onChange={handleChange}
          value={inputValues.fullname}
        />
        <Input
          type="email"
          name="email"
          label="Email"
          onChange={handleChange}
          value={inputValues.email}
        />
        <Input
          type="text"
          name="userGroupId"
          label="Account type"
          disabled
          value={inputValues.userGroup && inputValues.userGroup.description}
        />
        <Button secondary onClick={handleSubmit}>
          {isProfileUpdating ? <Spinner alt small /> : "Update profile"}
        </Button>
      </Box>
      <Box
        display="flex"
        direction="col"
        align="center"
        small="align-items: start;"
      >
        <DropToUpload
          onDropDataURI={(dataURIs, files) =>
            handleDropDataURI(dataURIs, files)
          }
        >
          <Avatar
            avatar={inputValues.thumb}
            marginBottom={2}
            height={160}
            width={160}
          /><HelpBox>
          Drag your image file to the above placeholder to upload your profile
          picture
        </HelpBox>
          <Button marginTop={2} secondary onClick={onAvatarSave}>Save</Button>
        </DropToUpload>
      </Box>
    </Box>
  );
};

export default Profile;
