import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Box, Spinner, Button, Text } from "../../../../components/Dashboard";

import Item from "../containers/Item";

const Results = ({
  getDocuments,
  getMoreDocuments,
  documents,
  isLoading,
  notFound,
  selectedVessels,
  selectedAccounts,
}) => {
  const [prevDocs, setPrevDocs] = useState([]);
  const [curDocs, setCurDocs] = useState([]);
  const [isSelect, setIsSelect] = useState(false);
  useEffect(() => {
    const raccountIds = localStorage.getItem("account_id");
    console.log(raccountIds, "raccountIdsraccountIds");
    if (raccountIds) {
      getDocuments("", raccountIds);
    } else {
      getDocuments();
    }

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(
    () => {
      if (!isSelect) {
        setPrevDocs(curDocs);
      }
      setTimeout(() => {
        setIsSelect(false);
        setCurDocs(documents);
      }, 0);
    },
    [documents]
  );

  useEffect(
    () => {
      setIsSelect(true);
      setPrevDocs([]);
    },
    [selectedVessels, selectedAccounts]
  );

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
      document.documentElement.offsetHeight
    )
      return;
    // getMoreDocuments();
  };

  return (
    <Box display="flex" direction="col" width="100%">
      {documents.map(
        (document) => document && <Item document={document} key={document.id} />
      )}
      <Box marginVertical={2} display="flex" justify="center">
        {isLoading ? (
          <Spinner />
        ) : curDocs.length > prevDocs.length ? (
          <Button rounded inverted small onClick={getMoreDocuments}>
            Load more
          </Button>
        ) : (
          <Text>No documents found for this search</Text>
        )}
      </Box>
    </Box>
  );
};

Results.propTypes = {
  documents: PropTypes.array.isRequired,
  getDocuments: PropTypes.func.isRequired,
  getMoreDocuments: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  notFound: PropTypes.bool.isRequired,
};

export default Results;
