import React, { PureComponent } from "react";
import VirtualList from "react-tiny-virtual-list";
import matchSorter from "match-sorter";
import Downshift from "downshift";

import styled from "styled-components";

import Label from "../Fields/StyledLabel";
import StyledInput from "../Fields/StyledInput";

import arrowDown from "../../assets/images/arrow-down.svg";

const DropdownInput = styled(StyledInput)`
  background-image: url(${arrowDown});
  background-repeat: no-repeat;
  background-size: 16px 8px;
  background-position: calc(100% - 16px) 50%;
  cursor: pointer;
`;

const advancedFilter = (theItems, value) => {
  return value
    ? matchSorter(theItems, value, {
        keys: ["value"],
      })
    : theItems;
};

class Dropdown extends PureComponent {
  state = {
    menuIsOpen: false,
    inputValue: "",
    selectedItem: "",
    items: this.props.options,
  };

  handleStateChange = (changes) => {
    const { options, onChange, name } = this.props;

    if (changes.hasOwnProperty("inputValue")) {
      const { inputValue } = changes;
      this.setState({ inputValue, items: advancedFilter(options, inputValue) });
    }

    if (changes.hasOwnProperty("selectedItem")) {
      const { selectedItem } = changes;
      this.setState({ menuIsOpen: false, selectedItem, items: options });
      if (onChange) {
        onChange(name, selectedItem);
      }
    }
  };

  handleFocus = (event) => {
    event.preventDefault();
    this.setState({
      menuIsOpen: true,
      items: this.props.options,
      inputValue: "",
    });
  };

  handleBlur = (event) => {
    this.closeMenu();
    if (this.props.hasOwnProperty("onBlur")) {
      const { onBlur } = this.props;
      onBlur(event);
    }
  };
  closeMenu = () => {
    this.setState({ menuIsOpen: false });
  };

  itemToString = (item) => {
    return item ? item.value : "";
  };

  render() {
    const {
      label,
      name,
      placeholder,
      value,
      error,
      dashboard,
      changeHeight,
    } = this.props;
    const { menuIsOpen, items, inputValue } = this.state;
    return (
      <Downshift
        isOpen={menuIsOpen}
        inputValue={value ? value.value : '' }
        itemToString={this.itemToString}
        onStateChange={this.handleStateChange}
        itemCount={items && items.length}
      >
        {({
          isOpen,
          getLabelProps,
          getInputProps,
          getItemProps,
          highlightedIndex,
        }) => (
          <div
            style={{
              marginBottom: 24,
              position: "relative",
              width: "100%",
            }}
          >
            <Label {...getLabelProps()} dashboard={dashboard}>
              {label}
            </Label>
            <DropdownInput
              {...getInputProps()}
              error={error}
              name={name}
              placeholder={placeholder}
              onBlur={this.handleBlur}
              onFocus={this.handleFocus}
              onClick={this.handleFocus}
              dashboard={dashboard}
              autoComplete="off"
              autoFill="off"
            />
            {isOpen && (
              <VirtualList
                width="100%"
                height={items.length * 40}
                itemCount={items.length}
                itemSize={40}
                scrollDirection="vertical"
                style={{
                  zIndex: 5,
                  position: "absolute",
                  backgroundColor: "#333",
                  boxShadow: "0px 10px 6px -6px rgba(0, 0, 0, 0.16)",
                  top: 96,
                  borderRadius: 4,
                }}
                renderItem={({ index, style }) => (
                  <div
                    key={items[index].value}
                    {...getItemProps({
                      item: items[index],
                      index,
                      style: {
                        ...style,
                        display: "flex",
                        alignItems: "center",
                        backgroundColor:
                          highlightedIndex === index
                            ? "#656565"
                            : "transparent",
                        paddingLeft: 16,
                        paddingRight: 16,
                        marginBottom: 8,
                        color: "rgb(215, 215, 215)",
                        fontFamily: "'Roboto', sans-serif",
                        fontSize: 14,
                        transition:
                          "background-color 0.25s cubic-bezier(0.45, 0.24, 0.66, 0.89)",
                        cursor: "pointer",
                      },
                    })}
                  >
                    {items[index].value}
                  </div>
                )}
              />
            )}
          </div>
        )}
      </Downshift>
    );
  }
}

export default Dropdown;
