import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Icon from "react-ionicons";
import axios from "axios";
import { URL } from "../../../global/utilities";
import pdfFile from "../../../assets/VF_Model_Outputs.pdf";
import {
  Box,
  H1,
  Dropdown,
  Button,
  Spinner,
  Text,
  UI,
} from "../../../components/Dashboard";
import { DownloadLink } from "../../../components/Dashboard/Typography/Typography";
import { Formik } from "formik";
import { store } from "react-notifications-component";
import {
  getAccountList,
  getVesselList,
  uploadHasError,
  getUploadErrorMsg,
} from "../../../entities/documents/reducers";

const SuccessIcon = styled(Icon).attrs({
  color: UI.colors.white,
  icon: "md-checkmark",
})`
  height: 35px;
  width: 24px;
`;

const FloorsAndModels = ({ accountList, hasError, errorMsg, vessels }) => {
  const [selected, setSelected] = useState();
  const [isUpdate, setIsUpdate] = useState(false);
  const [isFinish, setIsFinish] = useState(false);
  const [selectedVessel, setSelectedVessel] = useState();
  const [selectedView, setSelectedView] = useState();
  const [vesselList, setVesselList] = useState();
  const [modelList, setModelList] = useState();
  const [viewList, setViewList] = useState();
  const [isFormFocus, setIsFormFocus] = useState(false);
  const tok = localStorage.getItem("authToken");
  const changeHeight = true;
  useEffect(() => {
    axios
      .get(`${URL}/secured/model/list`, {
        headers: {
          authorization: tok,
        },
      })
      .then((res) => {
        setModelList(
          res.data.map((model) => {
            model.value = model.name;
            return model;
          })
        );
      });
  }, []);
  useEffect(() => {
    axios
      .get(`${URL}/secured/vessels`, {
        headers: {
          authorization: tok,
        },
      })
      .then((res) => {
        setVesselList(
          res.data.map((vessel) => {
            vessel.value = vessel.name;
            return vessel;
          })
        );
      });
  }, []);

  useEffect(
    () => {
      vesselList &&
        selectedVessel &&
        vesselList.map((vessel) => {
          if (selectedVessel && selectedVessel === vessel.id) {
            const item = vessel.views.map((view) => {
              view.value = view.title;
              return view;
            });
            setViewList(item);
          }
        });
    },
    [selectedVessel]
  );

  const handleSubmit = (values, { resetForm }) => {
    setIsFormFocus(false);
    setIsUpdate(true);
    axios
      .post(
        `${URL}/secured/order/create`,
        {
          vessel_id: values.vessel.id,
          vessel_view_id: values.vessel_view.id,
          model_id: values.model.id,
          vesselName: values.vessel.name,
          viewName: values.vessel_view.title,
          modelName: values.model.name,
        },
        {
          headers: {
            authorization: tok,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          store.addNotification({
            title: "Success",
            message:
              "Your order has been received; pls check the email associated with your account for confirmation.",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeInRight"],
            animationOut: ["animate__animated", "animate__fadeOutRight"],
            dismiss: {
              duration: 5000,
              delay: 1000,
              showIcon: true,
            },
            slidingEnter: {
              duration: 2000,
            },
          });
          resetForm();
          setSelected(null);
          setSelectedVessel(null);
          setSelectedView(null);
          setIsUpdate(false);
          setIsFinish(true);
        }
      });
  };

  useEffect(() => {
    if (isFormFocus) {
      window.scrollTo({
        top: document.body.scrollHeight,
        left: 0,
        behavior: 'smooth'
      });
    }
  }, [isFormFocus])

  return (
    <>
      <H1>Floors & Models</H1>
      <DownloadLink
        href={pdfFile}
        download
        target="_blank"
        marginBottom={2}
        small
        inlineBlock
      >
        Download Instructions
      </DownloadLink>
      <Text marginBottom={2}>
        Our mapping providers allow you to order the following from your
        existing view models:
      </Text>
      <Text marginBottom={2} marginLeft={2}>
        <Text paddingRight={1} inline>
          -
        </Text>{" "}
        Schematic Floor Plans are available in PDF, PNG and SVG formats.
      </Text>
      <Text marginBottom={2}>
        We don't support Schematic Floor Plan creation for Spaces larger than
        2,300 square meters. Floor Plans are calculated using Gross Internal
        Area (GIA) by default. GIA measures to the Internal face of walls.
      </Text>
      <DownloadLink
        href="https://drive.google.com/file/d/1JxhGOVemTnbYIp4D9vxBtm1TmrT1mWaz/view"
        download
        target="_blank"
        marginBottom={2}
        small
        inlineBlock
      >
        Download a Floor Plan sample
      </DownloadLink>
      <Text marginBottom={2} marginLeft={2}>
        <Text paddingRight={1} inline>
          -
        </Text>{" "}
        SKX files that you can easily import into Xactimate &trade;
      </Text>
      <Text marginBottom={2}>
        With these files, you no longer have to measure and manually sketch
        losses. This saves you valuable time so you can process insurance claims
        faster and more accurately. We don't support Vessel Views for Spaces
        larger than 2,300 square meters. Prices range per order and are
        dependent on the total square footage of the Space.
      </Text>
      <DownloadLink
        href="https://drive.google.com/file/d/18iq2EM5rb4KZtQ6JbnIZGXXLKnmWVsCg/view"
        download
        target="_blank"
        marginBottom={2}
        small
        inlineBlock
      >
        Download SKX files sample
      </DownloadLink>

      <Text marginBottom={2} marginLeft={2}>
        <Text paddingRight={1} inline>
          -
        </Text>{" "}
        A set of commercial 3D assets for import into third party programs. This
        bundle contains:
      </Text>
      <Text marginBottom={1}> ❏ Low density color point cloud (.xyz)</Text>
      <Text marginBottom={1}>
        ❏ Reflected ceiling plan image (.jpg and .pdf)
      </Text>
      <Text marginBottom={1}>
        ❏ High resolution floor plan image (.jpg and .pdf)
      </Text>
      <Text>❏ OBJ file of the 3D mesh (.obj)</Text>
      <p> </p>
      <Text marginBottom={2}>
        Associated assets are typically used by architects and engineers for
        as-built designs and construction professionals for documentation and
        turnover packages. MatterPak (.xyz) point cloud and .OBJ scale: 1 unit =
        1 meter. Ceiling plan and floor plan images contain a scale legend
      </Text>

      <DownloadLink
        href="https://drive.google.com/file/d/1m1EY17n6Uv_qvsj1aEtAFBva-l24hvpi/view"
        download
        target="_blank"
        marginBottom={2}
        small
        inlineBlock
      >
        Download commercial 3D assets sample
      </DownloadLink>
      <Text marginBottom={2} big>
        Order your assets now!
      </Text>
      <Formik
        onSubmit={handleSubmit}
        validate={(values) => {
          let errors = {};
          if (!values.vessel) {
            errors.vessel = "Required";
          }
          return errors;
        }}
        render={({ handleSubmit, values, setFieldValue, isValid, resetForm }) => (
          <form onSubmit={handleSubmit} onClick={() => {
            setIsFormFocus(true);
          }}>
            {/*<Dropdown*/}
            {/*  label="Select account"*/}
            {/*  name="account"*/}
            {/*  options={accountList}*/}
            {/*  onChange={(field, value) => {*/}
            {/*    setSelected(value.id);*/}
            {/*    setFieldValue(field, value);*/}
            {/*    setFieldValue("vessel", "");*/}
            {/*    setSelectedView(null);*/}
            {/*    setSelectedVessel(null);*/}
            {/*  }}*/}
            {/*  placeholder="Select account..."*/}
            {/*/>*/}

            {vesselList && (
              <Dropdown
                label="Select vessel"
                changeHeight={changeHeight}
                name="vessel"
                options={vesselList}
                value={ values ? values.vessel : ''}
                onChange={(field, value) => {
                  setSelectedVessel(value.id);
                  setFieldValue(field, value);
                  setSelectedView(null);
                }}
                placeholder="Select vessel..."
              />
            )}
            {selectedVessel && (
              <Dropdown
                changeHeight={changeHeight}
                label="Select vessel view"
                name="vessel_view"
                options={viewList}
                value={ values ? values.vessel_view : ''}
                onChange={(field, value) => {
                  setSelectedView(value.id);
                  setFieldValue(field, value);
                }}
                placeholder="Select vessel view..."
              />
            )}
            {selectedView && (
              <Dropdown
                changeHeight={changeHeight}
                label="Model List"
                name="model"
                options={modelList}
                value={ values ? values.model : ''}
                onChange={(field, value) => {
                  setFieldValue(field, value);
                }}
                placeholder="Select Model..."
              />
            )}
            <Box display="flex" justify="center" alignItems="center">
              {hasError && (
                <Text color="crimson" marginRight={2}>
                  {errorMsg}
                </Text>
              )}
              <Button type="submit" rounded secondary disabled={!isValid}>
                {isUpdate ? (
                  <Spinner small alt />
                ) : isFinish ? (
                  <SuccessIcon />
                ) : (
                  "CONFIRM ORDER"
                )}
              </Button>
            </Box>
            <div style={{height: isFormFocus ? '70vh' : ''}}></div>
          </form>
        )}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const accounts = getAccountList(state);
  const accountList = accounts.map((account) => ({
    id: account.id,
    value: account.name,
  }));

  const vessels = getVesselList(state);
  const vesselList = vessels.map((vessel) => ({
    id: vessel.id,
    accountId: vessel.accountId,
    value: vessel.name,
    views: vessel.views,
  }));

  return {
    accountList: accountList,
    vesselList: vesselList,
    vessels: vessels,
    hasError: uploadHasError(state),
    errorMsg: getUploadErrorMsg(state),
  };
};

export default connect(mapStateToProps)(FloorsAndModels);
