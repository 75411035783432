import React from "react";

import { Box, UI, Button, Divider } from "../../../../components/Dashboard";

import MessageListItem from "./MessageListItem";

const MessageList = ({ onComposeClick }) => {
  const renderMessages = () => {
    let msgList = [];

    for (let i = 0; i < 2; i++) {
      const msg = {
        message:
          i % 2 === 0
            ? "Let's arrange a meeting for tomorrow"
            : "Hi Constantine, check this out",
        avatar:
          i % 2
            ? "https://s3.eu-west-2.amazonaws.com/vesselfront-s3/private/users/1/1544108514_jpara.jpg"
            : "https://s3.eu-west-2.amazonaws.com/vesselfront-s3/private/users/3/1540221198_1533073286_pp.jpg",
        sender: i % 2 ? "John Paraskakis" : "Pantelis Paliagkas"
      };
      msgList.push(msg);
    }
    return msgList;
  };

  const msgList = renderMessages();

  return (
    <Box
      display="flex"
      direction="col"
      background={UI.colors.lightGray}
      alignItems="start"
      width={450}
    >
      <Box paddingVertical={2} paddingHorizontal={2}>
        <Button rounded small secondary onClick={onComposeClick}>
          Compose
        </Button>
      </Box>
      <Divider color="darkGray" marginBottom={1} />
      {msgList.map((msg, index) => {
        return (
          <MessageListItem
            key={index}
            message={msg.message}
            avatar={msg.avatar}
            sender={msg.sender}
          />
        );
      })}
    </Box>
  );
};

export default MessageList;
