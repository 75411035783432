import { combineReducers } from "redux";

import * as authTypes from "./actions";

const token = (state = null, action) => {
  switch (action.type) {
    case authTypes.LOGIN.SUCCESS:
    case authTypes.LOGIN_WITH_PIN.SUCCESS:
      return action.payload.token;
    default:
      return state;
  }
};

const isFetching = (state = false, action) => {
  return action.type === authTypes.LOGIN.SUCCESS ||
    action.type === "LOGIN_WITH_PIN_REQUEST"
    ? true
    : false;
};

const error = (state = false, action) => {
  switch (action.type) {
    case authTypes.LOGIN.ERROR:
    case authTypes.LOGIN_WITH_PIN.ERROR:
      return action.payload;
    case authTypes.LOGIN.SUCCESS:
    case authTypes.LOGIN.REQUEST:
    case "LOGIN_WITH_PIN_REQUEST":
      return false;
    default:
      return state;
  }
};

const avatar = (state = {}, action) => {
  switch (action.type) {
    case authTypes.LOGIN.ERROR:
      if (action.payload.code === 449) {
        return action.payload.avatar;
      }
      return state;
    default:
      return state;
  }
};

const auth = combineReducers({
  token,
  isFetching,
  error,
  avatar
});

export default auth;

export const getToken = state => state.auth.token;
export const isAuthorized = state =>
  state.auth.error.status === 404 ? false : true;
export const isLoading = state => state.auth.isFetching;
export const isPinRequired = state =>
  (state.auth.error.code === 449) | (state.auth.error.code === 449)
    ? true
    : false;
export const getLoginAvatarUrl = state =>
  state.auth.avatar ? state.auth.avatar.url : "";
