import React from "react";

import Input from "../../../../../components/Input/Input";
import Dropdown from "../../../../../components/Input/Dropdown";
import { Box } from "../../../../../components/Dashboard";

const FirstStep = ({ handleChange, setFieldValue, types, flags }) => (
  <Box display="flex" zIndex={100} wrap>
    <Box display="flex" flexDirection="col" flex="grow" paddingRight={1}>
      <Input
        label="Vessel name"
        name="name"
        placeholder="Your vessel's name"
        type="text"
        onChange={handleChange}
        dashboard
        required
      />
      <Input
        label="IMO"
        name="imo"
        placeholder="Your vessel's imo"
        type="text"
        onChange={handleChange}
        dashboard
        required
      />
      <Input
        label="MMSI"
        name="mmsi"
        placeholder="Your vessel's mmsi"
        type="text"
        onChange={handleChange}
        dashboard
      />
      <Dropdown
        label="Vessel flag"
        name="flag"
        options={flags}
        onChange={setFieldValue}
        placeholder="Your vessel's flag"
        dashboard
        required
      />
      <Dropdown
        label="AIS vessel type"
        name="type"
        options={types}
        onChange={setFieldValue}
        placeholder="Your vessel's type"
        dashboard
        required
      />
    </Box>
    <Box display="flex" flexDirection="col" flex="grow" paddingLeft={1}>
      <Input
        label="Gross tonage"
        name="gtonage"
        placeholder="Your vessel's gross tonage (t)"
        type="text"
        onChange={handleChange}
        dashboard
      />
      <Input
        label="Dead weight"
        name="dweight"
        placeholder="Your vessel's dead weight (t)"
        type="text"
        onChange={handleChange}
        dashboard
      />
      <Input
        label="Vessel length"
        name="length"
        placeholder="Your vessel's length (m)"
        type="text"
        onChange={handleChange}
        dashboard
      />
      <Input
        label="Vessel breadth"
        name="breadth"
        placeholder="Your vessel's breadth (m)"
        type="text"
        onChange={handleChange}
        dashboard
      />
      <Input
        label="Year built"
        name="year"
        placeholder="Your vessel's construction built year"
        type="text"
        onChange={handleChange}
        dashboard
        required
      />
    </Box>
  </Box>
);

export default FirstStep;
