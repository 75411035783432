import { css } from "styled-components";

const MARGINS = {
  0: "0px",
  1: "8px",
  2: "16px",
  3: "24px",
  4: "48px",
  5: "64px",
  6: "80px",
  7: "96px",
};

const PADDINGS = {
  0: "0px",
  1: "8px",
  2: "16px",
  3: "24px",
  4: "48px",
  5: "64px",
  6: "80px",
  7: "96px",
};

const SIZES = {
  xlarge: 1440,
  large: 992,
  medium: 768,
  small: 576,
  xsmall: 375,
};

export const colors = {
  blue: "rgba(11,26,208,1)",
  red: "#FF0000",
  lightblue: "rgba(0,168,230,1)",
  green: "rgb(95, 208, 27)",
  crimson: "rgb(204,51,51)",
  white: "rgb(255,255,255)",
  offWhite: "rgb(226,226,226)",
  smoke: "rgba(216,216,216,1)",
  ghost: "rgba(155,155,155,1)",
  black: "rgba(28,28,29,1)",
  darkGray: "rgba(48,48,49,1)",
  lightGray: "rgb(74, 74, 74)",
};

export const getPixels = (property) => {
  if (property) {
    if (typeof property === "string") {
      return property;
    } else if (typeof property === "number") {
      return property + "px";
    }
  }
  return "auto";
};

export const getAbsolutePositioning = (props) => css`
  ${props.top &&
    `top: ${typeof props.top === "number" ? props.top + "px" : props.top}`};
  ${props.right &&
    `right: ${
      typeof props.right === "number" ? props.right + "px" : props.right
    }`};
  ${props.left &&
    `left: ${typeof props.left === "number" ? props.left + "px" : props.left}`};
  ${props.bottom &&
    `bottom: ${
      typeof props.bottom === "number" ? props.bottom + "px" : props.bottom
    }`};
`;

export const getMargins = (props) => css`
  ${props.marginTop &&
    `margin-top: ${
      typeof props.marginTop === "number" ? MARGINS[props.marginTop] : 0
    }`};
  ${props.marginBottom &&
    `margin-bottom: ${
      typeof props.marginBottom === "number" ? MARGINS[props.marginBottom] : 0
    }`};
  ${props.marginRight &&
    `margin-right: ${
      typeof props.marginRight === "number" ? MARGINS[props.marginRight] : 0
    }`};
  ${props.marginLeft &&
    `margin-left: ${
      typeof props.marginLeft === "number" ? MARGINS[props.marginLeft] : 0
    }`};
  ${props.marginVertical &&
    `
    margin-top: ${
      typeof props.marginVertical === "number"
        ? MARGINS[props.marginVertical]
        : 0
    }
    margin-bottom: ${
      typeof props.marginVertical === "number"
        ? MARGINS[props.marginVertical]
        : 0
    }
  `} ${props.marginHorizontal &&
    `
    margin-right: ${
      typeof props.marginHorizontal === "number"
        ? MARGINS[props.marginHorizontal]
        : 0
    }
    margin-left: ${
      typeof props.marginHorizontal === "number"
        ? MARGINS[props.marginHorizontal]
        : 0
    }
  `};
`;

export const getPaddings = (props) => css`
  ${props.paddingTop &&
    `padding-top: ${
      typeof props.paddingTop === "number" ? PADDINGS[props.paddingTop] : 0
    }`};
  ${props.paddingBottom &&
    `padding-bottom: ${
      typeof props.paddingBottom === "number"
        ? PADDINGS[props.paddingBottom]
        : 0
    }`};
  ${props.paddingRight &&
    `padding-right: ${
      typeof props.paddingRight === "number" ? PADDINGS[props.paddingRight] : 0
    }`};
  ${props.paddingLeft &&
    `padding-left: ${
      typeof props.paddingLeft === "number" ? PADDINGS[props.paddingLeft] : 0
    }`};
  ${props.paddingVertical &&
    `
    padding-top: ${
      typeof props.paddingVertical === "number"
        ? PADDINGS[props.paddingVertical]
        : 0
    }
    padding-bottom: ${
      typeof props.paddingVertical === "number"
        ? PADDINGS[props.paddingVertical]
        : 0
    }
  `} ${props.paddingHorizontal &&
    `
    padding-right: ${
      typeof props.paddingHorizontal === "number"
        ? PADDINGS[props.paddingHorizontal]
        : 0
    }
    padding-left: ${
      typeof props.paddingHorizontal === "number"
        ? PADDINGS[props.paddingHorizontal]
        : 0
    }
  `};
`;

export const media = Object.keys(SIZES).reduce((acc, size) => {
  acc[size] = (...args) => css`
    @media (max-width: ${SIZES[size]}px) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});

export default {
  getPaddings,
  getMargins,
  getAbsolutePositioning,
  colors,
  getPixels,
  media,
};
