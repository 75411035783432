import React, { PureComponent } from "react";
import styled from "styled-components";
import Icon from "react-ionicons";
import { connect } from "react-redux";
import matchSorter from "match-sorter";

import Downshift from "downshift";

import {
  Box,
  SimpleButton,
  UI,
  Text,
  Button
} from "../../../components/Dashboard";

import arrowDown from "../../../assets/images/arrow-down.svg";

// Styled components specific for this form

const { colors } = UI;

const TagInput = styled.input`
  flex: 1 1 auto;
  /* width: 100%; */
  padding: 4px 8px;
  background-color: ${colors.black};
  border-radius: 4px;
  border: 1px solid ${props => (props.error ? colors.crimson : "transparent")};
  font-size: ${props => (props.big ? "14px" : "13px")};
  font-family: "Roboto", sans-serif;
  font-weight: ${props => (props.semiBold ? "500" : "300")};
  outline: none;
  box-shadow: none;
  -webkit-appearance: none;
  color: ${props => (props.color ? colors[props.color] : colors.smoke)};
  caret-color: ${props => (props.color ? colors[props.color] : colors.smoke)};
  transition: all 250ms cubic-bezier(0.45, 0.24, 0.66, 0.89);

  &:focus {
    border: 1px solid ${colors.lightblue};
  }

  &::placeholder {
    font-style: italic;
    font-size: 12px;
    color: ${props => (props.color ? colors[props.color] : colors.smoke)};
  }
`;

const TagDropdown = styled.input`
  width: 100%;
  padding: 4px 8px;
  background-image: url(${arrowDown});
  background-repeat: no-repeat;
  background-size: 8px;
  background-position: calc(100% - 8px) 50%;
  background-color: ${colors.black};
  border: 1px solid transparent;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  color: #fff;
  outline: none;
  box-shadow: none;
  -webkit-appearance: none;
  border-radius: 4px;
  position: relative;
  transition: all 0.3s cubic-bezier(0.45, 0.24, 0.66, 0.89);

  &:focus {
    border: 1px solid ${colors.lightblue};
  }
`;

const TagTextAera = styled.textarea`
  flex: 1 1 auto;
  height: ${props => (props.touched ? "64px" : "29px")};
  padding: 4px 8px;
  background-color: ${colors.black};
  border-radius: 4px;
  border: 1px solid transparent;
  font-size: ${props => (props.big ? "14px" : "13px")};
  font-family: "Roboto", sans-serif;
  font-weight: ${props => (props.semiBold ? "500" : "300")};
  outline: none;
  box-shadow: none;
  -webkit-appearance: none;
  color: ${props => (props.color ? colors[props.color] : colors.smoke)};
  caret-color: ${props => (props.color ? colors[props.color] : colors.smoke)};
  transition: all 250ms ease-in-out;
  resize: none;
  -webkit-appearance: none;

  &:focus {
    border: 1px solid ${colors.lightblue};
  }

  ::placeholder {
    color: ${props => (props.color ? colors[props.color] : colors.smoke)};
  }
`;

const TagLabel = styled.label`
  flex: 1 1 25%;
  color: ${colors.smoke};
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-weight: 500;
  margin: 0;
  transition: all 250ms ease-in-out;
`;

const StyledForm = styled.form`
  position: relative;
  width: 320px;
  height: 100%;
  min-height: 320px;
  max-height: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
`;

const Uploader = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 8px;
  margin-bottom: 16px;
  border-radius: 4px;
  border: 1px dashed
    ${props =>
      props.isOver || props.fileDropped ? colors.lightblue : colors.ghost};
  background: ${colors.black};
  transition: all 250ms ease-in-out;

  &:hover {
    cursor: pointer;
    border: 1px dashed ${colors.lightblue};
  }
`;

//---------------------------------------

const styles = {
  color: colors.ghost,
  fontSize: 13,
  fontFamily: "Roboto",
  fontWeight: 500,
  cursor: "pointer"
};

//---------------------------------------

class TagForm extends PureComponent {
  fileUploader = React.createRef();

  state = {
    touchedFields: {},
    isOver: false,
    fileDropped: false,
    filename: null,
    uploader: true,
    dropdown: false,
    isDropdownOpen: false
  };

  componentDidMount() {
    this.props.getDocuments();
  }

  componentWillUnmount() {
    this.props.reset();
  }

  handleOver = () => {
    this.setState({ isOver: true });
  };

  handleDrop = files => {
    const { setFieldValue } = this.props;
    if (files.length) {
      this.setState({
        filename: files[0].name,
        isOver: false,
        fileDropped: true
      });
      setFieldValue("attachment", files[0]);
    }
  };

  handleLeave = () => {
    this.setState({ isOver: false });
  };

  handleFocus = event => {
    this.setState({
      touchedFields: {
        ...this.state.touchedFields,
        [event.target.name]: true
      }
    });
  };

  handleBlur = event => {
    if (!event.target.value) {
      this.setState({
        touchedFields: {
          ...this.state.touchedFields,
          [event.target.name]: false
        }
      });
    }
  };

  handleFileUpload = event => {
    const { setFieldValue } = this.props;
    if (event.target.files.length) {
      this.setState({
        filename: event.target.files[0].name,
        isOver: false,
        fileDropped: true
      });
      setFieldValue("attachment", event.target.files[0]);
    }
  };

  toggleDropdown = () => {
    if (!this.state.isDropdownOpen) {
      this.setState({
        dropdown: !this.state.dropdown,
        uploader: false,
        isDropdownOpen: true
      });
    }
    this.setState({ dropdown: true, uploader: false });
  };

  toggleUploader = () => {
    if (!this.state.uploader) {
      this.setState({ dropdown: false, uploader: true });
    }
  };

  toggleDropdownView = () => {
    this.setState({ isDropdownOpen: !this.state.isDropdownOpen });
  };

  renderWithUpload = () => {
    const { handleChange, cancel, errors, isValid } = this.props;
    const {
      touchedFields,
      isOver,
      filename,
      fileDropped,
      uploader,
      dropdown
    } = this.state;
    return (
      <>
        <Box marginBottom={2} display="flex" justify="between">
          <TagLabel touched={touchedFields.title} htmlFor="title">
            Title
          </TagLabel>
          <TagInput
            type="text"
            color="white"
            onChange={handleChange}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            name="title"
            id="title"
            autoComplete="off"
            value={(this.props.values && this.props.values.title) || ""}
            error={errors && errors.title}
          />
        </Box>
        <Box marginBottom={2} display="flex" justify="between">
          <TagLabel touched={touchedFields.details} htmlFor="details">
            Description
          </TagLabel>
          <TagTextAera
            touched={touchedFields.details}
            type="text"
            color="white"
            onChange={handleChange}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            name="details"
            id="details"
            autoComplete="off"
            maxLength="140"
            value={(this.props.values && this.props.values.details) || ""}
          />
        </Box>
        <Box marginBottom={1} display="flex" justify="between">
          <TagLabel touched={touchedFields.media} htmlFor="media">
            Link
          </TagLabel>
          <TagInput
            type="text"
            color="white"
            onChange={handleChange}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            name="media"
            id="media"
            autoComplete="off"
            value={(this.props.values && this.props.values.media) || ""}
            error={errors && errors.media}
          />
        </Box>
        <Box>
          <Box marginBottom={1}>
            <span
              style={{
                ...styles,
                color: uploader ? colors.smoke : colors.ghost
              }}
              onClick={this.toggleUploader}
            >
              Upload
            </span>
            <span style={styles}> / </span>
            <span
              style={{
                ...styles,
                color: dropdown ? colors.smoke : colors.ghost
              }}
              onClick={this.toggleDropdown}
            >
              Select from documents
            </span>
          </Box>
          {uploader && (
            <Uploader
              onOver={this.handleOver}
              onLeave={this.handleOnLeave}
              onDrop={this.handleDrop}
              onDropDataURI={this.handleDropDataURI}
              isOver={isOver}
              fileDropped={fileDropped}
              id="attachment"
              name="attachment"
              onClick={() => {
                this.fileUploader.current.click();
              }}
            >
              {!filename ? (
                <Icon
                  icon="md-cloud-upload"
                  fontSize="16px"
                  color={isOver ? colors.white : colors.ghost}
                />
              ) : (
                <Text
                  tiny
                  semiBold
                  color={fileDropped ? "lightblue" : "offWhite"}
                >
                  {filename}
                </Text>
              )}
              <input
                type="file"
                style={{ display: "none" }}
                ref={this.fileUploader}
                onChange={this.handleFileUpload}
              />
            </Uploader>
          )}
          {dropdown && (
            <Text tiny semiBold color="lightblue" marginBottom={2}>
              {(this.props.values.assetId &&
                this.props.getFilename(this.props.values.assetId)) ||
                "Select a file"}
            </Text>
          )}
          <Box display="flex" justify="between" alignItems="center">
            <TagLabel touched={touchedFields.pages} htmlFor="pages">
              Pages
            </TagLabel>
            <TagInput
              type="text"
              color="white"
              placeholder="Leave blank or (e.g. 1 - 10, 12 - 20)"
              onChange={handleChange}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              name="pages"
              id="pages"
              autoComplete="off"
              value={(this.props.values && this.props.values.pages) || ""}
            />
          </Box>
        </Box>

        <Box display="flex" justify="between" marginTop={2} alignItems="center">
          <SimpleButton small type="button" onClick={cancel} secondary>
            Cancel
          </SimpleButton>
          <Button type="submit" small secondary disabled={!isValid}>
            Submit
          </Button>
        </Box>
      </>
    );
  };

  renderDocumentSelector = () => {
    const { documents, setFieldValue } = this.props;

    const handleChange = selection => {
      setFieldValue("assetId", selection.assetId);
      this.toggleDropdownView();
    };

    return (
      <>
        <Downshift
          defaultIsOpen
          onChange={handleChange}
          itemToString={item => {
            return item ? item.displayName : "";
          }}
        >
          {({
            getInputProps,
            getMenuProps,
            getItemProps,
            isOpen,
            inputValue,
            highlightedIndex
          }) => (
            <div
              style={{
                position: "relative",
                width: "100%",
                minHeight: 300,
                maxHeight: 300
              }}
            >
              <TagDropdown
                {...getInputProps()}
                id="assetId"
                name="assetId"
                type="text"
                color="white"
                autoComplete="off"
                placeholder="Select from documents..."
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
              />
              <ul
                {...getMenuProps()}
                style={{
                  height: "100%",
                  maxHeight: 240,
                  width: "100%",
                  listStyle: "none",
                  padding: "8px 8px 0px 8px",
                  fontFamily: "Roboto",
                  fontSize: 14,
                  color: colors.smoke,
                  overflow: "hidden",
                  overflowY: "auto"
                }}
              >
                {isOpen &&
                  matchSorter(documents, inputValue, {
                    keys: ["displayName"]
                  }).map((document, index) => (
                    <li
                      {...getItemProps({
                        key: document.id,
                        item: document,
                        style: {
                          paddingBottom: 4,
                          color:
                            highlightedIndex === index
                              ? colors.lightblue
                              : colors.smoke,
                          cursor: "pointer"
                        }
                      })}
                    >
                      {document.displayName}
                    </li>
                  ))}
              </ul>
            </div>
          )}
        </Downshift>
        <SimpleButton
          onClick={this.toggleDropdownView}
          type="button"
          small
          secondary
        >
          Cancel
        </SimpleButton>
      </>
    );
  };

  render() {
    const { handleSubmit } = this.props;
    const { isDropdownOpen } = this.state;
    return (
      <StyledForm onSubmit={handleSubmit}>
        {isDropdownOpen
          ? this.renderDocumentSelector()
          : this.renderWithUpload()}
      </StyledForm>
    );
  }
}

const mapStateToProps = state => {
  const getDocumentById = (state, documentId) =>
    state.documents.dropdown.byId[documentId];
  const getAllDocuments = state =>
    state.documents.dropdown.allIds.map(id => getDocumentById(state, id));
  const getFilenameForAssetId = (state, assetId) =>
    getAllDocuments(state).find(doc => doc.assetId === assetId).displayName;
  return {
    documents: getAllDocuments(state),
    getFilename: assetId => getFilenameForAssetId(state, assetId)
  };
};

const mapDispatchToProps = dispatch => ({
  getDocuments: () => dispatch({ type: "DOCUMENTS_FOR_DROPDOWN_REQUEST" }),
  reset: () => dispatch({ type: "DOCUMENTS_FOR_DROPDOWN_RESET" })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TagForm);
