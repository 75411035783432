import { createRequestTypes, createActions } from "../../global/utilities";

export const SEND_MESSAGE = "SEND_MESSAGE";
export const RECEIVE_MESSAGE = "RECEIVE_MESSAGE";

export const CHAT_HISTORY = createRequestTypes("CHAT_HISTORY");

export const chatHistoryRequest = scanId =>
  createActions(CHAT_HISTORY["REQUEST"], { scanId });
export const chatHistorySuccess = data =>
  createActions(CHAT_HISTORY["SUCCESS"], { data });
export const chatHistoryError = error =>
  createActions(CHAT_HISTORY["ERROR"], { error });

// api.vesselfront.com/secured/chat/:scanId

export const sendMessage = data => ({
  type: "SEND_MESSAGE",
  payload: {
    data
  }
});

export const receiveMessage = data => ({
  type: "RECEIVE_MESSAGE",
  payload: {
    data
  }
});
