import React, { PureComponent } from "react";
import { connect } from "react-redux";

import {
  selectScanByTitle,
  getVesselById,
  makeGetScans,
  getShareableScanLink
} from "../../../global/rootReducer";

import {
  Box,
  Button,
  H1,
  Divider,
  ShareBox
} from "../../../components/Dashboard";

import ScanBox from "./ScanBox";
import ScanList from "./ScanList";

class Scan extends PureComponent {
  state = { toggle: false };

  toggleModal = () => {
    const { toggle } = this.state;
    const { fetchShareableScanLink, scan } = this.props;
    if (!toggle) {
      fetchShareableScanLink(scan.id);
    }
    this.setState({ toggle: !toggle });
  };

  stop = event => {
    event.stopPropagation();
  };

  render() {
    const {
      vesselName,
      scan,
      scans,
      scanCategory,
      scanLink,
      vesselId,
      isFetching,
      error
    } = this.props;
    const { toggle } = this.state;
    return (
      <Box>
        <ShareBox
          toggle={toggle}
          toggler={this.toggleModal}
          title={scanCategory}
          loading={isFetching}
          error={error}
          link={
            scanLink
              ? `https://www.vesselfront.com/preview/${scanLink.identifier}`
              : ""
          }
        />
        <Box
          display="flex"
          justify="between"
          medium={{
            flexDirection: 'column',
            marginBottom: '15px',
            textAlign: 'center'
          }}
        >
          <H1 bold>{vesselName}</H1>
          <Button rounded secondary onClick={this.toggleModal}>
            Share View
          </Button>
        </Box>
        <Divider marginBottom={2} />
        <Box
          display="flex"
          justify="between"
          medium={{
            justifyContent: 'center'
          }}
        >
          <H1>{scanCategory}</H1>
        </Box>
        <Box marginTop={2} display="flex" wrap justify="between">
          <Box
            marginBottom={2}
            maxWidth="100%"
            display="flex"
            flex="grow"
            align="start"
          >
            <ScanBox
              scan={scan}
              width={1280}
              render={(state, renderMatterportSpace) => renderMatterportSpace()}
            />
          </Box>
          <Box flex="grow" overflow="auto">
            <ScanList scans={scans} currentScan={scan} vesselId={vesselId} />
          </Box>
        </Box>
      </Box>
    );
  }
}

Scan.defaultProps = {
  vesselName: "Vessel Name",
  scanCategory: "Scan Name"
};

const mapStateToProps = (state, props) => {
  const { match } = props;
  const scanCategory = match.params.scanCategory;
  const vesselId = match.params.vesselId;
  const getScans = makeGetScans(state);

  const vessel = getVesselById(state, vesselId);
  const vesselName = vessel ? vessel.name : "No vessel found";
  const scans = getScans(state, vesselId);
  const scan = selectScanByTitle(state, vesselId, scanCategory);
  const scanCategoryName = scan
    ? scan.title
    : `No scans found for ${scanCategory}`;
  const scanLink = getShareableScanLink(state, scan.id);
  return {
    vesselName: vesselName,
    scan: scan,
    scanCategory: scanCategoryName,
    scans: scans,
    scanLink: scanLink,
    vesselId: vesselId,
    isFetching: state.scans.scanLinks.isFetching,
    error: state.scans.scanLinks.error
  };
};

const mapDispatchToProps = dispatch => ({
  fetchShareableScanLink: scanId => {
    return dispatch({ type: "SCAN_LINK_REQUEST", payload: { scanId } });
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Scan);
