// Global API url

const getUrl = () => {
  const HOSTNAME = window.location.hostname;
  switch (HOSTNAME) {
    case "www.vesselfront.com":
    case "vesselfront.com":
      return `https://api.vesselfront.com`;
    case "staging.vesselfront.com":
      return "http://api.staging.vesselfront.com";
    case "dev.vesselfront.com":
      return "http://api.dev.vesselfront.com";
    default:
      return "http://localhost:8080";
  }
};

export const URL = getUrl();
export const URL_NEW = URL;
// Global action utilities

const REQUEST = "REQUEST";
const SUCCESS = "SUCCESS";
const ERROR = "ERROR";

export const createRequestTypes = (base) => {
  return [REQUEST, SUCCESS, ERROR].reduce((acc, type) => {
    acc[type] = `${base}_${type}`;
    return acc;
  }, {});
};

export const createActions = (type, payload = {}) => {
  return {
    type,
    payload: { ...payload },
  };
};

const contains = (key, arr) => arr.indexOf(key) >= 0;
export const omit = (keys, obj) =>
  Object.keys(obj).reduce((acc, k) => {
    if (contains(k, keys)) {
      return acc;
    }
    return {
      ...acc,
      [k]: obj[k],
    };
  }, {});

export const createFormData = (values) => {
  let formData = new FormData();
  for (let i = 0; i < Object.keys(values).length; i++) {
    const field = Object.keys(values)[i];
    formData.set(field, values[field]);
  }
  return formData;
};
