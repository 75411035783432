import React from "react";

import styles from "./arrows.module.css";

export const LeftArrow = ({ onClick }) => {
  return (
    <svg
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16.264 26.829"
    >
      <defs />
      <path
        id="Path_2"
        data-name="Path 2"
        className={styles["cls-1"]}
        d="M1132.5,177l12,12.362L1132.5,201"
        transform="translate(1147.329 202.436) rotate(180)"
      />
    </svg>
  );
};

export const RightArrow = ({ onClick }) => {
  return (
    <svg
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16.264 26.829"
    >
      <path
        id="Path_1"
        data-name="Path 1"
        className={styles["cls-1"]}
        d="M1132.5,177l12,12.362L1132.5,201"
        transform="translate(-1131.065 -175.607)"
      />
    </svg>
  );
};
