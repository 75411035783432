import React, { PureComponent } from "react";
import { Box } from "../../../components/Dashboard";

import VesselsBox from "../Overview/containers/VesselsBox";

class Vessels extends PureComponent {
  render() {
    return (
      <Box>
        <VesselsBox title="Your vessels" />
      </Box>
    );
  }
}

export default Vessels;
