import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link } from "react-router-dom";

import Icon from "react-ionicons";

import {
  Box,
  Text,
  Divider,
  Avatar,
  SimpleButton,
  UI
} from "../../../../components/Dashboard";

const colors = UI.colors;

const UserMenuBox = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: 0px 24px;
  box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.2);
  max-height: ${props => (props.show ? "500px" : "0px")};
  opacity: ${props => (props.show ? "1" : "0.01")};
  transition: all 325ms ease-in-out;
  margin-top: 48px;
  top: 0;
  right: 0;
  overflow: hidden;
  background: ${colors.darkGray};

  @media (max-width: 576px) {
    right: auto;
  }
`;

// this should be refactored and generalized
const LinkAsButton = styled(Link)`
  display: block;
  position: relative;
  text-align: center;
  height: 32px;
  min-width: 120px;
  border-radius: 0px;
  border: none;
  background-color: rgba(0, 168, 230, 1);
  box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.2);
  color: rgb(255, 255, 255);
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 500;
  margin: 0;
  outline: 0;
  padding: 6px;

  &:hover {
    text-decoration: none;
    color: rgb(255, 255, 255);
  }
`;

class UserBox extends PureComponent {
  userBoxRef = React.createRef();

  state = { show: false, block: false };

  // Maybe find a more performant way of doing this but will keep this for now.
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = e => {
    if (!this.userBoxRef.current.contains(e.target)) {
      this.setState({ show: false });
    }
  };

  handleClick = event => {
    event.preventDefault();
    this.setState({ show: true });
  };

  render() {
    const { fullname, isLoading, email, avatar, logo, signOut } = this.props;
    const { show } = this.state;
    return (
      <Box display="flex" alignItems="start" justify="center">
        <Icon icon="ios-person" color={colors.white} />
        <SimpleButton
          onClick={this.handleClick}
          paddingHorizontal={1}
          secondary
        >
          {isLoading ? "loading..." : fullname ? fullname : " "}
        </SimpleButton>
        <Icon icon="md-arrow-dropdown" color={colors.white} />
        <UserMenuBox
          ref={this.userBoxRef}
          position="absolute"
          marginTop={4}
          show={show}
          background={colors.darkGray}
          display="flex"
          flexDirection="column"
          paddingHorizontal={3}
          elevated
          overflow="hidden"
          top="0px"
          left={-35}
        >
          <Box display="flex" paddingVertical={2}>
            <Box>
              <Avatar circle big avatar={avatar || logo} />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justify="start"
              alignItems="flex-start"
              paddingHorizontal={2}
            >
              <Text semiBold small color="white">
                {fullname}
              </Text>
              <Text small marginBottom={1} color="smoke">
                {email}
              </Text>
              <LinkAsButton as={Link} to="/dashboard/account/profile">
                VIEW PROFILE
              </LinkAsButton>
            </Box>
          </Box>
          <Divider marginBottom={2} />
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            paddingBottom={2}
          >
            {/*<SimpleButton small secondary marginBottom={1} disabled>*/}
            {/*  Billing information*/}
            {/*</SimpleButton>*/}
            {/*<SimpleButton small secondary marginBottom={1} disabled>*/}
            {/*  Vesselfront Roadmap*/}
            {/*</SimpleButton>*/}
            <SimpleButton small color="lightblue" onClick={signOut}>
              Sign out
            </SimpleButton>
          </Box>
        </UserMenuBox>
      </Box>
    );
  }
}

UserBox.defaultProps = {
  fullname: "",
  isLoading: false,
  email: "",
  avatar: null,
  logo: null,
};

UserBox.propTypes = {
  fullname: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  email: PropTypes.string.isRequired,
  avatar: PropTypes.string,
  logo: PropTypes.string,
  signOut: PropTypes.func
};

export default UserBox;
