import React, { PureComponent } from "react";
import { Box, Avatar, UI } from "../../../../components/Dashboard";
import styled from "styled-components";

const DUMMY_DATA = [
  {
    currentUser: false,
    text: "Let's arrange a meeting for tomorrow evening. What do you think?",
    avatar:
      "https://s3.eu-west-2.amazonaws.com/vesselfront-s3/private/users/3/1540221198_1533073286_pp.jpg"
  },
  {
    currentUser: true,
    text: "That's a great idea. Let's say 6.42pm",
    avatar:
      "https://s3.eu-west-2.amazonaws.com/vesselfront-s3/private/users/3/1540221198_1533073286_pp.jpg"
  },
  {
    currentUser: false,
    text: "6:42pm is too early for me. How about 6:45?",
    avatar:
      "https://s3.eu-west-2.amazonaws.com/vesselfront-s3/private/users/3/1540221198_1533073286_pp.jpg"
  },
  {
    currentUser: true,
    text: "Sounds good. See you tomorrow",
    avatar:
      "https://s3.eu-west-2.amazonaws.com/vesselfront-s3/private/users/3/1540221198_1533073286_pp.jpg"
  },
  {
    currentUser: false,
    text:
      "Awesome, we have a lot to talk about. I think we should bring John into the conversation too. He should be briefed about some things",
    avatar:
      "https://s3.eu-west-2.amazonaws.com/vesselfront-s3/private/users/3/1540221198_1533073286_pp.jpg"
  }
];

const MessageText = styled.div`
  max-width: 400px;
  padding: 8px 16px;
  font-size: 14px;
  border: 1px solid
    ${props => (props.currentUser ? UI.colors.lightblue : UI.colors.darkGray)};
  border-radius: 20px;
  color: ${UI.colors.white};
  word-wrap: break-word;
  background: ${props =>
    props.currentUser ? UI.colors.lightblue : UI.colors.lightGray};
`;

const MessagePill = ({ message: { currentUser, text, avatar } }) => {
  return (
    <Box
      display="flex"
      paddingVertical={2}
      marginBottom={1}
      width="100%"
      justify={currentUser ? "end" : "start"}
      align="flex-end"
    >
      {!currentUser && <Avatar src={avatar} marginRight={2} circle tiny />}
      <MessageText currentUser={currentUser}>{text}</MessageText>
    </Box>
  );
};

class MessageBox extends PureComponent {
  render() {
    return (
      <Box display="flex" direction="col" width="100%" paddingHorizontal={4}>
        {DUMMY_DATA.map((message, index) => {
          return <MessagePill message={message} />;
        })}
      </Box>
    );
  }
}

export default MessageBox;
