import React, { PureComponent } from "react";

import { VesselsBox, UsersBox } from "./containers";

class Overview extends PureComponent {
  render() {
    return (
      <div>
        <VesselsBox title="Welcome to your fleet" />
        <UsersBox title="Your People" />
      </div>
    );
  }
}

export default Overview;
