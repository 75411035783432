import React from "react";
import styled from "styled-components";

import { UI, Text, Button } from "../../../../components/Dashboard";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  background: ${UI.colors.darkGray};
  border-top: 8px solid ${UI.colors.lightblue};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  min-width: 240px;
  max-width: 320px;
  box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.1);
  margin-right: 128px;

  @media (max-width: 992px) {
    margin-right: 0;
    margin-bottom: 24px;
  }
`;


const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

const Title = ({ title }) => (
  <Text marginBottom={3} semiBold>
    {title.toUpperCase()}
  </Text>
);

const Price = styled.div`
  display: block;
  position: relative;
  font-size: 50px;
  font-weight: 500;
  color: ${UI.colors.white};
  margin-bottom: 24px;

  ::before {
    content: "€";
    position: absolute;
    font-size: 26px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    top: -15px;
    left: -15px;
    color: #d8d8d8;
  }

  span {
    content: "/mo";
    font-size: 20px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    color: rgb(216, 216, 216);
    margin-left: 4px;
  }
`;

const SubscribePlan = ({ title, price, children, monthly }) => {
  return (
    <Container>
      <Title title={title} />
      <Text marginBottom={1}>from</Text>
      <Price>
        {price}
        <span>{monthly ? "/mo" : "/yr"}</span>
      </Price>
      <Content>{children}</Content>
      <Button secondary>Get started - {price}&euro;</Button>
    </Container>
  );
};

SubscribePlan.defaultProps = {
  title: "Title",
  price: 50,
  monthly: true
};

export default SubscribePlan;
