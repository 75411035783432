import React, { useState } from "react";
import styled from "styled-components";
import { CSSTransition } from "react-transition-group";
import { useMediaQuery } from "react-responsive";

import { Box, UI, Spinner } from "../../../components/Dashboard";

import Blueprint from "./Blueprint2";
import SideCardScanBox from "./SideCardScanBox";
import RightPanel from "./RightPanel";

const { colors, getMargins } = UI;

const MainBox = styled.div`
  display: flex;
  position: relative;
  min-height: ${props => (props.height ? `${props.height}px` : "480px")};
  overflow: hidden;
  ${getMargins};

  @media (max-width: 768px) and (orientation: portrait) {
    flex-direction: column-reverse;
  }
`;

const LBox = styled(Box)`
  display: block;
  position: absolute;
  width: 20%;
  top: 50%;
  left: 0;
  opacity: 1;
  transform: translateX(0%) translateY(-50%) scale(1);
  transition: all 1s cubic-bezier(0.45, 0.24, 0.66, 0.89);
  
  @media (max-width: 768px) and (orientation: portrait) {
    position: unset;
    transition: unset;
    transform: translateY(-40%);
    width: 100%;
    height: 100%;
  }
  
  &.leftbox-enter {
    opacity: 0.01;
    transform: translateX(-100%) translateY(-50%) scale(0.9);
    @media (max-width: 768px) and (orientation: portrait) {
      transform: translateX(-100%) translateY(-40%) scale(0.9);
    }
  }

  &.leftbox-enter-active {
    opacity: 1;
    transform: translateX(0%) translateY(-50%) scale(1);
    @media (max-width: 768px) and (orientation: portrait) {
      transform: translateX(0%) translateY(-40%) scale(1);
    }
  }

  &.leftbox-exit {
    opacity: 1;
    transform: translateX(0%) translateY(-50%) scale(1);
    @media (max-width: 768px) and (orientation: portrait) {
      transform: translateX(0%) translateY(-40%) scale(1);
    }
  }

  &.leftbox-exit-active {
    opacity: 0.01;
    transform: translateX(-100%) translateY(-50%) scale(0.9);
    @media (max-width: 768px) and (orientation: portrait) {
      transform: translateX(-100%) translateY(-40%) scale(0.9);
    }
  }
`;

const RBox = styled(Box)`
  display: block;
  position: relative;

  width: 100%;
  min-height: ${props => (props.height ? `${props.height}px` : "480px")};
  transform: translate(0%) scale(1);
  opacity: 1;
  transition: all 0.75s cubic-bezier(0.45, 0.24, 0.66, 0.89);

  &.rightbox-enter {
    opacity: 0.01;
    transform: translate(100%) scale(0.9);
  }

  &.rightbox-enter-active {
    opacity: 1;
    transform: translate(0%) scale(1);
  }

  &.rightbox-exit {
    opacity: 1;
    transform: translate(0%) scale(1);
  }

  &.rightbox-exit-active {
    opacity: 0.01;
    transform: translate(100%) scale(1);
  }
`;

const QuickEditBox = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 768 })
  const [leftPanel, setLeftPanel] = useState(false);
  const [rightPanel, setRightPanel] = useState(false);
  const [canAnimate, setCanAnimate] = useState({ left: false, right: false });
  const [loaded, setLoaded] = useState({ right: false });
  const [scanId, setScanId] = useState(null);
  const {history, vesselId, loading, height} = props;


  const handleClick = (event, scanId) => {
    event.preventDefault();
    event.stopPropagation();
    if (leftPanel) {
      setScanId( scanId );
    } else {
      setLeftPanel(!leftPanel);
      setScanId( scanId );
    }
  };

  const toggleRightPanel = () => {
    setRightPanel(!rightPanel);
  };

  const toggleLeftPanel = () => {
    setLeftPanel(!leftPanel);
  };

  const allowRightPanel = () => {
    setCanAnimate({...canAnimate, right: true});
  };

  const disallowRightPanel = () => {
    setCanAnimate({...canAnimate, right: false});
  };

  const closeLeftPanel = event => {
    event.preventDefault();
    setLeftPanel( false );
  };

  return (
    <MainBox display="flex" marginBottom={4} height={isMobile ? leftPanel ? height : 150 : height}>
      <CSSTransition
        unmountOnExit
        in={leftPanel && !rightPanel}
        onExited={allowRightPanel}
        timeout={isMobile ? 0 : 1000}
        classNames={{
          enter: "leftbox-enter",
          enterActive: "leftbox-enter-active",
          exit: "leftbox-exit",
          exitActive: "leftbox-exit-active"
        }}
      >
        <LBox paddingBottom={2} background={colors.darkGray} elevated>
          <SideCardScanBox
            history={history}
            scanId={scanId}
            vesselId={vesselId}
            backButton={toggleLeftPanel}
            toggleRightPanel={toggleRightPanel}
          />
        </LBox>
      </CSSTransition>
      {loading && <Spinner big />}
      <Blueprint
        closeTooltip={closeLeftPanel}
        onClick={handleClick}
        toggle={leftPanel}
        exit={rightPanel}
        vesselId={vesselId}
      />
      <CSSTransition
        unmountOnExit
        in={rightPanel && canAnimate.right}
        onExited={disallowRightPanel}
        onEntered={() => setLoaded({ right: true })}
        onExit={() => setLoaded({ right: false })}
        timeout={750}
        classNames={{
          enter: "rightbox-enter",
          enterActive: "rightbox-enter-active",
          exit: "rightbox-exit",
          exitActive: "rightbox-exit-active"
        }}
      >
        <RBox
          rightPanel={rightPanel}
          background={colors.darkGray}
          elevated
          height={height}
        >
          <RightPanel
            close={toggleRightPanel}
            scanId={scanId}
            open={rightPanel}
            loaded={loaded.right}
          />
        </RBox>
      </CSSTransition>
    </MainBox>
  );
}

export default QuickEditBox;
