import { connect } from "react-redux";
import { CurrentUserBox } from "../components";

import {
  getCurrentUser,
  getFullName,
  getEmail,
  getProperty,
  isCurrentUserFetching
} from "../../../entities/user/reducer";

const mapStateToProps = state => {
  const user = getCurrentUser(state);
  return {
    fullname: getFullName(user),
    email: getEmail(user),
    avatar: getProperty(user, 'avatar'),
    logo: getProperty(user, 'logo'),
    isLoading: isCurrentUserFetching(state)
  };
};

const mapDispatchToProps = dispatch => ({
  signOut: () => dispatch({ type: "LOGOUT_REQUEST" })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CurrentUserBox);
