import React from "react";
import styled from "styled-components";
import { CSSTransition } from "react-transition-group";

import Label from "../Fields/StyledLabel";
import StyledInput from "../Fields/StyledInput";

const InputContainer = styled.div`
  width: 100%;
  margin-bottom: 24px;
`;

const Error = styled.span`
  display: flex;
  opacity: 1;
  position: relative;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  padding: 8px 16px;
  background: #4d2222;
  color: #787878;
  border: 1px solid #4d2222;
  margin-left: auto;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  transition: all 500ms ease;

  &.error-enter {
    opacity: 0.01;
  }

  &.error-enter-active {
    opacity: 1;
  }

  &.error-exit {
    opacity: 1;
  }

  &.error-exit-active {
    opacity: 0.01;
  }
`;

const Input = ({
  label,
  name,
  placeholder,
  dark,
  light,
  error,
  type,
  onChange,
  _ref,
  onBlur,
  dashboard,
  value,
  disabled,
  readOnly,
  required,
}) => {
  return (
    <InputContainer>
      <Label
        dark={dark}
        dashboard={dashboard}
        light={light}
        htmlFor={name}
        required={required}
      >
        {label}
      </Label>
      <StyledInput
        dashboard={dashboard}
        dark={dark}
        light={light}
        error={error}
        name={name}
        placeholder={placeholder}
        type={type}
        id={name}
        onChange={onChange}
        onBlur={onBlur}
        ref={_ref}
        value={value}
        disabled={disabled}
        readOnly={readOnly}
      />
      <CSSTransition
        in={typeof error === "string"}
        timeout={500}
        unmountOnExit
        classNames={{
          enter: "error-enter",
          enterActive: "error-enter-active",
          exit: "error-exit",
          exitActive: "error-exit-active",
        }}
      >
        <Error>{error}</Error>
      </CSSTransition>
    </InputContainer>
  );
};

export default Input;
