import React, { PureComponent } from "react";

import Input from "../../../components/Input/Input";
import Checkbox from "../../../components/Input/Checkbox";
import { Box, Button } from "../../../components/Dashboard";

class AddUserForm extends PureComponent {
  render() {
    return (
      <form>
        <Box display="flex">
          <Box display="flex" flexDirection="col" flex="grow" paddingRight={1}>
            <Input
              label="First Name"
              name="firstName"
              placeHolder="User's first name"
              type="text"
              dashboard
            />
            <Input
              label="Last Name"
              name="lastName"
              placeHolder="User's last name"
              type="text"
              dashboard
            />
          </Box>
          <Box display="flex" flexDirection="col" flex="grow" paddingLeft={1}>
            <Input
              label="Access Type"
              name="accessType"
              placeHolder="User's rights"
              type="text"
              dashboard
            />
            <Input
              label="Email"
              name="email"
              placeHolder="User's email"
              type="email"
              dashboard
            />
            <Checkbox name="isEmployee" theme="darkAlt" dashboard>
              <Checkbox.Input />
              <Checkbox.Label>
                Is this person an employee of your organization?
              </Checkbox.Label>
            </Checkbox>
            <Button type="submit" secondary rounded fullWidth>
              Invite User
            </Button>
          </Box>
        </Box>
      </form>
    );
  }
}

export default AddUserForm;
