import React, { useReducer } from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import { Box, H1, Divider, UI } from "../../../components/Dashboard";

import {
  MessageList,
  MessageTextBox,
  Messages,
  MessageReceiver,
  UserMultiSelect
} from "./components";

import { getAllUsers } from "../../../global/rootReducer";
import { getProperty } from "../../../entities/user/reducer";

const Container = styled(Box)`
  border-left: 1px solid ${UI.colors.darkGray};
`;

const initialState = {
  isOpen: false
};

const reducer = (state, action) => {
  switch (action.type) {
    case "show_compose_message":
      return {
        ...state,
        isOpen: true
      };
    case "hide_compose_message":
      return {
        ...state,
        isOpen: false
      };
    default: {
      return state;
    }
  }
};

const Inbox = ({ items }) => {
  const [{ isOpen }, dispatch] = useReducer(reducer, initialState);
  const handleClick = () => dispatch({ type: "show_compose_message" });
  const handleClose = () => dispatch({ type: "hide_compose_message" });

  const newMessage = () => {
    // function that sends message
    handleClose();
  };

  return (
    <Box>
      <H1>Inbox</H1>
      <Divider />
      <Box display="flex" minHeight={640}>
        <MessageList onComposeClick={handleClick} />
        <Container
          display="flex"
          width="100%"
          direction="col"
          background={UI.colors.lightGray}
        >
          <MessageReceiver
            text={isOpen ? "New message" : "Message with Pantelis Paliagkas"}
          />
          <Divider color="darkGray" marginBottom="0px" />
          {isOpen ? (
            <Box marginTop={2} paddingHorizontal={3} zIndex={10}>
              <UserMultiSelect items={items} />
            </Box>
          ) : (
            <Messages />
          )}
          {!isOpen && <Divider color="darkGray" marginBottom="0px" />}
          <MessageTextBox onSubmit={newMessage} onDiscard={handleClose} />
        </Container>
      </Box>
    </Box>
  );
};

const mapStateToProps = state => {
  const users = getAllUsers(state);
  return {
    items: users.map(user => ({
      id: user.id,
      value: user.fullname,
      avatar: getProperty(user, 'avatar')
    }))
  };
};

export default connect(mapStateToProps)(Inbox);
