import { connect } from "react-redux";
import {
  getAllVessels,
  userCanAddVessel
} from "../../../../global/rootReducer";

import { VesselsBox } from "../components";

const mapStateToProps = state => {
  return {
    allVessels: getAllVessels(state),
    canAddVessel: userCanAddVessel(state),
    isFetching: state.vessels.isFetching
  };
};

export default connect(mapStateToProps)(VesselsBox);
