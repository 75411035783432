import React from "react";
import PropTypes from "prop-types";

const Caret = (props) => {
  const { direction, style } = props;
  let path;
  switch (direction) {
    case 'top':
      path = 'M0 0 L12 12 L24 0';
      break;
    case 'right':
      path = 'M24 0 L12 12 L24 24';
      break;
    case 'bottom':
      path = 'M0 24 L12 12 L24 24';
      break;
    case 'left':
      path = 'M0 0 L12 12 L0 24';
      break;
    default:
      path = 'M0 0 L12 12 L24 0';
      break;
  }

  return (
    <svg width="24" height="24" style={style}>
      <path d={path} />
    </svg>
  )
}

Caret.propTypes = {
  direction: PropTypes.oneOf(['top', 'right', 'bottom', 'left'])
};

export default Caret;