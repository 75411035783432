import { ofType, combineEpics } from "redux-observable";
import { of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { map, catchError, switchMap } from "rxjs/operators";
import { normalize, schema } from "normalizr";

import { URL, createFormData } from "../../global/utilities";
import { getToken } from "../auth/reducer";

import * as userActions from "./actions";
import { store } from "react-notifications-component";
const userGroup = new schema.Entity("usergroups");
const user = new schema.Entity("users", {
  userGroup: userGroup,
});
const userList = [user];

const fetchUserEpic = (action$, state$) =>
  action$.pipe(
    ofType(userActions.USER.REQUEST),
    map(() => state$.value),
    map((state) => getToken(state)),
    switchMap((token) =>
      ajax({
        url: `${URL}/secured/users/me`,
        headers: {
          Authorization: token,
        },
      }).pipe(
        map((response) => {
          localStorage.setItem(
            "account_id",
            response.response.account_id || ""
          );
          return userActions.userSuccess(normalize(response.response, user));
        }),
        catchError((e) =>
          of(userActions.userError({ message: e.message, status: e.status }))
        )
      )
    )
  );

const fetchUsersEpic = (action$, state$) =>
  action$.pipe(
    ofType(userActions.USERS.REQUEST),
    map(() => state$.value),
    map((state) => getToken(state)),
    switchMap((token) =>
      ajax({
        url: `${URL}/secured/users`,
        headers: {
          Authorization: token,
        },
      }).pipe(
        map((response) => {
          return userActions.usersSuccess(
            normalize(response.response, userList)
          );
        }),
        catchError((e) =>
          of(userActions.usersError({ message: e.message, status: e.status }))
        )
      )
    )
  );

const updateUserEpic = (action$, state$) =>
  action$.pipe(
    ofType(userActions.USER_UPDATE.REQUEST),
    map(({ payload }) => ({ token: getToken(state$.value), payload: payload })),
    switchMap(({ token, payload }) =>
      ajax({
        method: "post",
        url: `${URL}/secured/users/changePassword`,
        headers: {
          Authorization: token,
        },
        body: createFormData(payload.values),
      }).pipe(
        map((response) => {
          store.addNotification({
            title: "Success",
            message: "You successfully changed your password",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeInRight"],
            animationOut: ["animate__animated", "animate__fadeOutRight"],
            dismiss: {
              duration: 5000,
              delay: 1000,
              showIcon: true,
            },
            slidingEnter: {
              duration: 2000,
            },
          });
          return userActions.userUpdateSuccess(
            normalize(response.response, user)
          );
        }),
        catchError((e) =>
          of(
            userActions.userUpdateError({
              message: e.message,
              status: e.status,
            })
          )
        )
      )
    )
  );

const updateUserAvatar = (action$, state$) =>
  action$.pipe(
    ofType(userActions.AVATAR_UPDATE.REQUEST),
    map(({ payload }) => ({ token: getToken(state$.value), payload: payload })),
    switchMap(({ token, payload }) =>
      ajax({
        method: "put",
        url: `${URL}/secured/users/avatar`,
        headers: {
          Authorization: token,
        },
        body: createFormData(payload.values),
      }).pipe(
        map((response) => {
          store.addNotification({
            title: "Success",
            message: "You successfully changed your avatar",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeInRight"],
            animationOut: ["animate__animated", "animate__fadeOutRight"],
            dismiss: {
              duration: 5000,
              delay: 1000,
              showIcon: true,
            },
            slidingEnter: {
              duration: 2000,
            },
          });
          return userActions.userUpdateSuccess(
            normalize(response.response, user)
          );
        }),
        catchError((e) =>
          of(
            userActions.userUpdateError({
              message: e.message,
              status: e.status,
            })
          )
        )
      )
    )
  );

export default combineEpics(
  fetchUsersEpic,
  fetchUserEpic,
  updateUserEpic,
  updateUserAvatar
);
