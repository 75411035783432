import { combineReducers } from "redux";
import * as previewTypes from "./actions";

const scan = (state = {}, action) => {
  switch (action.type) {
    case previewTypes.PREVIEW_SCAN.SUCCESS:
      return {
        ...state,
        ...action.payload.data.entities.scan
      };
    default:
      return state;
  }
};

const currentScan = (state = {}, action) => {
  switch (action.type) {
    case previewTypes.PREVIEW_SCAN.SUCCESS:
      return {
        id: action.payload.data.result
      };
    default:
      return state;
  }
};

const previews = combineReducers({
  scan,
  currentScan
});

export default previews;

export const getCurrentScan = state => {
  return state.previews.scan[state.previews.currentScan.id];
};

export const getVesselofScan = (state, vesselId) => {
  return state.previews.vessel[vesselId];
};
