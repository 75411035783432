import React from "react";

import Checkbox from "../../../../../components/Input/Checkbox";
import TextArea from "../../../../../components/Input/TextArea";
import { Box, Text } from "../../../../../components/Dashboard";

const AREAS = [
  { name: "Bridge" },
  { name: "Engine Room" },
  { name: "Bosun Store" },
  { name: "Pump Room" },
  { name: "Foam Room" },
  { name: "CO2 Control Room" },
  { name: "Ballas Control Room" },
  { name: "Engine Control Room" },
  { name: "Emergency Generator" },
  { name: "Steering Room" },
  { name: "Main Deck" },
  { name: "Moorings" }
];

const SecondStep = ({ handleChange, setFieldValue, types, flags }) => (
  <Box display="flex" wrap marginBottom={2}>
    <Text marginBottom={3} color="smoke">
      Select the areas you want scanned:{" "}
    </Text>
    <Box
      display="flex"
      width="100%"
      large={`flex-wrap:wrap`}
      wrap
      marginBottom={2}
    >
      {AREAS.map(area => {
        return (
          <Box
            key={area.name}
            display="flex"
            medium={`width:100%`}
            large={`width:50%`}
            xlarge={`width:50%`}
            width="33%"
          >
            <Checkbox name={area.name.toLowerCase()} dashboard theme="darkAlt">
              <Checkbox.Input onChange={handleChange} />
              <Checkbox.Label>{area.name}</Checkbox.Label>
            </Checkbox>
          </Box>
        );
      })}
    </Box>
    <TextArea
      label="Other (describe)"
      dark
      placeholder="Areas you want scanned not mentioned above"
    />
  </Box>
);

export default SecondStep;
