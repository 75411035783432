import axios from "axios";
import { put, take, select, fork, call } from "redux-saga/effects";
import { normalize, schema } from "normalizr";

import * as actions from "./actions";
import { getToken } from "../auth/reducer";

import { URL_NEW } from "../../global/utilities";

const URL = URL_NEW;

const flag = new schema.Entity("flags");
const type = new schema.Entity("types");
const thumbnail = new schema.Entity("thumbnails");
const image = new schema.Entity("images");

// could be by name but will do it with id for now
// const scan = new schema.Entity("scans", {}, { idAttribute: "title" });
const view = new schema.Entity("views", {
  thumbnail: thumbnail
});

// Defines vessel entity
const vessel = new schema.Entity("vessels", {
  flag: flag,
  type: type,
  image: image,
  views: [view]
});

const getVesselAPI = (token, vesselId) =>
  axios.get(`${URL}/secured/vessels/${vesselId}`, {
    headers: {
      Authorization: token
    }
  });

const postVessel = (token, data) => {
  return axios(`${URL}/secured/vessels`, {
    method: "post",
    headers: {
      Authorization: token
    },
    data: {
      ...data.payload
    }
  });
};

function* getVessel({ vesselId }) {
  try {
    const token = yield select(getToken);
    const res = yield call(getVesselAPI, token, vesselId);
    // const normalized = normalize(res.data, vessel);
    yield put(actions.vesselSuccess(normalize(res.data, vessel)));
    // yield put({ type: "TEST", payload: normalize(res.data, singleVessel) });
  } catch (e) {
    yield put(actions.vesselError(e));
  }
}

export function* watchVessels() {
  // while (true) {
  //   // yield take(actions.VESSELS.REQUEST);
  //   // yield fork(getVessels);
  // }
}

export function* watchVessel() {
  while (true) {
    const action = yield take(actions.VESSEL.REQUEST);
    yield fork(getVessel, action.payload);
  }
}

function* saveVessel(data) {
  try {
    const token = yield select(getToken);
    const res = yield call(postVessel, token, data);
    const normalized = normalize(res.data, vessel);
    yield put(actions.createVesselSuccess(normalized));
  } catch (e) {
    yield put(actions.createVesselError(e));
  }
}

export function* watchSaveVessel() {
  while (true) {
    const data = yield take(actions.CREATE_VESSEL.REQUEST);
    yield fork(saveVessel, data);
  }
}
