import { combineReducers } from "redux";

import * as userTypes from "./actions";
import * as authTypes from "../auth/actions";

const byId = (state = {}, action) => {
  switch (action.type) {
    case userTypes.USER.SUCCESS:
      return {
        ...state,
        ...action.payload.data.entities.users
      };
    case userTypes.USERS.SUCCESS:
      return {
        ...state,
        ...action.payload.data.entities.users
      };
    case userTypes.USER_UPDATE.SUCCESS:
      return {
        ...state,
        [action.payload.data.result]:
          action.payload.data.entities.users[action.payload.data.result]
      };
    default:
      return state;
  }
};

const userGroups = (state = {}, action) => {
  switch (action.type) {
    case userTypes.USER.SUCCESS:
      return {
        ...state,
        ...action.payload.data.entities.usergroups
      };
    case userTypes.USERS.SUCCESS:
      return {
        ...state,
        ...action.payload.data.entities.usergroups
      };
    default:
      return state;
  }
};

const currentUser = (state = {}, action) => {
  switch (action.type) {
    case userTypes.USER.SUCCESS:
      return {
        id: action.payload.data.result
      };
    default:
      return state;
  }
};

const profile = (state = {}, action) => {
  switch (action.type) {
    case authTypes.LOGIN.REQUEST:
      return {
        username: action.payload.username,
        password: action.payload.password
      };

    case "LOGOUT":
      return {};
    default:
      return state;
  }
};

const isFetching = (state = {}, action) => {
  switch (action.type) {
    case userTypes.USER.REQUEST:
      return {
        ...state,
        currentUser: true
      };
    case userTypes.USERS.REQUEST:
      return {
        ...state,
        users: true
      };
    case userTypes.USER.SUCCESS:
    case userTypes.USER.ERROR:
      return {
        ...state,
        currentUser: false
      };
    case userTypes.USERS.SUCCESS:
    case userTypes.USERS.ERROR:
      return {
        ...state,
        users: false
      };
    default:
      return state;
  }
};

const isUpdating = (state = {}, action) => {
  switch (action.type) {
    case userTypes.USER_UPDATE.REQUEST:
      switch (action.meta) {
        case "UPDATE_USERNAME":
          return { ...state, username: true };
        case "UPDATE_PASSWORD":
          return { ...state, password: true };
        case "UPDATE_PROFILE":
          return { ...state, profile: true };
        default:
          return state;
      }
    case userTypes.USER_UPDATE.SUCCESS:
    case userTypes.USER_UPDATE.ERROR:
      return {
        username: false,
        password: false,
        profile: false
      };
    default:
      return state;
  }
};

const users = combineReducers({
  profile,
  currentUser,
  byId,
  isFetching,
  isUpdating,
  userGroups
});

export default users;

export const getAllUserIds = state => state.users.byId;
export const isCurrentUserFetching = state =>
  state.users.isFetching.currentUser;
export const getCurrentUser = state => {
  return state.users.byId[state.users.currentUser.id];
};

export const getUserById = (state, id) => state.users.byId[id];

export const getFullName = user => {
  return user ? user.fullname : undefined;
};

export const getUsername = user => {
  return user ? user.username : "";
};

export const getEmail = user => {
  return user ? user.email : undefined;
};

export const getProperty = (user, property) => {
  return user ? user[property] : undefined;
};

export const getUserGroupForUser = (state, user) => {
  return user ? state.users.userGroups[user.userGroup] : null;
};
