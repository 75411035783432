import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Icon from "react-ionicons";
import styles from "../../NavBar/NavBar.module.css";
import { colors } from "../UI/ui";

import Portal from "../Portal/Portal";
import Search from "../Search/Search";
import Column from "antd/lib/table/Column";

const Menu = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  z-index: 2;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    z-index: -1;
    left: 0;
    height: 1px;
    width: 100%;
    background: ${colors.smoke};
  }
  @media (max-width: 768px) {
    &::before {
      height: 0px;
    }
  }
`;

const MenuItems = styled.div`
  display: flex;
  flex: 1 0 auto;
  flex-wrap: wrap;
  justify-content: start;
  background: transparent;

  @media (max-width: 998px) {
    display: ${(props) => (props.show ? "flex" : "none")};
    flex-direction: column;
  }
`;

const MenuActions = styled.div`
  flex: 1 0 0;
  justify-content: space-between;
  text-align: right;

  @media (max-width: 576px) {
    display: none;
  }
`;

const MenuItem = styled(Link)`
  position: relative;
  display: block;
  min-width: 160px;
  width: 160px;
  font-family: "Roboto", sans-seif;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  color: ${(props) => (props.active ? colors.lightblue : colors.smoke)};
  background: transparent;
  padding-bottom: 16px;
  border-bottom: ${(props) => {
    if (props.active) {
      return `5px solid ${colors.lightblue}`;
    }
    return "5px solid transparent";
  }};
  cursor: pointer;
  transition: all 325ms ease-in-out;

  @media (max-width: 768px) {
    padding-bottom: 5px;
    margin-bottom: 10px;
  }

  &:hover {
    color: ${colors.lightblue};
    text-decoration: none;
  }
`;

const Badge = styled.span`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -25%;
  right: 15%;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  background: #b11226;
  color: ${colors.white};
`;

const SearchIcon = styled(Icon).attrs({
  fontSize: "30px",
  color: colors.smoke,
  icon: "ios-search",
})`
  cursor: pointer;
`;

const NavBar = ({ links, currentLocation }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [show, setShow] = useState(false);
  const Burger = ({ light }) => (
    <div
      className={`${styles.navbarBurger} ${light &&
        styles.navbarBurgerDark} mb-20`}
      onClick={() => setShow(!show)}
    >
      <span />
      <span />
      <span />
    </div>
  );
  const togggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Burger />
      <Menu show={show}>
        <Portal
          isOpen={isOpen}
          render={({ isOpen }) => {
            return (
              <Search
                isOpen={isOpen}
                theme="dark"
                timeOut={250}
                onClose={togggleDrawer}
              />
            );
          }}
        />

        <MenuItems show={show}>
          {links.map((link) => {
            if (currentLocation.includes(link.path)) {
              return (
                <MenuItem active="true" key={link.name} to={link.path}>
                  {link.name}
                </MenuItem>
              );
            } else if (link.name === "Inbox") {
              return (
                <MenuItem key={link.name} to={link.path}>
                  <Badge>2</Badge>
                  {link.name}
                </MenuItem>
              );
            }
            return (
              <MenuItem key={link.name} to={link.path}>
                {link.name}
              </MenuItem>
            );
          })}
        </MenuItems>
        <MenuActions>
          <SearchIcon onClick={togggleDrawer} />
        </MenuActions>
      </Menu>
    </>
  );
};

export default NavBar;

NavBar.propTypes = {
  links: PropTypes.array.isRequired,
};
