import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";

import { getCurrentUser } from "../../../entities/user/reducer";

import Icon from "react-ionicons";

import Box from "../Boxes/Box";
import Portal from "../Portal/Portal";
import SlideIn from "../../Crossfade/SlideIn";
import { Text, Divider } from "../Typography/Typography";
import { colors } from "../UI/ui";

import ChatMessage from "./ChatMessage";

const ChatTextArea = styled.textarea`
  min-height: 160px;
  width: 100%;
  min-width: 100%;
  border: none;
  border-top: 0.5px solid rgba(151, 151, 151, 1);
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 300;
  color: ${colors.smoke};
  outline: none;
  box-shadow: none;
  -webkit-appearance: none;
  background: ${colors.darkGray};
  padding: 24px 16px;
  resize: none;
`;

const Circle = styled.div`
  display: block;
  height: 20px;
  width: 20px;
  background-color: #ff0624;
  background-color: ${props =>
    props.on ? "rgba(255,6,36,1)" : "rgba(74,74,74,1)"};
  border-radius: 10px;
  border: ${props => (props.on ? "none" : "2px solid rgba(216,216,216,1)")};
`;

const ChatButtonWrapper = styled(Box)`
  &:hover {
    cursor: pointer;
  }
`;

const ChatContainer = styled(Box)`
  overflow: hidden;
  overflow-y: auto;
  height: 100%;

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: ${colors.darkGray};
  }

  ::-webkit-scrollbar {
    width: 6px;
    background-color: ${colors.darkGray};
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${colors.black};
    border-radius: 3px;
  }
`;

const ArrowIcon = styled(Icon).attrs({
  fontSize: "30px",
  color: colors.white,
  icon: "ios-arrow-forward"
})`
  cursor: pointer;
`;

class ChatBox extends PureComponent {
  messageBox = React.createRef();

  state = { isChatOpen: false, message: "" };

  componentDidMount() {
    const { sender, joinRoom, scan } = this.props;
    if (scan) {
      joinRoom({ userId: sender.id, roomId: scan.id });
    }
  }

  componentDidUpdate(prevProps) {
    const { messageList, joinRoom, leaveRoom, sender, scan } = this.props;
    const { isChatOpen } = this.state;
    if (isChatOpen && messageList !== prevProps.messageList) {
      this.scrollToBottom();
    }

    if (scan && sender) {
      if (scan.id !== prevProps.scan.id) {
        if (!prevProps.scan.id) {
          joinRoom({ userId: sender.id, roomId: scan.id });
        } else {
          leaveRoom({ userId: sender.id, roomId: scan.id });
          joinRoom({ userId: sender.id, roomId: scan.id });
        }
      }
    }
  }

  componentWillUnmount() {
    const { leaveRoom, sender, scan } = this.props;
    leaveRoom({ userId: sender.id, roomId: scan.id });
  }

  scrollToBottom() {
    this.messageBox.current.parentNode.scrollTo({
      top: this.messageBox.current.offsetTop,
      behavior: "smooth"
    });
  }

  toggleChat = () => {
    const { isChatOpen } = this.state;
    this.setState({ isChatOpen: !isChatOpen });
  };

  handleChange = event => {
    this.setState({ message: event.target.value });
  };

  handleKeyPress = event => {
    const { message } = this.state;
    const { sendMessage, sender, scan } = this.props;
    if (message) {
      if (event.shiftKey && event.key === "Enter") {
        event.preventDefault();
        this.setState({ message: `${message}` });
      } else if (event.key === "Enter") {
        event.preventDefault();
        sendMessage({ userId: sender.id, message: message, roomId: scan.id });
        this.setState({ message: "" });
      }
    }
  };

  render() {
    const { isChatOpen, message } = this.state;
    const { messageList } = this.props;
    return (
      <Box>
        <Portal
          isOpen={isChatOpen}
          render={({ isOpen }) => {
            return (
              <SlideIn
                in={isOpen}
                unmountOnExit
                top="30%"
                right="0px"
                height="70%"
                width={480}
              >
                <Box
                  width="100%"
                  height="100%"
                  background={colors.darkGray}
                  direction="col"
                  display="flex"
                  elevated
                >
                  <Box
                    direction="row"
                    display="flex"
                    justify="between"
                    alignItems="center"
                    paddingVertical={2}
                    paddingHorizontal={2}
                    height={64}
                  >
                    <Box display="flex" flex="grow" alignItems="center">
                      <Circle on />
                      <Text marginLeft={2} color="white">
                        Chat on
                      </Text>
                    </Box>
                    <ArrowIcon onClick={this.toggleChat} />
                  </Box>
                  <Divider noMargin />
                  <ChatContainer
                    alignItems="start"
                    paddingLeft={2}
                    paddingVertical={3}
                    direction="col"
                  >
                    {messageList.map((message, index) => {
                      return (
                        <ChatMessage
                          key={index}
                          message={message.message}
                          userId={message.userId}
                        />
                      );
                    })}
                    <div ref={this.messageBox} />
                  </ChatContainer>
                  <Box display="flex" flex="shrink">
                    <ChatTextArea
                      placeholder="Type your message..."
                      name="chatMessage"
                      value={message}
                      onChange={this.handleChange}
                      onKeyUp={this.handleKeyPress}
                    />
                  </Box>
                </Box>
              </SlideIn>
            );
          }}
        />
        <ChatButtonWrapper
          display="flex"
          direction="row"
          alignItems="center"
          height={48}
          elevated
          onClick={this.toggleChat}
        >
          <Box
            width={48}
            height={48}
            background="#000000"
            display="flex"
            justify="center"
            alignItems="center"
          >
            <Circle on={isChatOpen} />
          </Box>
          <Box
            display="flex"
            alignItems="center"
            paddingLeft={3}
            paddingRight={4}
            background={colors.darkGray}
          >
            <Text color="white">{isChatOpen ? "Chat on" : "Chat off"}</Text>
          </Box>
        </ChatButtonWrapper>
      </Box>
    );
  }
}

ChatBox.defaultProps = {
  isChatOn: true
};

ChatBox.propTypes = {
  isChatOn: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  messageList: state.messages.list,
  sender: getCurrentUser(state)
});

const mapDispatchToProps = dispatch => ({
  joinRoom: payload => {
    return dispatch({ type: "JOIN_ROOM", payload: { ...payload } });
  },
  leaveRoom: payload => {
    return dispatch({ type: "LEAVE_ROOM", payload: { ...payload } });
  },
  sendMessage: payload => {
    return dispatch({ type: "SEND_MESSAGE", payload: { ...payload } });
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChatBox);
