import React, { PureComponent } from "react";

import Crossfade from "../../../../components/Crossfade/Crossfade";

import {
  Box,
  Button,
  Portal,
  H1,
  Divider,
  UI,
  Spinner,
} from "../../../../components/Dashboard";

import VesselBox from "../containers/VesselBox";
import { AddVesselForm } from "../../components/index";

class VesselsBox extends PureComponent {
  // this functionality should be moved into own component
  state = { toggle: false };

  toggleModal = () => {
    const { toggle } = this.state;
    this.setState({ toggle: !toggle });
  };

  stop = (event) => {
    event.stopPropagation();
  };

  renderVesselList = () => {
    const { allVessels } = this.props;
    return allVessels.map((vessel) => {
      return (
        <Box marginRight={2} marginBottom={2} key={vessel.id}>
          <VesselBox key={vessel.id} vessel={vessel} />
        </Box>
      );
    });
  };

  render() {
    const { toggle } = this.state;
    const { title, canAddVessel, isFetching } = this.props;
    return (
      <Box marginBottom={5} >
        <Portal
          isOpen={toggle}
          render={({ isOpen }) => {
            return (
              <Crossfade in={isOpen} timeout={250} unmountOnExit>
                <Box
                  onMouseDown={this.toggleModal}
                  position="fixed"
                  display="flex"
                  justify="center"
                  alignItems="center"
                  width="100%"
                  height="100%"
                >
                  <Box
                    onMouseDown={this.stop}
                    position="relative"
                    minWidth="70%"
                    maxWidth="50%"
                    minHeight="50%"
                    maxHeight="90%"
                    alignSelf="auto"
                    background={UI.colors.darkGray}
                    paddingVertical={4}
                    paddingHorizontal={5}
                    overflowX="hidden"
                    elevated
                  >
                    <AddVesselForm closeOnSubmit={this.toggleModal} />
                  </Box>
                </Box>
              </Crossfade>
            );
          }}
        />
        <Box
          justify="between"
          display="flex"
          medium={{
            textAlign: 'center',
            justifyContent: 'center'
          }}
        >
          <H1>{title}</H1>
          {/*{canAddVessel && (*/}
          {/*  <Button rounded secondary onClick={this.toggleModal}>*/}
          {/*    Add Vessels*/}
          {/*  </Button>*/}
          {/*)}*/}
        </Box>
        <Divider />
        <Box
          display="flex"
          width="100%"
          wrap
          medium={{
            justifyContent: 'center'
          }}
        >
          {isFetching ? <Spinner big /> : this.renderVesselList()}
        </Box>
      </Box>
    );
  }
}

export default VesselsBox;
