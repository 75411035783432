import React, { PureComponent } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Icon from "react-ionicons";

import { getScanById } from "../../../entities/scans/reducer";

import { logoWhite as logo } from "../../../utilities";
import { Box, Text, Divider, UI } from "../../../components/Dashboard";
import ScanBox from "../Scan/ScanBox";

const { colors } = UI;

const Back = styled(Icon).attrs({
  fontSize: "18px",
  color: colors.smoke,
  icon: props => (props.activeIndex === 0 ? "md-close" : "ios-arrow-forward")
})`
  cursor: pointer;
`;

const Tag = styled(Box)`
  transition: all 250ms ease-in;
  &:hover {
    background: ${colors.lightGray};
    cursor: pointer;
  }
`;

const Pill = styled.div`
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: ${colors.white};
  background: ${colors.lightblue};
  padding: 8px;
  border-radius: 50%;
  text-align: center;
`;

const ActionItem = styled(Box)`
  opacity: ${props => (props.disabled ? 0.75 : 1)};
  &:hover {
    cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  }
`;

const SmallPoint = styled.span`
  display: block;
  height: 6px;
  width: 6px;
  background: ${colors.lightblue};
  border-radius: 50%;
  margin-right: 8px;
  border: ${props => (props.selected ? "2px solid #ff8f00" : "none")};
`;

class RightPanel extends PureComponent {
  state = { activeIndex: 0, sid: null, clientHeight: 0 };

  boxRef = React.createRef();

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.loaded !== this.props.loaded && this.boxRef.current) {
      this.setState({
        clientHeight: this.boxRef.current.clientHeight
      });
    }
  }

  changeTabs = index => {
    this.setState({ activeIndex: index });
  };

  selectNote = (sid, callback) => {
    callback(sid);
    this.setState({ sid: sid });
  };

  renderStatus = ({ noteStatus }) => {
    let status = {};
    if (noteStatus.isNoteTakingActive) {
      if (noteStatus.isTooltipActive) {
        status = {
          icon: "ios-locate-outline",
          text:
            "A Note is being created. Save to submit or Cancel to move to the previous screen.",
          color: colors.lightblue,
          beat: true
        };
      } else {
        status = {
          icon: "md-information-circle",
          text:
            "Note Creation Mode is active. Click on the image area you choose to define where you would like your Note to appear.",
          color: colors.smoke,
          beat: false
        };
      }
    } else {
      if (
        !noteStatus.isSubmitting &&
        !noteStatus.submitFailed &&
        !noteStatus.submitSuccess
      ) {
        status = {
          icon: "md-information-circle",
          text:
            "Select “Add a Note” to annotate a specific point of the Vessel View.",
          color: colors.smoke,
          beat: false
        };
      } else if (noteStatus.submitSuccess) {
        status = {
          icon: "md-checkmark-circle-outline",
          text:
            "Your Note was successfully submitted! Your updated Vessel View will be visible to all visitors within an hour.",
          color: colors.green,
          beat: true
        };
      } else if (noteStatus.isSubmitting) {
        status = {
          icon: "ios-locate-outline",
          text: "The Note was created and submitted to Vesselfront.",
          color: colors.lightblue,
          beat: true
        };
      } else if (noteStatus.submitFailed) {
        status = {
          icon: "md-close-circle",
          text:
            "The Note failed to be submitted due to an error. Please try again.",
          color: colors.crimson,
          beat: true
        };
      }
    }

    return (
      <Box
        display="flex"
        flex="shrink"
        alignItems="center"
        paddingHorizontal={2}
        paddingVertical={2}
      >
        <Icon
          icon={status.icon}
          fontSize="32px"
          color={status.color}
          beat={status.beat}
        />
        <Text marginLeft={1} semiBold small>
          {status.text}
        </Text>
      </Box>
    );
  };

  renderMenu = (state, getSpacePose, clearScreenShot) => {
    return (
      <Box
        display="flex"
        flex="grow"
        flexDirection="col"
        alignItems="start"
        paddingHorizontal={2}
        paddingVertical={2}
      >
        <ActionItem
          display="flex"
          alignItems="center"
          marginBottom={2}
          onClick={getSpacePose}
        >
          <Icon icon="md-add" fontSize="24px" color={colors.smoke} />
          <Text marginLeft={2} color="smoke">
            Add a Note
          </Text>
        </ActionItem>
        <ActionItem
          display="flex"
          alignItems="center"
          marginBottom={2}
          onClick={() => {
            clearScreenShot();
            this.changeTabs(1);
          }}
        >
          <Icon
            icon="ios-clipboard-outline"
            fontSize="24px"
            color={colors.smoke}
          />
          <Text marginHorizontal={2} color="smoke">
            All Notes
          </Text>
          {state.tags.length > 0 && <Pill>{state.tags.length}</Pill>}
        </ActionItem>
        <ActionItem
          display="flex"
          alignItems="center"
          marginBottom={2}
          disabled
        >
          <Icon icon="md-help" fontSize="24px" color={colors.smoke} />
          <Text marginLeft={2} color="smoke">
            Ask a question
          </Text>
        </ActionItem>
      </Box>
    );
  };

  renderNotes = (state, navigateToNote) => {
    const { tags, sid } = state;
    return (
      <Box
        display="flex"
        flexDirection="col"
        alignItems="start"
        height={this.props.height}
        style={{ overflowX: "hidden", overflowY: "auto" }}
      >
        {tags.map(tag => {
          return (
            <Tag
              display="block"
              paddingHorizontal={2}
              paddingTop={2}
              key={tag.sid}
              onClick={() => this.selectNote(tag.sid, navigateToNote)}
            >
              <Box display="flex" alignItems="center" marginBottom={1}>
                <SmallPoint selected={sid === tag.sid} />
                <Text small color="smoke" semiBold>
                  {tag.label}
                </Text>
              </Box>
              <Text marginBottom={1} small paddingLeft={2}>
                {tag.description}
              </Text>
            </Tag>
          );
        })}
      </Box>
    );
  };

  renderLoading = () => {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
        height="100%"
        background={colors.darkGray}
      >
        <img src={logo} height={64} alt="vesselfront-logo" />
      </Box>
    );
  };

  renderScan = () => {
    const { close, scan } = this.props;
    const { clientHeight } = this.state;
    if (scan) {
      const { activeIndex } = this.state;
      return (
        <ScanBox
          width={"100%"}
          height={clientHeight}
          scan={scan}
          render={(
            state,
            renderMatterportSpace,
            renderScreenshot,
            getSpacePose,
            navigateToNote,
            clearScreenShot
          ) => (
            <Box display="flex" minHeight="100%" _ref={this.boxRef}>
              <Box width="75%" height="100%" elevated>
                {renderMatterportSpace()}
                {renderScreenshot()}
              </Box>
              <Box
                display="flex"
                width="25%"
                flexDirection="column"
                alignItems="flex-start"
              >
                <Box>
                  <Box
                    paddingHorizontal={2}
                    paddingVertical={2}
                    display="flex"
                    justifyContent="between"
                  >
                    <Text bid color="smoke" semiBold>
                      {activeIndex === 0 ? "Vessel View Editor" : "All Notes"}
                    </Text>
                    <Back
                      onClick={
                        activeIndex === 0 ? close : () => this.changeTabs(0)
                      }
                      activeIndex={activeIndex}
                    />
                  </Box>
                  <Divider marginBottom={1} color="lightGray" />
                </Box>
                {activeIndex === 0
                  ? this.renderMenu(state, getSpacePose, clearScreenShot)
                  : this.renderNotes(state, navigateToNote)}

                {activeIndex === 0 && (
                  <Divider marginBottom="0" color="lightGray" />
                )}
                {activeIndex === 0 && this.renderStatus(state)}
              </Box>
            </Box>
          )}
        />
      );
    }
  };

  render() {
    const { loaded } = this.props;
    return loaded ? this.renderScan() : this.renderLoading();
  }
}

const mapStateToProps = (state, props) => {
  const scan = getScanById(state, props.scanId);
  return {
    scan: scan
  };
};

export default connect(mapStateToProps)(RightPanel);
