import React, { PureComponent } from "react";
import { CSSTransition } from "react-transition-group";

import styles from "./Image.module.css";

class Image extends PureComponent {
  state = { isLoaded: false, showDescription: false };

  onLoad = () => {
    this.setState({ isLoaded: true });
  };

  toggleDescription = () => {
    this.setState({ showDescription: true });
  };

  render() {
    const { src, alt, description, noMargin, height, onClick } = this.props;
    const { isLoaded, showDescription } = this.state;
    const className = `
      ${styles.container}
      ${noMargin && styles.noMargin}
    `;

    const imageClassName = `
      ${styles.image}
      ${onClick && styles.linkable}
    `;
    return (
      <div className={className}>
        <img
          onLoad={this.onLoad}
          src={src}
          className={styles.imageHidden}
          alt="hiden-img"
          style={{ height: height }}
        />
        <CSSTransition
          in={isLoaded}
          timeout={350}
          unmountOnExit
          classNames={{
            enter: styles.imageEnter,
            enterActive: styles.imageEnterActive,
            exit: styles.imageExit,
            exitActive: styles.imageExitActive
          }}
          onEntered={this.toggleDescription}
        >
          <img
            src={src}
            className={imageClassName}
            alt={`${alt ? alt : "just-a-pic"}`}
            style={{ height: height }}
            onClick={onClick}
          />
        </CSSTransition>
        {description && (
          <CSSTransition
            in={showDescription}
            timeout={350}
            classNames={{
              enter: styles.descriptionEnter,
              enterActive: styles.descriptionEnterActive,
              exit: styles.descriptionExit,
              exitActive: styles.descriptionExitActive
            }}
            unmountOnExit
          >
            <span className={styles.description}>{description}</span>
          </CSSTransition>
        )}
      </div>
    );
  }
}

export default Image;
