import React from "react";
import { connect } from "react-redux";

import { Box, H1, Divider, Button } from "../../../components/Dashboard";

import { openDocumentsModal } from "../../../entities/documents/actions";

import { UploadModal } from "./components";
import { Results, Search, Filters } from "./containers";

const Library = ({ openModal }) => {
  return (
    <Box>
      <UploadModal />
      <Box
        justify="between"
        display="flex"
        marginBottom={1}
        wrap
        medium={{
          flexDirection: 'column',
          justifyContent: 'center',
          textAlign: 'center',
          marginBottom: '20px',
        }}
      >
        <H1>Documents Library</H1>
        <Button rounded secondary onClick={openModal}>
          Add Documents
        </Button>
      </Box>
      <Divider noMargin />
      <Search />
      <Box
        display="flex"
        width="100%"
        marginTop={4}
        justify="between"
        small="flex-wrap: wrap;"
      >
        <Box
          maxWidth={320}
          display="flex"
          flex="fix300"
          basis="300px"
          minWidth={299}
          marginRight={2}
          small={`
            margin: 0px 0px 24px 0px;
            width: 100%;
            max-width: 100%;
          `}
        >
          <Filters />
        </Box>
        <Box
          display="flex"
          flex="grow"
          small={`
            margin: 0px 0px 24px 0px;
            width: 100%;
            max-width: 100%;
          `}
        >
          <Results />
        </Box>
      </Box>
    </Box>
  );
};

const mapDispatchToProps = (dispatch) => ({
  openModal: () => dispatch(openDocumentsModal()),
});

export default connect(
  null,
  mapDispatchToProps
)(Library);
