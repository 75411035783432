import React, { useState, useRef } from "react";
import styled from "styled-components";
import Downshift from "downshift";

import StyledInput from "../../../../components/Fields/StyledInput";
import arrowDown from "../../../../assets/images/arrow-down.svg";

import { Box, UI, Avatar, Text } from "../../../../components/Dashboard";

const MultiSelectInput = styled(StyledInput)`
  border: none;
  background-color: transparent;
  max-width: 100%;
  width: auto;

  &:focus {
    border: none;
  }
`;

const MultiSelectContainer = styled.div`
  position: relative;
  width: 100%;
  min-width: 0;
  background-image: url(${arrowDown});
  background-repeat: no-repeat;
  background-size: 16px 8px;
  background-position: calc(100% - 16px) 50%;
  background-color: #383838;
  cursor: text;
  z-index: 10;
  box-sizing: border-box;

  &:focus {
    border: 4px solid #ccc;
  }
`;

const ListBox = styled.div`
  position: absolute;
  width: 100%;
  max-height: 320px;
  z-index: 10;
  background: #383838;
  padding-top: 8px;
  padding-bottom: 8px;
  overflow-x: hidden;
  overflow-y: auto;
  backface-visibility: hidden;
  box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.2);
`;

const SelectedItem = styled.span`
  user-select: none;
  display: inline-block;
  white-space: normal;
  padding: 4px 8px;
  margin-left: 8px;
  font-size: 14px;
  font-weight: 500;
  background: ${UI.colors.lightGray};
  color: ${UI.colors.white};
  cursor: pointer;
  border-radius: 2px;
  box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.2);

  &:after {
    content: "\00d7";
    font-size: 18px;
    vertical-align: middle;
    margin-left: 8px;
  }
`;

const stateReducer = (state, changes) => {
  switch (changes.type) {
    case Downshift.stateChangeTypes.keyDownEnter:
    case Downshift.stateChangeTypes.clickItem:
      return {
        ...changes,
        inputValue: ""
      };
    default:
      return changes;
  }
};

const MultiSelect = ({ items }) => {
  const [selected, setSelected] = useState([]);

  const inputRef = useRef(null);
  const addItem = value => {
    if (!selected.includes(value)) {
      setSelected(selected.concat(value));
    }
  };

  const removeItem = value => {
    setSelected(selected.filter(item => item !== value));
  };

  return (
    <Downshift
      onChange={selection => addItem(selection.value)}
      itemToString={item => (item ? item.value : "")}
      stateReducer={stateReducer}
    >
      {({
        getInputProps,
        isOpen,
        getMenuProps,
        getItemProps,
        inputValue,
        getRootProps,
        highlightedIndex
      }) => (
        <MultiSelectContainer
          {...getRootProps()}
          onClick={() => inputRef.current.focus()}
        >
          {selected.map(selection => (
            <SelectedItem key={selection} onClick={() => removeItem(selection)}>
              {selection}
            </SelectedItem>
          ))}
          <MultiSelectInput
            label="To"
            placeholder="Enter username"
            {...getInputProps({
              ref: inputRef,
              onKeyDown(event) {
                event.key === "Backspace" &&
                  !inputValue &&
                  removeItem(selected[selected.length - 1]);
              }
            })}
          />
          {isOpen && (
            <ListBox {...getMenuProps()}>
              {items
                .filter(
                  item =>
                    !inputValue ||
                    item.value.toLowerCase().includes(inputValue.toLowerCase())
                )
                .map((item, index) => (
                  <Box
                    {...getItemProps({ key: index, item })}
                    paddingVertical={1}
                    paddingHorizontal={1}
                    display="flex"
                    background={
                      highlightedIndex === index
                        ? UI.colors.lightGray
                        : "transparent"
                    }
                  >
                    <Avatar src={item.avatar} tiny circle />
                    <Text
                      marginLeft={1}
                      small
                      color={highlightedIndex === index ? "lightblue" : "smoke"}
                    >
                      {item.value}
                    </Text>
                  </Box>
                ))}
            </ListBox>
          )}
        </MultiSelectContainer>
      )}
    </Downshift>
  );
};

export default MultiSelect;
