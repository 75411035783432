import { combineReducers } from "redux";
import { createSelector } from "reselect";

const byId = (state = {}, action) => {
  switch (action.type) {
    case "VESSELS_SUCCESS":
    case "CREATE_VESSEL_SUCCESS":
      return {
        ...state,
        ...action.payload.data.entities.accounts
      };
    case "ACCOUNTS_SUCCESS":
      return {
        ...state,
        ...action.payload.data.entities.accounts
      };
    default:
      return state;
  }
};

const allIds = (state = {}, action) => {
  switch (action.type) {
    case "ACCOUNTS_SUCCESS":
      return action.payload.data.result;
    default:
      return state;
  }
};

const accounts = combineReducers({
  byId,
  allIds
});

export default accounts;

export const getAllAccountsById = state => state.accounts.byId;
export const getAllAccountIds = state => state.accounts.allIds;
export const getAccountById = (state, accountId) =>
  getAllAccountsById(state)[accountId];

export const getAllAccounts = createSelector(
  [getAllAccountIds, getAllAccountsById],
  (accountsIds, accountsById) => {
    return accountsIds.length
      ? accountsIds.map(accountId => accountsById[accountId])
      : [];
  }
);
