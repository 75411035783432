import React, { PureComponent } from "react";

import { container, msg } from "./FullWIdthNotification.module.css";

class FullWidthNotification extends PureComponent {
  render() {
    const { children } = this.props;
    return (
      <div className={container}>
        <span className={msg}>{children}</span>
      </div>
    );
  }
}

export default FullWidthNotification;
