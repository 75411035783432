import React, { useState, useEffect, useRef } from "react";

import { LeftArrow, RightArrow } from "./arrows";
import useCali from "../../Cali";

import { isSameDate, isInMonth, DAY_NAMES } from "../../Cali/utilities";

import styles from "./Datepicker.module.css";

const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = event => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, []);
};

const Datepicker = ({ label, placeholder }) => {
  const ref = useRef();
  const [isOpen, setState] = useState(false);
  const [
    date,
    selectedDate,
    nextMonth,
    previousMonth,
    selectDay,
    getMonthDays
  ] = useCali();
  useOnClickOutside(ref, () => setState(false));

  const handleChange = event => {
    return;
  };

  const handleFocus = event => {
    setState(true);
  };

  const selectDate = date => {
    selectDay(date);
    setState(false);
  };

  return (
    <div className={styles.inputContainer}>
      <label className={styles.label}>{label}</label>
      <input
        className={styles.input}
        placeholder={placeholder}
        onFocus={handleFocus}
        value={selectedDate && selectedDate.format("DD/MM/YYYY")}
        onChange={handleChange}
      />
      {isOpen && (
        <div ref={ref} className={styles.datepickerContainer}>
          <NavigatorBox
            date={date}
            nextMonth={nextMonth}
            previousMonth={previousMonth}
          />
          <div className={styles.datepickerMain}>
            <DayBox />
            <DateBox
              date={date}
              selectDay={selectDate}
              selectedDate={selectedDate}
              getMonthDays={getMonthDays}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const DayBox = () => (
  <div className={styles.daynamesContainer}>
    {DAY_NAMES.map((day, index) => {
      return (
        <span key={index} className={styles.dayname}>
          {day}
        </span>
      );
    })}
  </div>
);

const NavigatorBox = ({ date, previousMonth, nextMonth }) => (
  <div className={styles.datepickerHeader}>
    <div>
      <span>{date.format("MMMM")}</span>
      <span>{date.format("YYYY")}</span>
    </div>
    <LeftArrow onClick={previousMonth} />
    <RightArrow onClick={nextMonth} />
  </div>
);

const DateBox = ({ date, selectDay, selectedDate, getMonthDays }) => {
  return (
    <div className={styles.dateboxContainer}>
      {getMonthDays().map((day, index) => (
        <SingleDateBox
          key={index}
          date={day}
          onClick={isInMonth(day, date) ? selectDay : () => {}}
          isSelected={selectedDate && isSameDate(selectedDate, day)}
          isInMonth={isInMonth(day, date)}
        />
      ))}
    </div>
  );
};

const SingleDateBox = ({ date, onClick, isSelected, isInMonth }) => {
  const selectDay = event => {
    event.preventDefault();
    onClick(date);
  };

  const classNames = `
    ${styles.dateboxDay}
    ${isSelected && styles.dateboxDaySelected}
    ${!isInMonth && styles.dateboxDayNotIncluded}
  `;

  return (
    <div className={classNames} onClick={selectDay}>
      <span>{date.format("DD")}</span>
    </div>
  );
};

export default Datepicker;
