import styled from "styled-components";

import {getPixels, getMargins, media} from "../UI/ui";

const Avatar = styled.div`
  display: block;
  height: ${props =>
    props.height ? getPixels(props.height) : props.big ? "64px" : props.tiny ? "30px" : "48px"};
  width: ${props =>
    props.width ? getPixels(props.width) : props.big ? "64px" : props.tiny ? "30px" : "48px"};
  border-radius: ${props =>
    props.circle ? "50%" : props.rounded ? "4px" : "0px"};
  background-image: url(${props =>
    (props.avatar && props.avatar) || (props.src && props.src)});
  background-color: #1d1d1e;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  ${getMargins};
  
  ${(props) => props.xlarge && media.xlarge`${props.xlarge}`};
  ${(props) => props.large && media.large`${props.large}`};
  ${(props) => props.medium && media.medium`${props.medium}`};
  ${(props) => props.small && media.small`${props.small}`};
  ${(props) => props.xsmall && media.xsmall`${props.xsmall}`};
  &:hover {
    ${props => props.onClick && "cursor: pointer;"};
  }
`;

export default Avatar;
