import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Icon from "react-ionicons";
import { colors } from "../UI/ui";

import Box from "../Boxes/Box";
import { Text } from "../Typography/Typography";
import Image from "../../Image/Image";

const MoreIcon = styled(Icon).attrs({
  color: "#fff",
  icon: props => (props.open ? "md-close" : "md-more")
})`
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  padding: 6px;
  background-color: ${colors.lightblue};
  z-index: 999;
  cursor: pointer;
`;

const Expand = styled(Box).attrs({})`
  opacity: ${props => (props.isActive ? "1" : "0.01")};
  top: 0%;
  right: 0%;
  left: ${props => (props.isActive ? "0%" : "100%")};
  bottom: ${props => (props.isActive ? "0%" : "100%")};
  transition: all 400ms cubic-bezier(0.45, 0.24, 0.66, 0.89);
`;

class ImageCard extends PureComponent {
  state = { toggle: false };

  static getDerivedStateFromProps(props, state) {
    const { isActive } = props;
    if (isActive) {
      return {
        toggle: true
      };
    }
    return {
      toggle: false
    };
  }

  handleClick = () => {
    const { activateCard } = this.props;
    if (activateCard) {
      activateCard();
    } else {
      this.setState({ toggle: !this.state.toggle });
    }
  };

  renderExtraAction = () => {
    const { extraAction } = this.props;
    return React.cloneElement(extraAction, { ...this.props });
  };

  render() {
    const {
      img,
      title,
      subTitle,
      extraAction,
      children,
      onClickCard
    } = this.props;
    const { toggle } = this.state;
    return (
      <Box
        display="flex"
        position="relative"
        flexDirection="col"
        width={280}
        height={300}
        background={colors.darkGray}
      >
        <Box flex="shrink" overflow="hidden">
          <Image src={img} noMargin onClick={onClickCard} />
        </Box>
        <Box
          background={colors.lightGray}
          flex="grow"
          flexDirection="col"
          justify="between"
          alignItems="start"
          display="flex"
          paddingHorizontal={3}
          paddingVertical={3}
        >
          <Box>
            <Text bold big color="white" onClick={onClickCard}>
              {title}
            </Text>
            <Text color="white">{subTitle}</Text>
          </Box>
          <Box>{children}</Box>
        </Box>
        {extraAction && <MoreIcon open={toggle} onClick={this.handleClick} />}
        {extraAction && (
          <Expand
            position="absolute"
            background={colors.lightblue}
            zIndex="998"
            overflow="hidden"
            isActive={toggle}
          >
            {this.renderExtraAction()}
          </Expand>
        )}
      </Box>
    );
  }
}

ImageCard.propTypes = {
  img: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  extraAction: PropTypes.element
};

export default ImageCard;
