import React, { useState } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import Icon from "react-ionicons";
import styled from "styled-components";
import { Box, Text, UI, Button } from "../../../../components/Dashboard";
import styles from "./Item.module.css";
import { Popconfirm } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";

const Link = styled.a`
  display: block;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: ${UI.colors.smoke} !important;
  text-align: left;
  transition: all 250ms ease-in-out;
  text-decoration: none;
  margin-right: 8px;
  &:hover {
    cursor: pointer;
    color: ${UI.colors.lightblue};
    text-decoration: none;
  }
  @media (max-width: 768px) {
    font-size: 12px;
    word-break: break-all;
  }
`;

const Item = ({
  id,
  displayName,
  description,
  date,
  url,
  onSubmit,
  onDelete,
}) => {
  const [isEditing, setEditing] = useState(false);
  const [values, setValues] = useState({ displayName, description });
  const confirm = () => {
    handleDelete(id);
  };

  const toggleEditingMode = () => {
    setEditing(!isEditing);
  };

  const handleEditing = (event) =>
    setValues({ ...values, [event.target.name]: event.target.value });

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(values);
    toggleEditingMode();
  };
  const handleDelete = () => {
    onDelete();
  };

  const handleKeyPress = (event) =>
    event.key === "Escape" && toggleEditingMode();

  return (
    <Box
      display="flex"
      direction="col"
      paddingVertical={2}
      paddingHorizontal={2}
      background={UI.colors.darkGray}
      marginBottom={2}
      align="start"
      borderColor={UI.colors.lightGray}
    >
      {isEditing && (
        <form
          className={styles.form}
          onSubmit={handleSubmit}
          onKeyUp={handleKeyPress}
        >
          <Box marginRight={1}>
            <input
              name="displayName"
              className={styles.input}
              type="text"
              value={values.displayName}
              onChange={handleEditing}
              autoFocus
              autoComplete="off"
              autofill="off"
            />
            <input
              name="description"
              className={styles.input}
              type="text"
              value={values.description}
              onChange={handleEditing}
              autoComplete="off"
              autofill="off"
            />
          </Box>
          <Button
            type="button"
            tiny
            inverted
            marginRight={1}
            onClick={toggleEditingMode}
          >
            Cancel
          </Button>
          <Button type="submit" tiny secondary onClick={handleSubmit}>
            Save
          </Button>
        </form>
      )}
      {!isEditing && (
        <Box display="flex" marginBottom={1}>
          <Link href={url} target="_blank" rel="noopener noreferrer">
            {values.displayName}
          </Link>
          <Icon
            className={styles.pencil}
            fontSize="16px"
            icon="md-create"
            onClick={toggleEditingMode}
          />
        </Box>
      )}
      {!isEditing && <Text marginBottom={2}>{values.description}</Text>}
      <Box display="flex" justify="between">
        <Box display="flex" align="center">
          <Text small color="ghost">
            {dayjs(date).format("MMMM DD, YYYY")}
          </Text>
          <Text color="ghost" marginLeft={1} marginRight={1}>
            |
          </Text>
          <Text small color="ghost">
            VF - {id}
          </Text>
        </Box>
        <Box display="flex" width={48} justify="between">
          <Icon fontSize="18" icon="md-share" color={UI.colors.ghost} />
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Icon
              fontSize="18"
              icon="md-download"
              color={UI.colors.lightblue}
            />
          </a>
          <Popconfirm
            title="Are you sure to delete this document?"
            onConfirm={confirm}
            okText="Yes"
            cancelText="No"
            placement="topLeft"
            icon={<ExclamationCircleFilled style={{ color: "red" }} />}
          >
            <Icon fontSize="18" icon="md-trash" color={UI.colors.lightblue} />
          </Popconfirm>
        </Box>
      </Box>
    </Box>
  );
};

Item.defaultProps = {
  id: 0,
  displayName: "Title",
  description: "Description",
  date: Date.now(),
  url: null,
};

Item.propTypes = {
  id: PropTypes.number.isRequired,
  displayName: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default Item;
