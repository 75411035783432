import { connect } from "react-redux";
import { getAccountList, getVesselList } from "../../../../entities/documents/reducers";

import Filters from "../components/Filters";

const mapStateToProps = state => {
  return {
    accountList: getAccountList(state),
    vesselList: getVesselList(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    selectAccounts: data =>
      dispatch({ type: "SELECT_ACCOUNTS", payload: data }),
    selectVessels: data => dispatch({ type: "SELECT_VESSELS", payload: data })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Filters);