import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import styled from "styled-components";
import { Box, Text, ImageCard, ShareBox } from "../../../components/Dashboard";

import { getThumbnailForScan as getThumbnail } from "../../../entities/scans/reducer";
import { getShareableScanLink } from "../../../global/rootReducer";

const ActionItem = styled(Text)`
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  pointer-events: ${props => (props.disabled ? "none" : "auto")};

  &:hover {
    cursor: pointer;
  }
`;

// This will need refactoring for performance reasons

const ExtraAction = ({ scan, onClick, toggleModal, history }) => {
  const openModal = () => {
    toggleModal(scan.id);
  };

  return (
    <Box
      display="flex"
      position="absolute"
      width="100%"
      height="100%"
      top={0}
      left={0}
      flexDirection="col"
      justify="start"
      alignItems="start"
      paddingHorizontal={3}
      paddingVertical={2}
    >
      <ActionItem marginBottom={1} color="white" semiBold onClick={onClick}>
        Walk-in
      </ActionItem>
      <ActionItem marginBottom={1} onClick={openModal} semiBold color="white">
        Share View
      </ActionItem>
      <ActionItem
        
        marginBottom={1}
        onClick={openModal}
        semiBold
        color="white"
      >
        Edit View
      </ActionItem>
      <ActionItem
        disabled
        marginBottom={1}
        onClick={openModal}
        semiBold
        color="white"
      >
        Live View
      </ActionItem>
      {/* <ActionItem
        disabled
        marginBottom={1}
        onClick={openModal}
        semiBold
        color="white"
      >
        Edit Rights
      </ActionItem> */}
      <ActionItem
        marginBottom={1}
        onClick={() => {history.push("/dashboard/floors&models")}}
        semiBold
        color="white"
      >
        Floors & Models
      </ActionItem>
      <ActionItem
        disabled
        marginBottom={1}
        onClick={openModal}
        semiBold
        color="black"
      >
        Delete View
      </ActionItem>
    </Box>
  );
};

class ScanBox extends PureComponent {
  state = { toggle: false };

  toggleModal = () => {
    const { toggle } = this.state;
    const { fetchShareableScanLink, scan } = this.props;
    if (!toggle) {
      fetchShareableScanLink(scan.id);
    }
    this.setState({ toggle: !toggle });
  };

  stop = event => {
    event.stopPropagation();
  };

  onClick = () => {
    const { history, scan, vesselId } = this.props;
    history.push(`/dashboard/vessels/${vesselId}/${scan.title}`);
  };

  render() {
    const { toggle } = this.state;
    const {
      img,
      title,
      scanLink,
      snapshots,
      notes,
      area,
      visits,
      isActive,
      activateCard,
      isFetching,
      error
    } = this.props;
    return (
      <Box>
        <ShareBox
          toggle={toggle}
          toggler={this.toggleModal}
          title={title}
          link={scanLink}
          loading={isFetching}
          error={error}
        />
        <ImageCard
          img={img}
          title={title}
          onClickCard={this.onClick}
          extraAction={
            <ExtraAction
              {...this.props}
              toggleModal={this.toggleModal}
              onClick={this.onClick}
            />
          }
          isActive={isActive}
          activateCard={activateCard}
        >
          <Box display="Flex" justify="between">
            <Box>
              <Text feather marginBottom={1}>
                Scans: {snapshots || 0}
              </Text>
              <Text feather>
                Area: {area}m<sup>2</sup>
              </Text>
            </Box>
            <Box>
              <Text feather marginBottom={1}>
                Notes: {notes || 0}
              </Text>
              <Text feather>Visitors: {visits}</Text>
            </Box>
          </Box>
        </ImageCard>
      </Box>
    );
  }
}

//---------------------------------------------------------------

const mapStateToProps = (state, props) => {
  const getLink = getShareableScanLink(state, props.scan.id);
  console.log(props, state);
  return {
    img: getThumbnail(state, props.scan.thumbnail),
    title: props.scan.title,
    area: Math.round(props.scan.area),
    snapshots: props.scan.snapshots,
    notes: props.scan.notes,
    visits: props.scan.visits,
    scanLink: getLink
      ? `https://www.vesselfront.com/preview/${getLink.identifier}`
      : "",
    isFetching: state.scans.scanLinks.isFetching,
    error: state.scans.scanLinks.error
  };
};

const mapDispatchToProps = dispatch => ({
  fetchShareableScanLink: scanId => {
    return dispatch({ type: "SCAN_LINK_REQUEST", payload: { scanId } });
  }
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ScanBox)
);
