import React, { PureComponent } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { colors, getMargins } from "../UI/ui";

const StyledButton = styled.button`
  display: block;
  position: relative;
  text-align: center;
  height: ${props => (props.small || props.tiny ? "32px" : "40px")};
  min-width: ${props =>
    props.fullWidth
      ? "100%"
      : props.small
      ? "120px"
      : props.tiny
      ? "60px"
      : "180px"};
  border-radius: ${props => (props.rounded ? "20px" : "0px")};
  border: ${props =>
    props.inverted ? `1px solid ${colors.white}` : "1px solid transparent"};
  background-color: ${props =>
    !props.secondary
      ? props.inverted
        ? "transparent"
        : props.danger
        ? colors.crimson
        : colors.blue
      : colors.lightblue};
  box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.2);
  color: ${colors.white};
  font-family: "Roboto", sans-serif;
  font-size: ${props => (props.small || props.tiny ? "12px" : "16px")};
  font-weight: 500;
  margin: 0;
  ${getMargins};
  outline: 0;
  box-sizing: border-box;

  &:focus {
    outline: 0;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover {
    cursor: pointer;
  }
`;

class Button extends PureComponent {
  handleClick = event => {
    event.preventDefault();
    const { onClick } = this.props;
    onClick();
  };

  render() {
    const { children } = this.props;
    return <StyledButton {...this.props}>{children}</StyledButton>;
  }
}

Button.defaultProps = {
  // onClick: () => {},
  // onMouseOver: () => {},
  secondary: false,
  rounded: false,
  ref: null,
  fullWidth: false,
  small: false
};

Button.propTypes = {
  children: PropTypes.string.isRequired,
  rounded: PropTypes.bool,
  secondary: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  ref: PropTypes.object,
  onMouseOver: PropTypes.func,
  fullWidth: PropTypes.bool,
  small: PropTypes.bool
};

export default Button;
