import React from "react";
import { connect } from "react-redux";

import Crossfade from "../../../../components/Crossfade/Crossfade";
import { Box, Portal, UI } from "../../../../components/Dashboard";

import { closeDocumentsModal } from "../../../../entities/documents/actions";

import { Upload } from "./index";

const UploadModal = ({ isOpen, closeModal }) => {
  const stopProp = event => event.stopPropagation();

  return (
    <Portal
      isOpen={isOpen}
      render={({ isOpen }) => {
        return (
          <Crossfade in={isOpen} timeout={350} unmountOnExit>
            <Box
              onMouseDown={closeModal}
              position="fixed"
              display="flex"
              justify="center"
              alignItems="center"
              width="100%"
              height="100%"
            >
              <Box
                onMouseDown={stopProp}
                position="relative"
                minWidth="50%"
                maxWidth="50%"
                maxHeight="90%"
                alignSelf="auto"
                background={UI.colors.darkGray}
                paddingVertical={4}
                paddingHorizontal={5}
                elevated
                medium={`
                  max-width: 90%;
                  padding: 48px 24px;
                `}
              >
                <Upload />
              </Box>
            </Box>
          </Crossfade>
        );
      }}
    />
  );
};
const mapStateToProps = state => ({
  isOpen: state.documents.ui.isOpen
});

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(closeDocumentsModal())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadModal);
