import React, { PureComponent } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Icon from "react-ionicons";

import { getScanById, getScanThumbnail } from "../../../global/rootReducer";

import { Box, Text, UI } from "../../../components/Dashboard";

const { colors } = UI;

const SideImage = styled(Box)`
  ${(props) =>
    props.src
      ? `background-image: url(${JSON.stringify(props.src)})`
      : "background: #4a4a4a"};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: all 125ms ease-in;
`;

const Back = styled(Icon).attrs({
  fontSize: "18px",
  color: colors.smoke,
  icon: "ios-arrow-back",
})`
  cursor: pointer;
`;

const ActionItem = styled(Text)`
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};

  &:hover {
    cursor: pointer;
  }
`;

class SideCardScanBox extends PureComponent {
  onClick = () => {
    const { scan, history, vesselId } = this.props;
    history.push(`/dashboard/vessels/${vesselId}/${scan.title}`);
  };
  goFloors = () => {
    const { history } = this.props;
    history.push("/dashboard/floors&models");
  };
  onClickExtended = () => {
    const { toggleRightPanel } = this.props;
    toggleRightPanel();
  };

  render() {
    const { scan, thumbnail, backButton } = this.props;
    return (
      <Box>
        <Box
          display="flex"
          width="100%"
          justify="between"
          paddingHorizontal={2}
          paddingVertical={2}
        >
          <Back onClick={backButton} />
          <Text bid color="smoke" semiBold>
            {scan && scan.title}
          </Text>
        </Box>
        <SideImage
          src={thumbnail}
          width="100%"
          height="120px"
          marginBottom={2}
        />
        <Box display="flex" direction="col" paddingHorizontal={2}>
          <Box>
            <ActionItem color="smoke" onClick={this.onClick}>
              Walk-in
            </ActionItem>
            <ActionItem color="smoke" onClick={this.onClickExtended} >
              Edit View
            </ActionItem>
            <ActionItem disabled>Live View</ActionItem>
            {/*<ActionItem disabled>Edit Rights</ActionItem>*/}
            <ActionItem color="smoke" onClick={this.goFloors}>
              Floors & Models
            </ActionItem>
            <ActionItem disabled>Delete View</ActionItem>
          </Box>
        </Box>
      </Box>
    );
  }
}

const mapStateToProps = (state, props) => {
  const scan = getScanById(state, props.scanId);
  const thumbnail = scan && getScanThumbnail(state, scan.thumbnail);
  return {
    scan: scan,
    thumbnail: thumbnail,
  };
};

export default connect(mapStateToProps)(SideCardScanBox);
