import React, { useState, useEffect } from "react";

import {
  Box,
  Text,
  H4,
  Switch,
  Divider
} from "../../../../components/Dashboard";
import { SubscribePlan } from ".";

const Plans = () => {
  const [checked, setChecked] = useState(false);
  const [prices, setPrices] = useState({ subscriber: 249, operator: 489 });

  useEffect(() => {
    if (checked) {
      setPrices({
        subscriber: prices.subscriber * 12,
        operator: prices.operator * 12
      });
    } else {
      setPrices({ subscriber: 249, operator: 489 });
    }
  }, [checked]);

  const handleChange = () => {
    setChecked(!checked);
  };

  return (
    <Box
      direction="col"
      flex="grow"
      display="flex"
      justify="start"
      align="start"
    >
      <Box
        flex="grow"
        marginBottom={2}
        display="flex"
        align="center"
        justify="between"
        width="100%"
        wrap
      >
        <Box display="flex">
          <H4 semiBold>Choose your plan</H4>
        </Box>
        <Box display="flex" justify="center">
          <Text paddingRight={1}>MONTHLY</Text>
          <Switch switched={checked} onChange={handleChange} />
          <Text paddingLeft={1}>YEARLY</Text>
        </Box>
      </Box>
      <Divider color="darkGray" marginBottom={2} />
      <Text marginBottom={2} big>
        For as many companies. For as many vessels. For as many people.
      </Text>
      <Box display="flex" justify="start" width="100%" wrap>
        <SubscribePlan
          title="subscriber"
          price={prices.subscriber}
          monthly={!checked}
        >
          <Text big center semiBold color="smoke">
            Scanning *
          </Text>
          <Text center marginBottom={1}>
            €6/m2
          </Text>
          <Text big center semiBold color="smoke">
            Storage **
          </Text>
          <Text center marginBottom={1}>
            100GB
          </Text>
          <Text big center semiBold color="smoke">
            Downloads ***
          </Text>
          <Text center marginBottom={1}>
            Up to 75K downloads per month & €0,012 per additional download
          </Text>
          <Text big center semiBold color="smoke">
            Uploads ***
          </Text>
          <Text center marginBottom={1}>
            3000 uploads per month & €0,03 per each additional GB
          </Text>
          <Text big center semiBold color="smoke">
            Technical Files ****
          </Text>
          <Text center marginBottom={1}>
            €80 per technical file
          </Text>
        </SubscribePlan>
        <SubscribePlan
          title="Operator"
          price={prices.operator}
          monthly={!checked}
        >
          <Text big center semiBold color="smoke">
            Scanning *
          </Text>
          <Text center marginBottom={1}>
            €5/m2
          </Text>
          <Text big center semiBold color="smoke">
            Storage **
          </Text>
          <Text center marginBottom={1}>
            1TB
          </Text>
          <Text big center semiBold color="smoke">
            Downloads ***
          </Text>
          <Text center marginBottom={1}>
            Up to 150K downloads per month & €0,012 per additional download
          </Text>
          <Text big center semiBold color="smoke">
            Uploads ***
          </Text>
          <Text center marginBottom={1}>
            30K uploads per month & €0,03 per each additional GB
          </Text>
          <Text big center semiBold color="smoke">
            Technical Files ****
          </Text>
          <Text center marginBottom={1}>
            Unlimited
          </Text>
        </SubscribePlan>
      </Box>
      <Box marginTop={3}>
        <Text small>* Does not include travel & accommodation</Text>
        <Text small>** Includes indexing & document processing</Text>
        <Text small>*** Includes file management & document support</Text>
        <Text small>
          **** 3D model downloads for independent processing (color point cloud,
          OBJ files etc)
        </Text>
      </Box>
    </Box>
  );
};

export default Plans;
