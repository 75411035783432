import React from "react";

import { Box, Text, Divider, UI } from "../../../../components/Dashboard";
import Checkbox from "../../../../components/Input/Checkbox";

const renderCheckBoxes = (list, onChange) => {
  return list.map(item => (
    <Checkbox
      key={item.id}
      theme="darkAlt"
      name={item.id}
      onChange={onChange}
      dashboard
    >
      <Checkbox.Input />
      <Checkbox.Label maxWidth='250px'>{item.name || item.title}</Checkbox.Label>
    </Checkbox>
  ));
};

const Filters = ({
  accountList,
  vesselList,
  selectAccounts,
  selectVessels
}) => {
  const handleAccountCheckboxes = event => {
    selectAccounts({
      checked: event.target.checked,
      id: event.target.id
    });
  };
  const handleVesselCheckboxes = event => {
    selectVessels({
      checked: event.target.checked,
      id: event.target.id
    });
  };
  return (
    <Box
      width="100%"
      height="auto"
      background={UI.colors.darkGray}
      borderColor={UI.colors.lightGray}
    >
      <Text
        paddingVertical={2}
        paddingHorizontal={2}
        semiBold
        color="lightblue"
      >
        Filter by
      </Text>
      <Divider noMargin color="lightGray" />
      <Box paddingVertical={2} paddingHorizontal={2}>
        <Text marginBottom={2} semiBold color="smoke">
          Accounts
        </Text>
        {renderCheckBoxes(accountList, handleAccountCheckboxes)}
      </Box>
      <Divider noMargin color="lightGray" />
      <Box paddingVertical={2} paddingHorizontal={2}>
        <Text marginBottom={2} semiBold color="smoke">
          Vessels
        </Text>
        {renderCheckBoxes(vesselList, handleVesselCheckboxes)}
      </Box>
    </Box>
  );
};

export default Filters;
