import { ofType, combineEpics } from "redux-observable";
import { mergeMap, map, pluck, catchError, mapTo } from "rxjs/operators";
import { ajax } from "rxjs/ajax";

import { URL } from "../../global/utilities";

import * as authActions from "./actions";
import { of } from "rxjs";

const loginEpic = (action$) =>
  action$.pipe(
    ofType(authActions.LOGIN.REQUEST),
    mergeMap((action) =>
      ajax({
        url: `${URL}/public/token`,
        method: "POST",
        body: {
          username: action.payload.username,
          password: action.payload.password,
        },
      }).pipe(
        pluck("response", "token"),
        map((token) => {
          localStorage.setItem("authToken", token);
          return authActions.loginSuccess(token);
        }),
        catchError((e) =>
          of(
            authActions.loginError({
              ...e,
            })
          )
        )
      )
    )
  );

const loginWithPinEpic = (action$) =>
  action$.pipe(
    ofType(authActions.LOGIN_WITH_PIN.REQUEST),
    mergeMap((action) =>
      ajax({
        url: `${URL}/public/token?username=${action.payload.username}&code=${
          action.payload.pin
        }`,
      }).pipe(
        pluck("response", "token"),
        map((token) => authActions.loginWithPinSuccess(token)),
        catchError((e) =>
          of(
            authActions.loginError({
              ...e,
            })
          )
        )
      )
    )
  );

const logoutEpic = (action$) =>
  action$.pipe(
    ofType(authActions.LOGOUT.REQUEST),
    mapTo({ type: authActions.LOGOUT.SUCCESS })
  );

export default combineEpics(loginEpic, loginWithPinEpic, logoutEpic);
