import { combineReducers } from "redux";

import * as scanTypes from "./actions";

const byId = (state = {}, action) => {
  switch (action.type) {
    case "VESSELS_SUCCESS":
      return {
        ...state,
        ...action.payload.data.entities.views
      };
    default:
      return state;
  }
};

const allIds = (state = [], action) => {
  switch (action.type) {
    case "VESSELS_SUCCESS":
      // this should probably be refactored
      if (action.payload.data.entities.views) {
        return [
          ...new Set([
            ...state,
            ...Object.keys(action.payload.data.entities.views).map(
              scanId => scanId
            )
          ])
        ];
      }
      return state;
    default:
      return state;
  }
};

const thumbnails = (state = {}, action) => {
  switch (action.type) {
    case "VESSELS_SUCCESS":
      return {
        ...state,
        ...action.payload.data.entities.thumbnails
      };
    default:
      return state;
  }
};

const scanLinks = (state = { isFetching: false, error: null }, action) => {
  switch (action.type) {
    case "SCAN_LINK_SUCCESS":
      return {
        ...state,
        [action.payload.data.vesselViewId]: { ...action.payload.data },
        isFetching: false
      };
    case "SCAN_LINK_REQUEST":
      return {
        ...state,
        isFetching: true,
        error: null
      };
    case "SCAN_LINK_ERROR":
      return {
        ...state,
        isFetching: false,
        error: {
          status: action.payload.error.response.status,
          message: action.payload.error.response.statusText
        }
      };
    default:
      return state;
  }
};

const noteCreationProcess = (
  state = {
    isSubmitting: false,
    submitFailed: false,
    submitSuccess: false,
    isNoteTakingActive: false,
    isTooltipActive: false
  },
  action
) => {
  switch (action.type) {
    case "NOTE_TAKING_ACTIVATED":
      return {
        ...state,
        isNoteTakingActive: true,
        isSubmitting: false,
        submitFailed: false,
        submitSuccess: false
      };
    case "NOTE_TAKING_DEACTIVATED":
      return {
        ...state,
        isNoteTakingActive: false
      };
    case "TOOLTIP_ACTIVATED":
      return {
        ...state,
        isTooltipActive: true
      };
    case "TOOLTIP_DEACTIVATED":
      return {
        ...state,
        isTooltipActive: false
      };
    case scanTypes.CREATE_VIEW_NOTE.REQUEST:
      return {
        ...state,
        isSubmitting: true,
        submitFailed: false,
        submitSuccess: false
      };
    case scanTypes.CREATE_VIEW_NOTE.SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        submitFailed: false,
        submitSuccess: true
      };
    case scanTypes.CREATE_VIEW_NOTE.ERROR:
      return {
        ...state,
        isSubmitting: false,
        submitFailed: true,
        submitSuccess: false
      };
    case "CLEAR_STATE":
      return {
        ...state,
        isSubmitting: false,
        submitFailed: false,
        submitSuccess: false
      };
    default:
      return state;
  }
};

const scans = combineReducers({
  byId,
  allIds,
  thumbnails,
  scanLinks,
  noteCreationProcess
});

export default scans;

export const getAllScanIds = state => state.scans.allIds;
export const getScansById = state => state.scans.byId;
export const getScanById = (state, scanId) => {
  return state.scans.byId[scanId];
};
export const getThumbnailForScan = (state, thumbnailId) =>
  state.scans.thumbnails[thumbnailId]
    ? state.scans.thumbnails[thumbnailId].url
    : "";
export const getScanLink = (state, scanId) => state.scans.scanLinks[scanId];

export const noteCreationStatus = state => state.scans.noteCreationProcess;
