import React from "react";

import { Box, Text, Avatar } from "../../../../components/Dashboard";

const MessageListItem = ({ avatar, sender, message }) => {
  return (
    <Box display="flex" height={80} paddingHorizontal={2} onClick={() => {}}>
      <Box flex="grow" display="flex" justify="center">
        <Avatar marginRight={1} circle src={avatar} />
      </Box>
      <Box
        flex="grow"
        display="flex"
        direction="col"
        alignItems="start"
        justify="center"
        overflow="hidden"
        minWidth="0"
        width="100%"
      >
        <Text small color="white">
          {sender}
        </Text>
        <Text small>{message}</Text>
      </Box>
    </Box>
  );
};

MessageListItem.defaultProps = {
  avatar:
    "https://s3.eu-west-2.amazonaws.com/vesselfront-s3/private/users/3/1540221198_1533073286_pp.jpg",
  sender: "Pantelis Paliagkas",
  message: "Message blah blah blah blah blah blah"
};

export default MessageListItem;
