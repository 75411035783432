import React, { PureComponent } from "react";
import { connect } from "react-redux";

import { Box, Text } from "../../../components/Dashboard";

import { getProperty, getVesselById } from "../../../entities/vessels/reducer";
import { getFlag } from "../../../entities/flags/reducer";
import { getType } from "../../../entities/types/reducer";

class InfoBox extends PureComponent {
  render() {
    const {
      imo,
      mmsi,
      flag,
      vesselType,
      grossTonage,
      deadWeight,
      yearBuilt,
      length,
      breadth
    } = this.props;
    return (
      <Box wrap display="flex" justify="start" marginBottom={4}>
        <Box flex="grow">
          <Text marginBottom={1}>
            IMO:{" "}
            <Text color="smoke" inline>
              {imo}
            </Text>
          </Text>
          <Text marginBottom={1}>
            MMSI:{" "}
            <Text color="smoke" inline>
              {mmsi}
            </Text>
          </Text>
          <Text marginBottom={1}>
            Flag:{" "}
            <Text color="smoke" inline>
              {flag}
            </Text>
          </Text>
          <Text marginBottom={1}>
            AIS Vessel Type:{" "}
            <Text color="smoke" inline>
              {vesselType}
            </Text>
          </Text>
        </Box>
        <Box flex="grow">
          <Text marginBottom={1}>
            Gross Tonage:{" "}
            <Text color="smoke" inline>
              {grossTonage ? `${grossTonage}t` : "N/A"}
            </Text>
          </Text>
          <Text marginBottom={1}>
            Dead Weight:{" "}
            <Text color="smoke" inline>
              {deadWeight ? `${deadWeight}t` : "N/A"}
            </Text>
          </Text>
          <Text marginBottom={1}>
            Lengh Overall x Breadth Extreme:{" "}
            <Text color="smoke" inline>
              {length}m x {breadth}m
            </Text>
          </Text>
          <Text marginBottom={1}>
            Year Built:{" "}
            <Text color="smoke" inline>
              {yearBuilt}
            </Text>
          </Text>
        </Box>
      </Box>
    );
  }
}

InfoBox.defaultProps = {
  imo: "9123922",
  mmsi: "355032000",
  callSign: "3FCUS",
  flag: "Panama[PA]",
  vesselType: "Cargo",
  grossTonage: null,
  deadWeight: null,
  length: "194.2",
  breadth: "28",
  yearBuilt: "1995",
  location: "Piraeus"
};

const mapStateToProps = (state, props) => {
  const vessel = getVesselById(state, props.vesselId);
  const flag = getFlag(state, getProperty(vessel, "flag"));
  const type = getType(state, getProperty(vessel, "type"));
  return {
    imo: getProperty(vessel, "imo"),
    mmsi: getProperty(vessel, "mmsi"),
    flag: flag && flag.description,
    vesselType: type && type.description,
    length: getProperty(vessel, "length"),
    breadth: getProperty(vessel, "width"),
    yearBuilt: getProperty(vessel, "year")
  };
};

export default connect(
  mapStateToProps,
  null
)(InfoBox);
