import React, { PureComponent } from "react";
import { connect } from "react-redux";

import { Box } from "../../../components/Dashboard";

import ScanBox from "./ScanBox";

import { makeGetScans } from "../../../global/rootReducer";

class ScansBox extends PureComponent {
  state = { activeIndex: -1 };

  activateCard = index => {
    const { activeIndex } = this.state;
    if (activeIndex === index) {
      this.setState({ activeIndex: -1 });
    } else {
      this.setState({ activeIndex: index });
    }
  };

  renderCards = () => {
    const { scans, vesselId } = this.props;
    return scans.map((scan, index) => {
      if (!scan) return null;
      const onClick = () => this.activateCard(index);
      const isActive = this.state.activeIndex === index;
      return (
        <Box marginRight={2} marginBottom={2} key={scan.id} elevated>
          <ScanBox
            scan={scan}
            vesselId={vesselId}
            isActive={isActive}
            activateCard={onClick}
          />
        </Box>
      );
    });
  };

  render() {
    return (
      <Box>
        <Box
          display="flex"
          width="100%"
          wrap
          medium={{
            textAlign: 'center',
            justifyContent: 'center'
          }}
        >
          {this.renderCards()}
        </Box>
      </Box>
    );
  }
}

const mapStateToProps = (state, props) => {
  const getScans = makeGetScans(state);
  return {
    scans: getScans(state, props.vesselId)
  };
};

export default connect(mapStateToProps)(ScansBox);
