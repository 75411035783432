import React, { PureComponent } from "react";
import styled from "styled-components";

// Components
import NavBar from "../../components/Dashboard/NavBars/NavBar";
import Box from "../../components/Dashboard/Boxes/Box";
import Card from "../../components/Dashboard/Cards/Card";
import Button from "../../components/Dashboard/Buttons/Button";
import Tooltip from "../../components/Dashboard/Tooltips/Tooltip";

import { H1, Text, Divider } from "../../components/Dashboard/Typography/Typography";

import Search from "../../components/Dashboard/Search/Search";
import Portal from "../../components/Dashboard/Portal/Portal";

const Library = styled.div`
  min-height: 100vh;
  background: ${props => props.background};
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: #fff;
`;

const LibraryContainer = styled.div`
  height: 100%;
  max-width: 95%;
  background: transparent;
  margin: auto;
  padding: 48px 0;
`;


const HorizontalList = styled.div`
  display: flex;
  min-width: 100%;
  flex-direction: row;
  flex-wrap: no-wrap;
  overflow-x: auto;

  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  > div {
    margin-right: 16px;
  }
`;

const menuItems = [
  "Bridge",
  "Main Deck",
  "Other Deck",
  "Accomodation",
  "Tanks",
  "Kitchens",
  "Boiler"
];

const moreItems = [
  "Flash chat",
  "Manage Vessel Access",
  "Vesselfront History",
  "Introduce to owner",
  "Contact via email"
];

class UILibrary extends PureComponent {
  state = { isSearchOpen: false };

  handleSearchDrawer = () => {
    const { isSearchOpen } = this.state;
    this.setState({ isSearchOpen: !isSearchOpen });
  };

  render() {
    let items = [];
    for (let i = 0; i <= 6; i++) {
      if (i === 2) {
        items.push(
          <Card
            moreItems={moreItems}
            key={i}
            title="John Paraskakis"
            subTitle="El Mecanico"
            other="Greece"
            avatar="http://www.publisto.com/wp-content/uploads/2017/06/004-150x150.jpg"
          />
        );
      } else if (i === 3) {
        items.push(
          <Card
            moreItems={moreItems}
            key={i}
            title="Pantelis Paliagkas"
            subTitle="El Artista"
            other="Greece"
            avatar="http://www.publisto.com/wp-content/uploads/2017/01/WEBSITE-PHOTO-2-150x150.jpg"
          />
        );
      } else if (i === 1) {
        items.push(
          <Card
            moreItems={moreItems}
            key={i}
            title="Constantine Abazis"
            subTitle="El Director"
            other="Greece"
            avatar="http://www.publisto.com/wp-content/uploads/2017/01/ca-150x150.png"
          />
        );
      } else {
        items.push(
          <Card
            actionItems={menuItems}
            title="Maria Princess"
            subTitle="Maria Princess"
            other="Greece"
            key={i}
          />
        );
      }
    }

    const { isSearchOpen } = this.state;
    return (
      <Library background="rgba(28,28,29,1)">
        <Portal isOpen={isSearchOpen} render={(isOpen) => {
          return (<Box width={500}>
            <Search theme="dark" timeOut={500} onClose={this.handleSearchDrawer} />
          </Box>)
        }} />
        <LibraryContainer>
          <H1 bold theme="dark">UI Library</H1>
          <H1 theme="dark">Navigation Bars</H1>
          <Divider />
          <NavBar
            links={["Overview", "Your Vessels", "Your People", "Your Account"]}
            iconClick={this.handleSearchDrawer}
          />
          <H1 theme="dark">Cards</H1>
          <Divider />
          <HorizontalList>{items}</HorizontalList>
          <H1 theme="dark">Buttons</H1>
          <Divider />
          <Box display="flex" wrap justifyContent="between" marginBottom={4}>
            <Button rounded>Primary Rounded</Button>
            <Button>Primary</Button>
            <Button secondary>Secondary</Button>
            <Button secondary rounded>
              Secondary Rounded
            </Button>
          </Box>
          <H1 theme="dark">Tooltips</H1>
          <Divider />
          <Box display="flex" wrap justifyContent="around" marginBottom={4}>
            <Box>
              <Tooltip position="bottom" text="Tooltip">
                I'm a tooltip bitch
              </Tooltip>
            </Box>
            <Box>
              <Tooltip position="top" text="Tooltip">
                I'm a tooltip bitch
              </Tooltip>
            </Box>
            <Box>
              <Tooltip position="top" text="Tooltip">
                I'm a tooltip bitch
              </Tooltip>
            </Box>
            <Box>
              <Tooltip position="left" text="Tooltip">
                I'm a tooltip bitch
              </Tooltip>
            </Box>
          </Box>
          <H1 theme="dark">Typography</H1>
          <Divider />
          <Box display="flex" alignItems="center" wrap>
            <Box flex="grow">
              <H1 theme="dark" color="smoke">This is a light heading</H1>
              <H1 theme="dark" bold color="smoke">This is a bold heading</H1>
            </Box>
            <Box display="flex" flex="grow" justifyContent="center" alignItems="start" direction="column">
              <Text theme="dark" color="smoke">This is a text</Text>
              <Text theme="dark" bold color="smoke">This is a bold text</Text>
            </Box>
          </Box>
        </LibraryContainer>
      </Library>
    );
  }
}

export default UILibrary;
