import React, { useState } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import styled from "styled-components";
import Icon from "react-ionicons";

import {
  Box,
  H2,
  Divider,
  Dropdown,
  UI,
  Text,
  Button,
  Spinner,
} from "../../../../components/Dashboard";

import {
  getAccountList,
  getVesselList,
  isUploading,
  uploadHasError,
  getUploadErrorMsg,
} from "../../../../entities/documents/reducers";

import Uploader from "./Uploader";

const SuccessIcon = styled(Icon).attrs({
  color: UI.colors.white,
  icon: "md-checkmark",
})`
  height: 35px;
  width: 24px;
`;

const Upload = ({
  accountList,
  vesselList,
  upload,
  isUploading,
  hasError,
  errorMsg,
  isComplete,
}) => {
  const handleFileUpload = (files, setFieldValue) => {
    setFieldValue("files", files);
    console.log(setFieldValue, "files");
  };
  const [selected, setSelected] = useState();
  console.log(accountList,"ACCCCCC")
  return (
    <>
      <H2>Upload Documents</H2>
      <Divider />
      <Formik
        onSubmit={(values) => {
          upload({
            accountId: values.account.id,
            vesselId: values.vessel.id,
            domain: 1,
            description: "beta",
            isActive: 1,
            files: values.files,
          });
        }}
        validate={(values) => {
          let errors = {};
          if (!values.account) {
            errors.account = "Required";
          }
          if (!values.vessel) {
            errors.vessel = "Required";
          }
          if (!values.files) {
            errors.files = "Required";
          }
          return errors;
        }}
        render={({ handleSubmit, setFieldValue, isValid, values }) => (
          <form onSubmit={handleSubmit}>
            <Dropdown
              label="Select account"
              name="account"
              options={accountList}
              onChange={(field, value) => {
                setSelected(value.id);
                setFieldValue(field, value);
              }}
              value={ values ? values.account : ''}
              placeholder="Select account..."
            />
            {selected && (
              <Dropdown
                label="Select vessel"
                name="vessel"
                options={vesselList.filter((item, index) => {
                  return item.accountId === selected;
                })}
                onChange={setFieldValue}
                value={ values ? values.vessel : ''}
                placeholder="Select vessel..."
              />
            )}
            <Uploader
              onFileUpload={(files) => handleFileUpload(files, setFieldValue)}
            />
            <Box display="flex" justify="end" alignItems="center">
              {hasError && (
                <Text color="crimson" marginRight={2}>
                  {errorMsg}
                </Text>
              )}
              <Button type="submit" inverted rounded disabled={!isValid}>
                {isUploading ? (
                  <Spinner small alt />
                ) : isComplete ? (
                  <SuccessIcon />
                ) : (
                  "Upload"
                )}
              </Button>
            </Box>
          </form>
        )}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const accounts = getAccountList(state);
  const accountList = accounts.map((account) => ({
    id: account.id,
    value: account.name,
  }));

  const vessels = getVesselList(state);
  const vesselList = vessels.map((vessel) => ({
    id: vessel.id,
    accountId: vessel.accountId,
    value: vessel.name,
  }));

  return {
    accountList: accountList,
    vesselList: vesselList,
    isUploading: isUploading(state),
    hasError: uploadHasError(state),
    errorMsg: getUploadErrorMsg(state),
    isComplete: state.documents.ui.isComplete,
  };
};

const mapDispatchToProps = (dispatch) => ({
  upload: (data) =>
    dispatch({ type: "DOCUMENTS_UPLOAD_REQUEST", payload: data }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Upload);
