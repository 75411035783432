import styled from "styled-components";
import {colors, getMargins, getPaddings, media} from "../UI/ui";

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${(props) => (props.color ? colors[props.color] : colors.smoke)};
  margin-bottom: ${(props) => (props.noMargin ? 0 : "48px")};
  ${getMargins};
`;

export const H1 = styled.h1`
  display: block;
  font-size: 48px;
  font-family: "Roboto", sans-serif;
  font-weight: ${(props) =>
    props.bold ? "700" : props.semiBold ? "500" : "100"};
  color: ${(props) => (props.color ? colors[props.color] : colors.smoke)};
  ${getMargins};
  ${getPaddings};
`;

export const H2 = styled(H1)`
  font-size: 36px;
`;

export const H3 = styled(H1)`
  font-size: 32px;
`;

export const H4 = styled(H1)`
  font-size: 24px;
`;

export const H5 = styled(H1)`
  font-size: 21px;
`;

export const Text = styled.span`
  display: ${(props) => (props.inline ? "inline" : "block")};
  font-size: ${(props) =>
    props.big ? "18px" : props.small ? "14px" : props.tiny ? "12px" : "16px"};
  font-family: "Roboto", sans-serif;
  font-weight: ${(props) =>
    props.bold
      ? "700"
      : props.semiBold
        ? "500"
        : props.feather
          ? "300"
          : props.thin
            ? "100"
            : "400"};
  color: ${(props) => (props.color ? colors[props.color] : colors.ghost)};
  text-align: ${(props) => (props.center ? "center" : "left")};
  ${getMargins};
  ${getPaddings};
  
  ${(props) => props.xlarge && media.xlarge`${props.xlarge}`};
  ${(props) => props.large && media.large`${props.large}`};
  ${(props) => props.medium && media.medium`${props.medium}`};
  ${(props) => props.small && media.small`${props.small}`};
  ${(props) => props.xsmall && media.xsmall`${props.xsmall}`};
  &:hover {
    ${(props) => props.onClick && "cursor: pointer;"};
  }
`;

export const DownloadLink = styled.a`
  display: ${(props) =>
    props.inline ? "inline" : props.inlineBlock ? "inline-block" : "block"};
  font-size: ${(props) =>
    props.big ? "18px" : props.small ? "14px" : props.tiny ? "12px" : "16px"};
  font-family: "Roboto", sans-serif;
  font-weight: ${(props) =>
    props.bold
      ? "700"
      : props.semiBold
        ? "500"
        : props.feather
          ? "300"
          : props.thin
            ? "100"
            : "400"};
  text-align: ${(props) => (props.center ? "center" : "left")};
  ${getMargins};
  ${getPaddings};

  &:hover {
    ${(props) => props.onClick && "cursor: pointer;"};
  }
`;
