import React, { useState, useEffect } from "react";

import {
  Box,
  H4,
  Button,
  Divider,
  Text,
  Spinner,
} from "../../../../components/Dashboard";
import { store } from "react-notifications-component";
import Input from "../../../../components/Input/Input";

const YourAccount = ({
  updateUserUsername,
  updateUserPassword,
  userId,
  username,
  isUsernameUpdating,
  isPasswordUpdating,
}) => {
  const [inputValues, setInputValues] = useState({});
  useEffect(() => setInputValues({ username: username }), [username]);

  const handleChange = (event) => {
    setInputValues({
      ...inputValues,
      [event.target.name]: event.target.value,
    });
  };

  const updatePassword = () => {
    let reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/;
    if (!inputValues.password || !inputValues.rPassword) {
      store.addNotification({
        title: "Error",
        message: "Please fill in the required fields",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeInRight"],
        animationOut: ["animate__animated", "animate__fadeOutRight"],
        dismiss: {
          duration: 5000,
          delay: 1000,
          showIcon: true,
        },
        slidingEnter: {
          duration: 2000,
        },
      });
    } else if (
      inputValues.password &&
      inputValues.rPassword &&
      inputValues.password !== inputValues.rPassword
    ) {
      store.addNotification({
        title: "Error",
        message: "Passwords do not match",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeInRight"],
        animationOut: ["animate__animated", "animate__fadeOutRight"],
        dismiss: {
          duration: 5000,
          delay: 1000,
          showIcon: true,
        },
        slidingEnter: {
          duration: 2000,
        },
      });
    } else if (
      inputValues.password &&
      inputValues.rPassword &&
      inputValues.password === inputValues.rPassword &&
      inputValues.password.match(reg)
    ) {
      updateUserPassword(userId, {
        password: inputValues.password,
        rPassword: inputValues.rPassword,
      });
    } else {
      store.addNotification({
        title: "Error",
        message: "The password should include Uppercase, Number and Symbol",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeInRight"],
        animationOut: ["animate__animated", "animate__fadeOutRight"],
        dismiss: {
          duration: 5000,
          delay: 1000,
          showIcon: true,
        },
        slidingEnter: {
          duration: 2000,
        },
      });
    }
  };

  const updateUsername = () => {
    updateUserUsername(userId, { username: inputValues.username });
  };

  return (
    <Box
      justify="between"
      display="flex"
      direction="col"
      wrap
      flex="grow"
      alignContent="start"
    >
      <Box maxWidth={320}>
        <H4 marginBottom={2} semiBold>
          Change password
        </H4>
        <Input
          type="password"
          name="password"
          label="New password"
          onChange={handleChange}
        />
        <Input
          type="password"
          name="rPassword"
          label="Confirm new password"
          onChange={handleChange}
          required
        />
        <Button secondary onClick={updatePassword}>
          {isPasswordUpdating ? <Spinner small alt /> : "Update password"}
        </Button>
        <Divider marginVertical={4} />
      </Box>

      <Box maxWidth={320}>
        <H4 marginBottom={2} semiBold>
          Change username
        </H4>
        <Input
          name="username"
          label="Enter a new username"
          onChange={handleChange}
          value={inputValues.username}
        />
        <Button secondary onClick={updateUsername}>
          {isUsernameUpdating ? <Spinner small alt /> : "Update username"}
        </Button>
        <Divider marginVertical={4} />
      </Box>

      <Box maxWidth={320}>
        <H4 marginBottom={2} semiBold>
          Delete account
        </H4>
        <Text marginBottom={2} semiBold color="offWhite">
          Attention. This action will permanently delete your account. In case
          of a mistake, contact our administrators.
        </Text>
        <Button danger onClick={() => {}}>
          Delete account
        </Button>
      </Box>
    </Box>
  );
};

YourAccount.displayName = "YourAccount";

export default YourAccount;
