import React, { PureComponent } from "react";

class TrackPosition extends PureComponent {
  state = { currentPosition: { x: 0, y: 0 }, lastPosition: { x: 0, y: 0 } };

  handleMouseMove = event => {
    this.setState({
      currentPosition: {
        x: event.nativeEvent.offsetX,
        y: event.nativeEvent.offsetY
      }
    });
  };

  handleMouseDown = event => {
    this.setState({
      lastPosition: {
        x: event.nativeEvent.offsetX,
        y: event.nativeEvent.offsetY
      }
    });
  };

  render() {
    const { render } = this.props;
    return (
      <div
        onMouseMove={this.handleMouseMove}
        onMouseDown={this.handleMouseDown}
      >
        {render(this.state)}
      </div>
    );
  }
}

export default TrackPosition;
