import React from "react";
import { Route } from "react-router";

import { Box, H1, Divider } from "../../../components/Dashboard";

import { AccountNavigation } from "./components";

import Plans from "./components/Plans";
import YourAccount from "./containers/YourAccount";
import Profile from "./containers/Profile";

const accountNavigationItems = [
  { title: "Profile", path: "/dashboard/account/profile" },
  { title: "Account", path: "/dashboard/account/youraccount" },
  /*{ title: "Billing", path: "#", disabled: true },
  { title: "Plans", path: "/dashboard/account/plans", disabled: false },*/
];

const Account = () => {
  return (
    <Box>
      <Box marginBottom={5}>
        <Box display="flex">
          <H1 marginRight={2}>Your Account</H1>
        </Box>
        <Divider marginBottom={4} />
        <Box wrap display="flex" marginBottom={4}>
          <AccountNavigation items={accountNavigationItems} />
          <Route path="/dashboard/account/profile" component={Profile} />
          <Route
            path="/dashboard/account/youraccount"
            component={YourAccount}
          />
          <Route path="/dashboard/account/plans" component={Plans} />
        </Box>
      </Box>
    </Box>
  );
};

export default Account;
