import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import jwt_decode from "jwt-decode";

import { getToken } from "../../entities/auth/reducer";

const PrivateRoute = ({ component: Component, signOut, ...rest }) => {
  var decoded = 1;
  const date = Date.now() / 1000;
  if (rest.token) {
    decoded = jwt_decode(rest.token);
  }
  if (decoded.exp < date) {
    signOut();
    localStorage.removeItem("authToken");
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        const { token } = rest;
        return decoded.exp > date ? (
          <Component {...props} />
        ) : (
          <Redirect to="/securelogin" />
        );
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  token: getToken(state),
});

const mapDispatchToProps = (dispatch) => ({
  signOut: () => dispatch({ type: "LOGOUT_REQUEST" }),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivateRoute);
