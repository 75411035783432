import React from "react";
import { connect } from "react-redux";

import styled from "styled-components";

import {
  getUserById,
  getProperty,
  getCurrentUser,
} from "../../../entities/user/reducer";

import Box from "../Boxes/Box";
import { Text } from "../Typography/Typography";
import Avatar from "../Avatars/Avatar";
import { colors } from "../UI/ui";

const Username = styled.span`
  display: block;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 12px;
  color: ${colors.white};
  margin-bottom: 8px;
`;

const Message = styled(Text)`
  word-break: break-all;
`;

const ChatMessage = ({ message, user, avatar, isSender }) => {
  return (
    <Box
      display="flex"
      marginBottom={3}
      maxWidth="90%"
      justifyContent={isSender ? "end" : "start"}
    >
      {!isSender && (
        <Box display="flex" alignItems="start">
          <Avatar circle avatar={avatar} />
        </Box>
      )}
      <Box
        display="flex"
        direction="col"
        alignItems={isSender ? "flex-end" : "flex-start"}
        marginLeft={2}
      >
        <Username>{user.fullname}</Username>
        <Box
          paddingVertical={3}
          paddingHorizontal={2}
          background={isSender ? colors.lightblue : colors.lightGray}
        >
          <Message color="white">{message}</Message>
        </Box>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state, props) => {
  const user = getUserById(state, props.userId);
  const isSender = user === getCurrentUser(state) ? true : false;
  return {
    user: user,
    isSender: isSender,
    avatar: getProperty(user, "avatar"),
  };
};

export default connect(mapStateToProps)(ChatMessage);
