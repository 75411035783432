import { ofType, combineEpics } from "redux-observable";
import {
  map,
  pluck,
  switchMap,
  delay,
  catchError,
  flatMap,
  mapTo,
  takeUntil,
  debounceTime,
  mergeAll,
} from "rxjs/operators";
import { of, merge } from "rxjs";
import { URL_NEW } from "../../global/utilities";

import * as documentActions from "./actions";
import { getToken } from "../auth/reducer";
import {
  getSearchKeyword,
  getFilters,
  getDocumentById,
  getAllDocuments,
} from "./reducers";

import {
  documentNormalizr,
  singleDocumentNormalizr,
  testSerializr,
} from "./normalizers";
import { store } from "react-notifications-component";

const searchDocumentsAPI = ({
  token,
  page,
  keyword,
  accountIds,
  accountId,
  modelIds,
  ajax,
}) => {
  console.log(
    accountIds,
    "accountIdsaccountIdsaccountIdsaccountIdsaccountIdsaccountIds"
  );
  return ajax({
    url: `${URL_NEW}/secured/documents/search?limit=25&page=${page}&keyword=${keyword}&raccountIds=${
      accountId ? accountId || "" : accountIds
    }&modelId=${modelIds}`,
    headers: {
      Authorization: token,
    },
  });
};

const postDocumentsAPI = (token, data, ajax) => {
  let formData = new FormData();
  formData.set("accountId", data.accountId);
  formData.set("vesselId", data.vesselId);
  formData.set("domain", data.domain);
  formData.set("description", data.description);
  formData.set("isActive", data.isActive);

  for (let i = 0; i < data.files.length; i++) {
    formData.set(`files[${i}]`, data.files[i]);
  }

  return ajax({
    url: `${URL_NEW}/secured/documents/`,
    method: "POST",
    headers: { Authorization: token },
    body: formData,
  });
};

// epics

const fetchDocumentsForDropdownEpic = (action$, state$, { ajax }) =>
  action$.pipe(
    ofType("DOCUMENTS_FOR_DROPDOWN_REQUEST"),
    switchMap(() =>
      ajax({
        url: `${URL_NEW}/secured/documents?limit=500`,
        headers: {
          Authorization: getToken(state$.value),
        },
      }).pipe(
        map((res) => ({
          type: "DOCUMENTS_FOR_DROPDOWN_SUCCESS",
          payload: testSerializr(res.response),
        }))
      )
    )
  );

const uploadDocumentsEpic = (action$, state$, { ajax }) =>
  action$.pipe(
    ofType("DOCUMENTS_UPLOAD_REQUEST"),
    switchMap((action) => {
      const { payload } = action;
      const token = getToken(state$.value);
      return postDocumentsAPI(token, payload, ajax).pipe(
        takeUntil(action$.pipe(ofType("DOCUMENTS_CLOSE_MODAL"))),
        flatMap((response) => {
          store.addNotification({
            title: "Success",
            message: "You successfully add new document",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeInRight"],
            animationOut: ["animate__animated", "animate__fadeOutRight"],
            dismiss: {
              duration: 5000,
              delay: 1000,
              showIcon: true,
            },
            slidingEnter: {
              duration: 2000,
            },
          });
          return [
            documentActions.documentsUploadSuccess(documentNormalizr(response)),
          ];
        }),
        catchError((e) =>
          of({
            type: "DOCUMENTS_UPLOAD_ERROR",
            payload: {
              wtf: e,
              error:
                "Something went wrong. Please try again. If the problem persists please contact us.",
            },
          })
        )
      );
    })
  );

const uploadDocumentsSuccess = (action$) =>
  action$.pipe(
    ofType("DOCUMENTS_UPLOAD_SUCCESS"),
    delay(2500),
    mapTo([
      { type: "SEARCH_DOCUMENTS_REQUEST" },
      { type: "DOCUMENTS_CLOSE_MODAL" },
    ]),
    mergeAll()
  );

const updateDocumentEpic = (action$, state$, { ajax }) =>
  action$.pipe(
    ofType(documentActions.DOCUMENT_UPDATE.REQUEST),
    switchMap(({ payload }) =>
      ajax({
        url: `${URL_NEW}/secured/documents/${payload.documentId}`,
        method: "POST",
        headers: {
          Authorization: getToken(state$.value),
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: {
          displayName: payload.values.displayName,
          description: payload.values.description,
        },
      }).pipe(
        map((res) =>
          documentActions.documentUpdateSuccess(
            singleDocumentNormalizr(res.response)
          )
        ),
        catchError((e) => of(documentActions.documentUpdateError(e)))
      )
    )
  );

const deleteDocumentEpic = (action$, state$, { ajax }) =>
  action$.pipe(
    ofType(documentActions.DOCUMENT_DELETE.REQUEST),
    switchMap(({ payload }) =>
      ajax({
        url: `${URL_NEW}/secured/documents/${payload.documentId}`,
        method: "delete",
        headers: {
          Authorization: getToken(state$.value),
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }).pipe(
        map((res) => {
          store.addNotification({
            title: "Success",
            message: "The file was deleted",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeInRight"],
            animationOut: ["animate__animated", "animate__fadeOutRight"],
            dismiss: {
              duration: 5000,
              delay: 1000,
              showIcon: true,
            },
            slidingEnter: {
              duration: 2000,
            },
          });
          return documentActions.documentDeleteSuccess(
            singleDocumentNormalizr({ id: payload.documentId })
          );
        }),
        catchError((e) => of(documentActions.documentDeleteError(e)))
      )
    )
  );

const searchEpic = (action$, state$, { ajax }) => {
  const fetchMore = action$.pipe(
    ofType("SEARCH_DOCUMENTS_FETCH_MORE"),
    map(() => ({
      type: "SEARCH_DOCUMENTS_REQUEST",
      meta: { page: state$.value.documents.ui.paging + 1 },
    }))
  );

  const setSearchKeyword = action$.pipe(
    ofType("SET_SEARCH_KEYWORD"),
    debounceTime(500),
    map((action) => ({
      type: "SET_SEARCH_KEYWORD_DEBOUNCED",
      payload: { ...action },
    }))
  );

  const searchKeyword = action$.pipe(
    ofType("SET_SEARCH_KEYWORD_DEBOUNCED", "SELECT_ACCOUNTS", "SELECT_VESSELS"),
    map(() => ({
      type: "SEARCH_DOCUMENTS_REQUEST",
      meta: { page: 0 },
    }))
  );

  const searchRequest = action$.pipe(
    ofType("SEARCH_DOCUMENTS_REQUEST"),
    map((action) => ({
      ...getFilters(state$.value),
      ...getSearchKeyword(state$.value),
      token: getToken(state$.value),
      page: action.meta ? action.meta.page : 0,
      accountId: action.payload ? action.payload.accountIds : "",
    })),
    switchMap((payload) =>
      searchDocumentsAPI({ ...payload, ajax: ajax }).pipe(
        pluck("response"),
        map((response) => ({
          type: "SEARCH_DOCUMENTS_SUCCESS",
          payload: documentNormalizr(response),
          meta: { page: payload.page },
        })),
        catchError((e) =>
          of({
            type: "SEARCH_DOCUMENTS_ERROR",
            payload: { ...e },
          })
        )
      )
    )
  );

  return merge(fetchMore, setSearchKeyword, searchKeyword, searchRequest);
};

export default combineEpics(
  uploadDocumentsEpic,
  uploadDocumentsSuccess,
  fetchDocumentsForDropdownEpic,
  updateDocumentEpic,
  deleteDocumentEpic,
  searchEpic
);
