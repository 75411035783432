import React from "react";
import { Box, Datepicker, Input } from "../../../../../components/Dashboard";

const ThirdStep = () => {
  return (
    <Box display="flex" zIndex={100} large={`flex-wrap:wrap;`}>
      <Box display="flex" flexDirection="col" flex="grow" paddingRight={1}>
        <Datepicker
          label="Date you want the scans to take place"
          placeholder="Select date"
        />
        <Input
          label="Vessel location at date"
          name="vesselLocation"
          placeholder="Enter vessel's location"
          type="text"
        />
        <Input
          label="Contact Person for Receiving Offer"
          name="receivingOfferPerson"
          placeholder="Enter the email of the person who will receive the offer"
          type="email"
        />
      </Box>
      <Box display="flex" flexDirection="col" flex="grow">
        <Input
          label="Contact Person on Location"
          name="contactPersonOnLocation"
          placeholder="Enter the email of the person on location"
          type="email"
        />
        <Input
          label="Contact Engineer for Vessel Documentation"
          name="contactEngineer"
          placeholder="Enter the email of the engineer"
          type="email"
        />
      </Box>
    </Box>
  );
};

export default ThirdStep;
