import { connect } from "react-redux";

import { getCurrentUser, getUsername } from "../../../../entities/user/reducer";

import { YourAccount } from "../components";

const mapStateToProps = state => {
  const user = getCurrentUser(state);
  return {
    userId: user ? user.id : null,
    username: getUsername(user),
    isUsernameUpdating: state.users.isUpdating.username,
    isPasswordUpdating: state.users.isUpdating.password
  };
};

const mapDispatchToProps = dispatch => ({
  updateUserPassword: (userId, values) =>
    dispatch({
      type: "USER_UPDATE_REQUEST",
      payload: { userId, values },
      meta: "UPDATE_PASSWORD"
    }),
  updateUserUsername: (userId, values) =>
    dispatch({
      type: "USER_UPDATE_REQUEST",
      payload: { userId, values },
      meta: "UPDATE_USERNAME"
    })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(YourAccount);
