import React, { PureComponent } from 'react';
import MessageBox from './MessageBox';
import { Box } from "../../../../components/Dashboard"

class Messages extends PureComponent {
    render() { 
        return(
            <Box overflow="auto" maxHeight="400px">
                <MessageBox />
            </Box>
        );
    }
}
 
export default Messages;