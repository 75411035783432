import { createRequestTypes, createActions } from "../../global/utilities";

export const VESSELS = createRequestTypes("VESSELS");
export const VESSEL = createRequestTypes("VESSEL");
export const CREATE_VESSEL = createRequestTypes("CREATE_VESSEL");

export const vesselsRequest = () => createActions(VESSELS["REQUEST"]);
export const vesselsSuccess = data =>
  createActions(VESSELS["SUCCESS"], { data });
export const vesselsError = error => createActions(VESSELS["ERROR"], { error });

export const vesselRequest = vesselId =>
  createActions(VESSEL["REQUEST"], { vesselId });
export const vesselSuccess = data => createActions(VESSEL["SUCCESS"], { data });
export const vesselError = error => createActions(VESSEL["ERROR"], { error });

export const createVesselRequest = data =>
  createActions(CREATE_VESSEL["REQUEST"], { data });
export const createVesselSuccess = data =>
  createActions(CREATE_VESSEL["SUCCESS"], { data });
export const createVesselError = error =>
  createActions(CREATE_VESSEL["ERROR"], { error });
