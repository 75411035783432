import axios from "axios";
import { put, take, fork, call } from "redux-saga/effects";
import { normalize, schema } from "normalizr";

import * as actions from "./actions";
import { URL_NEW } from "../../global/utilities";

const URL = URL_NEW;

const vessel = new schema.Entity("vessels");
const scan = new schema.Entity("scan", {
  vessel: vessel
});

const fetchPreviewScan = identifier => {
  return axios.get(`${URL}/public/preview/${identifier}`);
};

function* getPreviewScan({ payload }) {
  try {
    const { identifier } = payload;
    const res = yield call(fetchPreviewScan, identifier);
    const normalized = normalize(res.data, scan);
    yield put(actions.previewScanSuccess(normalized));
  } catch (e) {
    yield put(actions.previewScanError(e));
  }
}

export function* watchPreview() {
  while (true) {
    const action = yield take(actions.PREVIEW_SCAN.REQUEST);
    yield fork(getPreviewScan, action);
  }
}
