import { createRequestTypes, createActions } from "../../global/utilities";

export const SCAN_LINK = createRequestTypes("SCAN_LINK");
export const CREATE_VIEW_NOTE = createRequestTypes("CREATE_VIEW_NOTE");

export const scanLinkRequest = () => createActions(SCAN_LINK["REQUEST"]);
export const scanLinkSuccess = data =>
  createActions(SCAN_LINK["SUCCESS"], { data });
export const scanLinkError = error =>
  createActions(SCAN_LINK["ERROR"], { error });

export const createViewNoteRequest = () =>
  createActions(CREATE_VIEW_NOTE["REQUEST"]);
export const createViewNoteSuccess = data =>
  createActions(CREATE_VIEW_NOTE["SUCCESS"], { data });
export const createViewNoteError = error =>
  createActions(CREATE_VIEW_NOTE["ERROR"], { error });
