import React, { useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { CSSTransition } from "react-transition-group";
import styled from "styled-components";

import { colors, getPaddings } from "../UI/ui";
import Icon from "react-ionicons";
import Box from "../Boxes/Box";
import Avatar from "../Avatars/Avatar";
import { Text } from "../Typography/Typography";

const CardSide = styled(Box).attrs({
  width: 280,
  maxHeight: 300,
})`
  @media (max-width: 768px) {
    position: absolute;
    height: 100%;
  }
  &.expand-enter {
    opacity: 0.01;
    width: 0px;
    transition: all ${(props) => props.timeOut + "ms"}
      cubic-bezier(0.57, 0.21, 0.69, 1.25);
  }

  &.expand-enter-active {
    opacity: 1;
    width: 280px;
    transition: all ${(props) => props.timeOut + "ms"}
      cubic-bezier(0.57, 0.21, 0.69, 1.25);
  }

  &.expand-exit {
    opacity: 1;
    width: 280px;
    transition: all ${(props) => props.timeOut + "ms"}
      cubic-bezier(0.57, 0.21, 0.69, 1.25);
  }

  &.expand-exit-active {
    opacity: 0.01;
    width: 0px;
    transition: all ${(props) => props.timeOut + "ms"}
      cubic-bezier(0.57, 0.21, 0.69, 1.25);
  }
`;

const BlueCard = styled(Box)`
  overflow-y: auto;
`;

const LinkTo = styled(Link)`
  display: block;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #fff;
  margin-bottom: 8px;
  ${getPaddings};

  &:hover {
    color: #fff;
    text-decoration: none;
  }
`;

const DisabledLink = styled(LinkTo)`
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
`;

const ActionsContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

const MenuIcon = styled(Icon).attrs({
  color: "#fff",
  icon: props => (props.open ? "md-close" : "md-arrow-forward"),
  onClick: (props) => props.onClick,
})`
  @media (max-width: 768px) {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100;
  }
  display: block;
  width: 30px;
  height: 30px;
  padding: 6px;
  background-color: ${colors.blue};
  cursor: pointer;
`;

const MoreIcon = styled(Icon).attrs({
  color: "#fff",
  icon: props => (props.open ? "md-close" : "md-more")
})`
  @media (max-width: 768px) {
    position: absolute;
    top: 30px;
    right: 0;
    z-index: 100; 
  }
  display: block;
  width: 30px;
  height: 30px;
  padding: 6px;
  background-color: ${colors.lightblue};
  cursor: pointer;
`;

const Card = ({
  avatar,
  logo,
  title,
  subTitle,
  other,
  actionItems,
  moreItems,
  bottomSheet,
  onClickCard,
}) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const handleMore = (event) => {
    event.preventDefault();
    if (showMenu && !showMore) {
      setShowMore(true);
      setMenuIsOpen(true);
    } else {
      setMenuIsOpen(!menuIsOpen);
      setShowMore(!showMore);
    }
    setShowMenu(false);
  };

  const handleMenu = (event) => {
    event.preventDefault();
    if (showMore && !showMenu) {
      setShowMenu(true);
      setMenuIsOpen(true);
    } else {
      setMenuIsOpen(!menuIsOpen);
      setShowMenu(!showMenu);
    }
    setShowMore(false);
  };

  const timeOut = 325;
  return (
    <Box
      display="flex"
      background={colors.darkGray}
    >
      <Box width={280} height={300} zIndex={'unset'}>
        <ActionsContainer>
          {actionItems &&
            actionItems.length > 0 && <MenuIcon onClick={handleMenu} open={showMenu} />}
          {moreItems &&
            moreItems.length > 0 && <MoreIcon onClick={handleMore} open={showMore}/>}
        </ActionsContainer>
        <Box
          width="100%"
          position="static"
          paddingVertical={2}
          paddingHorizontal={3}
          background={colors.lightGray}
        >
          <Avatar
            onClick={onClickCard}
            width={90}
            height={90}
            avatar={avatar || logo}
            circle
            medium={{
              margin:'auto'
            }}
          />
          <Text
            marginTop={3}
            color="white"
            big
            semiBold
            onClick={onClickCard}
            medium={{
              textAlign: 'center'
            }}
          >
            {title}
          </Text>
          <Text
            medium={{
              textAlign: 'center'
            }}
          >
            {subTitle}
          </Text>
          {other &&
          <Text
            medium={{
              textAlign: 'center'
            }}
          >
            {other}
          </Text>
          }
        </Box>
        {bottomSheet && (
          <Box paddingHorizontal={3} paddingVertical={2}>
            {bottomSheet.map((item) => (
              <Box
                display="flex"
                justifyContent="between"
                key={item.description}
              >
                <Text feather>{item.description}</Text>
                <Text feather>{item.value}</Text>
              </Box>
            ))}
          </Box>
        )}
      </Box>
      <CSSTransition
        in={menuIsOpen}
        timeout={timeOut}
        unmountOnExit
        classNames={{
          enter: "expand-enter",
          enterActive: "expand-enter-active",
          exit: "expand-exit",
          exitActive: "expand-exit-active",
        }}
      >
        <CardSide timeOut={timeOut}>
          {showMenu ? (
            <BlueCard
              width="100%"
              height="100%"
              paddingHorizontal={3}
              paddingVertical={2}
              medium={{
                paddingRight: "40px"
              }}
              background={colors.blue}
            >
              {actionItems.map((item, index) => {
                return (
                  <LinkTo to={item.path} color="white" key={index}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="between"
                    >
                      {item.name}
                      <Icon icon="ios-eye" color={colors.white} />
                    </Box>
                  </LinkTo>
                );
              })}
            </BlueCard>
          ) : showMore ? (
            <Box
              width="100%"
              height="100%"
              position="absolute"
              background={colors.lightblue}
              paddingHorizontal={3}
              paddingVertical={2}
            >
              {moreItems.map((item, index) => {
                return (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="between"
                    key={index}
                  >
                    {item.path ? (
                      <LinkTo to={item.path} color="white">
                        {item.name}
                      </LinkTo>
                    ) : (
                      <DisabledLink to={item.path} color="white">
                        {item.name}
                      </DisabledLink>
                    )}
                  </Box>
                );
              })}
            </Box>
          ) : null}
        </CardSide>
      </CSSTransition>
    </Box>
  );
};

Card.defaultProps = {
  actionItems: undefined,
  moreItems: undefined,
  onClickCard: null,
};

Card.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  other: PropTypes.string,
  avatar: PropTypes.string,
  actionItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      link: PropTypes.string,
    })
  ), // This is should be refactored to be more specific
  moreItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      link: PropTypes.string,
    })
  ), // This is should be refactored to be more specific
  bottomSheet: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  onClickCard: PropTypes.func,
};

export default Card;
