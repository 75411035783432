import React, { useEffect } from "react";
import { withRouter } from "react-router";
import styled from "styled-components";

import { Box, Text, Divider, UI } from "../../../../components/Dashboard";

const AccountNavigationItem = styled(Box)`
  border-left: ${props =>
    props.active ? `3px solid ${UI.colors.lightblue}` : "none"};
  opacity: ${props => (props.disabled ? 0.125 : 1)};
`;

const AccountNavigation = ({ items, location, history }) => {
  // Not sure about this
  useEffect(() => {
    history.push("/dashboard/account/profile");
  }, []);

  const goTo = (event, path) => {
    event.preventDefault();
    history.push(path);
  };

  return (
    <Box
      display="flex"
      marginRight={5}
      marginBottom={4}
      minWidth={240}
      height="100%"
      direction="col"
      background={UI.colors.darkGray}
      elevated
    >
      <Box
        background={UI.colors.lightGray}
        paddingVertical={2}
        paddingHorizontal={2}
      >
        <Text semiBold color="offWhite">
          Your Account
        </Text>
      </Box>
      <Divider noMargin color="lightGray" />
      {items.map(item => {
        return (
          <React.Fragment key={item.title}>
            <AccountNavigationItem
              onClick={event => goTo(event, item.path)}
              paddingVertical={2}
              paddingHorizontal={2}
              active={location.pathname.includes(item.path)}
              disabled={item.disabled}
            >
              <Text
                semiBold
                color={
                  location.pathname.includes(item.path) ? "smoke" : "ghost"
                }
              >
                {item.title}
              </Text>
            </AccountNavigationItem>
            <Divider noMargin color="lightGray" />
          </React.Fragment>
        );
      })}
    </Box>
  );
};

AccountNavigation.defaultProps = {
  items: [{ title: "", path: "" }]
};

export default withRouter(AccountNavigation);
