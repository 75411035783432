import { ofType, combineEpics } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";
import { of } from "rxjs";

import { URL_NEW } from "../../global/utilities";
import * as accountActions from "./actions";
import { getToken } from "../auth/reducer";
import { normalizeData } from "./normalizers";

const fetchAccounts = (token, ajax) =>
  ajax({
    url: `${URL_NEW}/secured/accounts`,
    headers: { Authorization: token }
  });

const tokenSelector = state =>
  map(() => {
    return getToken(state.value);
  });

export const fetchAllAccountsEpic = (action$, state$, { ajax }) =>
  action$.pipe(
    ofType(accountActions.ACCOUNTS.REQUEST),
    tokenSelector(state$),
    switchMap(token =>
      fetchAccounts(token, ajax).pipe(
        map(res => accountActions.accountsSuccess(normalizeData(res.response))),
        catchError(e =>
          of(
            accountActions.accountsError({
              status: e.status,
              message: e.message
            })
          )
        )
      )
    )
  );

export default combineEpics(fetchAllAccountsEpic);
