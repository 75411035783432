export const navItems = [
  { name: "About Us", linkTo: "/aboutus" },
  { name: "Integrations", linkTo: "/integrations" },
];

export const IMAGES_URL = "https://d2r03fb27n6wbj.cloudfront.net/images";

export const logoWhite =
  "https://d2r03fb27n6wbj.cloudfront.net/images/VesselFrontLogoWhite.png";
export const logoWhiteText =
  "https://d2r03fb27n6wbj.cloudfront.net/images/VesselFrontLogoWithTextWhite.png";
export const logoOriginal =
  "https://d2r03fb27n6wbj.cloudfront.net/images/VesselFrontLogo.png";
