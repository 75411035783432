import { combineReducers } from "redux";

import * as actionTypes from "./actions";

const byId = (state = {}, action) => {
  switch (action.type) {
    case "SEARCH_DOCUMENTS_SUCCESS":
      return {
        ...state,
        ...action.payload.entities.documents,
      };
    case "SET_SEARCH_KEYWORD_DEBOUNCED":
    case "SELECT_ACCOUNTS":
    case "SELECT_VESSELS":
      return {};
    case actionTypes.DOCUMENTS_UPLOAD.SUCCESS:
      return {
        ...state,
        ...action.payload.entities.documents,
      };
    case actionTypes.DOCUMENT_DELETE.SUCCESS:
      delete state[action.payload.result];
      return {...state};
    default:
      return state;
  }
};

const allIds = (state = [], action) => {
  switch (action.type) {
    case actionTypes.DOCUMENTS.SUCCESS:
      return action.payload.result;
    case actionTypes.MORE_DOCUMENTS.SUCCESS:
      return [...new Set([...state, ...action.payload.result])];
    case actionTypes.DOCUMENTS_UPLOAD.SUCCESS:
      return [...new Set([...state, ...action.payload.result])];
    default:
      return state;
  }
};

const searchIds = (state = [], action) => {
  switch (action.type) {
    case actionTypes.DOCUMENTS_UPLOAD.SUCCESS:
      return [...new Set([...state, ...action.payload.result])];
    case "SEARCH_DOCUMENTS_SUCCESS":
      return [...new Set([...state, ...action.payload.result])];
    case "SET_SEARCH_KEYWORD_DEBOUNCED":
    case "SELECT_ACCOUNTS":
    case "SELECT_VESSELS":
      return [];
    default:
      return state;
  }
};

const assets = (state = {}, action) => {
  switch (action.type) {
    case "SEARCH_DOCUMENTS_SUCCESS":
      if (action.payload.entities.assets) {
        return { ...state, ...action.payload.entities.assets };
      }
      return state;
    default:
      return state;
  }
};

const searchKeyword = (state = "", action) => {
  switch (action.type) {
    case "SET_SEARCH_KEYWORD":
      return action.payload.keyword;
    default:
      return state;
  }
};

const filter = () => {
  const handleToggle = (state, action) => {
    const { checked, id } = action.payload;
    if (checked) {
      return [...state, parseInt(id)];
    } else if (id) {
      return state.filter((element) => element !== +id);
    }
    return state;
  };

  const selectedAccounts = (state = [], action) => {
    switch (action.type) {
      case "SELECT_ACCOUNTS":
        return handleToggle(state, action);
      default:
        return state;
    }
  };

  const selectedVessels = (state = [], action) => {
    switch (action.type) {
      case "SELECT_VESSELS":
        return handleToggle(state, action);

      default:
        return state;
    }
  };

  return combineReducers({
    selectedAccounts,
    selectedVessels,
  });
};

const loading = (state = { upload: false, request: false }, action) => {
  switch (action.type) {
    case "DOCUMENTS_REQUEST":
    case "SEARCH_DOCUMENTS_REQUEST":
      return {
        ...state,
        request: true,
      };
    case "DOCUMENTS_SUCCESS":
    case "DOCUMENTS_ERROR":
    case "SEARCH_DOCUMENTS_SUCCESS":
    case "SEARCH_DOCUMENTS_ERROR":
      return {
        ...state,
        request: false,
      };
    case "DOCUMENTS_UPLOAD_REQUEST":
      return {
        ...state,
        upload: true,
      };
    case "DOCUMENTS_UPLOAD_SUCCESS":
    case "DOCUMENTS_UPLOAD_ERROR":
    case "DOCUMENTS_CLOSE_MODAL":
      return {
        ...state,
        upload: false,
      };
    default:
      return state;
  }
};

const errors = () => {
  const upload = (state = {}, action) => {
    switch (action.type) {
      case "DOCUMENTS_UPLOAD_ERROR":
        return {
          ...state,
          hasError: true,
          message: action.payload.error,
        };
      case "DOCUMENTS_UPLOAD_SUCCESS":
      case "DOCUMENTS_UPLOAD_REQUEST":
      case "DOCUMENTS_CLOSE_MODAL":
        return {
          ...state,
          hasError: false,
          message: null,
        };
      default:
        return state;
    }
  };

  const search = (state = { notFound: false }, action) => {
    switch (action.type) {
      case "SEARCH_DOCUMENTS_ERROR":
        if (action.payload.status === 404) {
          return {
            ...state,
            notFound: true,
          };
        }
        return state;
      default:
        return {
          ...state,
          notFound: false,
        };
    }
  };

  return combineReducers({
    upload,
    search,
  });
};

const ui = (state = { paging: 1 }, action) => {
  switch (action.type) {
    case "DOCUMENTS_UPLOAD_SUCCESS":
      return {
        ...state,
        isComplete: true,
      };
    case "DOCUMENTS_OPEN_MODAL":
      return {
        ...state,
        isOpen: action.payload,
        isComplete: false,
      };
    case "DOCUMENTS_CLOSE_MODAL":
      return {
        ...state,
        isOpen: action.payload,
      };
    case "SEARCH_DOCUMENTS_SUCCESS":
      return {
        ...state,
        paging: action.meta.page,
      };
    default:
      return state;
  }
};

const dropdown = (state = { byId: {}, allIds: [] }, action) => {
  switch (action.type) {
    case "DOCUMENTS_FOR_DROPDOWN_SUCCESS":
      return {
        byId: action.payload.entities.documents,
        allIds: action.payload.result,
      };
    case "DOCUMENTS_FOR_DROPDOWN_RESET":
      return {
        byId: {},
        allIds: [],
      };
    default:
      return state;
  }
};

const documents = combineReducers({
  byId,
  allIds,
  searchIds,
  assets,
  searchKeyword,
  loading,
  errors: errors(),
  filter: filter(),
  ui,
  dropdown,
});

export default documents;

export const getDocumentById = (state, documentId) =>
  state.documents.byId[documentId];
export const getAllDocuments = (state) =>
  state.documents.allIds.map((id) => getDocumentById(state, id));
export const getSearchResults = (state) =>
  state.documents.searchIds.map((id) => getDocumentById(state, id));

export const getAssetById = (state, assetId) =>
  state.documents.assets[assetId] ? state.documents.assets[assetId] : null;

export const getAssetForDocument = (state, assetId) => {
  return assetId && state.documents.assets[assetId]
    ? state.documents.assets[assetId]
    : "Not Found";
};

export const getAccountList = (state) => {
  if (state.accounts.allIds.length) {
    return state.accounts.allIds.map(
      (accountId) => state.accounts.byId[accountId]
    );
  }
  return [];
};

export const getVesselList = (state) => {
  if (
    state.vessels.allIds.length &&
    state.documents.filter.selectedAccounts.length
  ) {
    const vesselsByAccount = state.vessels.allIds.filter((vesselId) =>
      state.documents.filter.selectedAccounts.includes(
        state.vessels.byId[vesselId].accountId
      )
    );
    return vesselsByAccount.map((id) => state.vessels.byId[id]);
  }

  return state.vessels.allIds.map((id) => state.vessels.byId[id]);
};

export const getFilters = (state) => {
  const modelIds =
    state.documents.filter.selectedVessels || "";
  return {
    accountIds: state.documents.filter.selectedAccounts || "",
    modelIds,
  };
};

export const getSearchKeyword = (state) => ({
  keyword: state.documents.searchKeyword,
});

export const isUploading = (state) =>
  state.documents.loading ? state.documents.loading.upload : false;

export const isLoading = (state) =>
  state.documents.loading ? state.documents.loading.request : false;

export const uploadHasError = (state) =>
  state.documents.errors.upload.hasError &&
  state.documents.errors.upload.hasError;

export const getUploadErrorMsg = (state) =>
  uploadHasError && state.documents.errors.upload.message;
