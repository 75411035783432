import React from "react";
import styled from "styled-components";

import Label from "../Fields/StyledLabel";
import StyledTextArea from "../Fields/StyledTextArea";

const TextAreaContainer = styled.div`
  width: 100%;
  margin-bottom: 24px;
`;

const TextArea = ({
  label,
  name,
  placeholder,
  dark,
  light,
  error,
  onChange,
  onBlur,
  value
}) => (
  <TextAreaContainer>
    <Label dark={dark} light={light} htmlFor={name}>
      {label}
    </Label>
    <StyledTextArea
      dark={dark}
      light={light}
      error={error}
      name={name}
      placeholder={placeholder}
      id={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
    />
  </TextAreaContainer>
);

export default TextArea;
