import React from "react";
import { Box, Text } from "../../../../components/Dashboard";

const MessageReceiver = ({ text }) => {
  return (
    <Box display="flex" paddingVertical={4} paddingHorizontal={4}>
      <Text color="white">{text}</Text>
    </Box>
  );
};

MessageReceiver.defaultProps = {
  text: "Message with Paliagkas Pantelis"
};

export default MessageReceiver;
