import React, { useEffect, useReducer } from "react";
import { connect } from "react-redux";
import { withFormik } from "formik";

import {
  getFlagsForDropdown,
  getTypesForDropdown
} from "../../../../../global/rootReducer";

import FirstStep from "./FirstStep";
import SecondStep from "./SecondStep";
import ThirdStep from "./ThirdStep";

import {
  Box,
  Button,
  H2,
  ProgressBar
} from "../../../../../components/Dashboard";

const STEPS = [<FirstStep />, <SecondStep />, <ThirdStep />];

const useStepper = () => {
  const [state, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case "NEXT_STEP":
          return {
            ...state,
            step: state.step + 1
          };
        case "PREVIOUS_STEP":
          return {
            ...state,
            step: state.step - 1
          };
        default:
          return state;
      }
    },
    { step: 1 }
  );
  return [state, dispatch];
};

const getComponent = (index, handleChange, setFieldValue, types, flags) => {
  if (STEPS[index]) {
    return React.cloneElement(STEPS[index], {
      handleChange,
      setFieldValue,
      types,
      flags
    });
  }
  return null;
};

const AddVesselForm = ({
  handleChange,
  handleSubmit,
  setFieldValue,
  flags,
  types,
  isPosting,
  dirty,
  failed,
  closeOnSubmit
}) => {
  const [state, dispatch] = useStepper();

  useEffect(() => {
    if (!isPosting && !failed && dirty) {
      closeOnSubmit();
    }
  }, [isPosting]);

  return (
    <>
      <H2>Add Vessel</H2>
      <ProgressBar progress={(state.step / 3) * 100} />
      <form onSubmit={handleSubmit} overflowY="scroll" overflowX="hidden">
        {getComponent(
          state.step - 1,
          handleChange,
          setFieldValue,
          types,
          flags
        )}
        <Box display="flex" flexDirection="row" justify="between" wrap>
          {state.step !== 1 && (
            <Button
              small
              inverted
              rounded
              type="button"
              onClick={() => dispatch({ type: "PREVIOUS_STEP" })}
            >
              Previous step
            </Button>
          )}
          {state.step !== 3 ? (
            <Button
              secondary
              rounded
              type="button"
              onClick={() => dispatch({ type: "NEXT_STEP" })}
              small
            >
              Next step
            </Button>
          ) : (
            <>
              <Button small secondary rounded type="submit">
                Add Vessel
              </Button>
            </>
          )}
        </Box>
      </form>
    </>
  );
};

const Form = withFormik({
  handleSubmit: (values, { props }) => {
    const data = {
      ...values,
      flag: values.flag.id,
      type: values.type.id
    };
    props.addVessel(data);
  }
})(AddVesselForm);

const mapStateToProps = state => ({
  account: 14,
  isPosting: state.vessels.isPosting,
  failed: state.vessels.errors.addVessel,
  flags: getFlagsForDropdown(state),
  types: getTypesForDropdown(state)
});

const mapDispatchToProps = dispatch => ({
  addVessel: data => {
    return dispatch({ type: "CREATE_VESSEL_REQUEST", payload: { ...data } });
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form);
