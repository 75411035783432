import { combineReducers } from "redux";

const byId = (state = {}, action) => {
  switch (action.type) {
    case "VESSELS_SUCCESS":
    case "CREATE_VESSEL_SUCCESS":
    case "VESSEL_SUCCESS":
      return {
        ...state,
        ...action.payload.data.entities.vessels
      };
    //FIX ME: temporary solution for getVessel not returning views the right way
    // return {
    //   ...state,
    //   [action.payload.data.result]: {
    //     ...state[action.payload.data.result],
    //     ...action.payload.data.entities.vessels[action.payload.data.result],
    //     views: state[action.payload.data.result].views
    //   }
    // };
    default:
      return state;
  }
};

const allIds = (state = [], action) => {
  switch (action.type) {
    case "VESSELS_SUCCESS":
      // this will probably needs refactoring for performance
      const merged = [...state, ...action.payload.data.result];
      return [...new Set(merged)];
    case "CREATE_VESSEL_SUCCESS":
      // this will probably needs refactoring for performance
      return [...new Set([...state, action.payload.data.result])];
    default:
      return state;
  }
};

const images = (state = {}, action) => {
  switch (action.type) {
    case "VESSELS_SUCCESS":
    case "CREATE_VESSEL_SUCCESS":
      return {
        ...state,
        ...action.payload.data.entities.images
      };
    default:
      return state;
  }
};

const isPosting = (state = false, action) => {
  switch (action.type) {
    case "CREATE_VESSEL_REQUEST":
      return true;
    case "CREATE_VESSEL_SUCCESS":
    case "CREATE_VESSEL_ERROR":
      return false;
    default:
      return state;
  }
};

const isFetching = (state = false, action) => {
  switch (action.type) {
    case "VESSEL_REQUEST":
      return true;
    case "VESSEL_SUCCESS":
    case "VESSEL_ERROR":
      return false;
    default:
      return state;
  }
};

const errors = (state = {}, action) => {
  switch (action.type) {
    case "CREATE_VESSEL_ERROR":
      return {
        ...state,
        addVessel: true
      };
    case "CREATE_VESSEL_REQUEST":
    case "CREATE_VESSEL_SUCCESS":
      return {
        ...state,
        addVessel: false
      };
    default:
      return state;
  }
};

const vessels = combineReducers({
  byId,
  allIds,
  images,
  isPosting,
  isFetching,
  errors
});

export default vessels;

export const getAllVesselsById = state => state.vessels.byId;
export const getAllVesselIds = state => state.vessels.allIds;
export const getVesselById = (state, vesselId) =>
  getAllVesselsById(state)[vesselId];

export const getScanIdsForVessel = (state, vesselId) => {
  const vesselById = getVesselById(state, vesselId);
  return vesselById ? vesselById.views : [];
};
export const getNumberOfScansForVessel = (state, vesselId) => {
  const vessel = getVesselById(state, vesselId);
  return vessel ? vessel.views.length : 0;
};

export const getVesselImage = (state, vessel) => {
  const hasImage =
    vessel !== undefined ? state.vessels.images[vessel.image] : "";
  return hasImage ? hasImage.url : "";
};

export const getProperty = (vessel, property) => {
  return vessel ? vessel[property] : undefined;
};

export const getVesselIdsForModelId = (state, modelId) => {
  return getAllVesselIds(state).filter(
    vesselId => getAllVesselsById(state)[vesselId].model.toString() === modelId
  );
};
