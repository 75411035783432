import { combineReducers } from "redux";

import * as messageTypes from "./actions";

const list = (state = [], action) => {
  switch (action.type) {
    case messageTypes.SEND_MESSAGE:
      return [...state, action.payload];
    case messageTypes.RECEIVE_MESSAGE:
      return [...state, action.payload.data];
    default:
      return state;
  }
};

const messages = combineReducers({
  list
});

export default messages;
