import { combineReducers } from "redux";

const byId = (state = {}, action) => {
  switch (action.type) {
    case "VESSELS_SUCCESS":
    case "VESSEL_SUCCESS":
      return {
        ...state,
        ...action.payload.data.entities.types
      };
    default:
      return state;
  }
};

const allIds = (state = [], action) => {
  switch (action.type) {
    case "VESSELS_SUCCESS":
    case "VESSEL_SUCCESS":
      return [
        ...new Set([
          ...state,
          ...Object.keys(action.payload.data.entities.types).map(
            typeId => typeId
          )
        ])
      ];
    default:
      return state;
  }
};

const types = combineReducers({
  byId,
  allIds
});

export default types;

export const getType = (state, id) => state.types.byId[id];
export const getAllTypeIds = state => state.types.allIds;
export const getTypesById = state => state.types.byId;
