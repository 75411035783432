import styled from "styled-components";

const Label = styled.label`
  font-family: "Roboto", sans-serif;
  color: ${props => (props.dark ? "#fff" : props.light ? "#484848" : "#fff")};
  padding-left: 0px;
  margin-bottom: 8px;

  ${props =>
    props.required &&
    `&::after {
      content: "*";
      padding-left: 4px;
      color: rgba(0,168,230,1);
    }`}
`;

export default Label;
