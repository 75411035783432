import React, { Suspense, PureComponent } from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";

import Overview from "./Pages/Dashboard/Overview/Overview";
import FloorsAndModels from "./Pages/Dashboard/Floors&Models/FloorsAndModels";
import Vessel from "./Pages/Dashboard/Vessel/Vessel";
import Scan from "./Pages/Dashboard/Scan/Scan";
import Vessels from "./Pages/Dashboard/Vessels/Vessels";
import People from "./Pages/Dashboard/People/People";
import Account from "./Pages/Dashboard/Account/Account";
import Inbox from "./Pages/Dashboard/Inbox/Inbox";
import Library from "./Pages/Dashboard/Library/Library";
import Zendesk from './Pages/Dashboard/Zendesk/Zendesk';

import { CurrentUserBox } from "./Pages/Dashboard/containers";

import { Box, UI } from "./components/Dashboard";

import { logoWhite as logo } from "./utilities";

import { getCurrentUser } from "./entities/user/reducer";

import { getUserGroupForUser } from "./global/rootReducer";

const NavBar = React.lazy(() =>
  import("./components/Dashboard/NavBars/NavBar")
);

class DashboardRoutes extends PureComponent {
  componentDidMount() {
    this.fetchAll();
  }

  fetchAll = () => {
    const {
      fetchUser,
      fetchVessels,
      fetchAllUsers,
      fetchAccounts,
    } = this.props;
    console.log(fetchUser, "USER");
    fetchUser();
    fetchAccounts();
    fetchAllUsers();
    fetchVessels();
  };

  goToOverview = () => {
    const { history } = this.props;
    history.push("/dashboard/overview");
  };

  logout = () => {
    const { logout } = this.props;
    logout();
  };

  renderNavBar = () => {
    const { location, user } = this.props;
    let links = [];
    if (user) {
      if (user.id === 4) {
        links = [
          { name: "Overview", path: "/dashboard/overview" },
          { name: "Your Vessels", path: "/dashboard/vessels" },
          { name: "Your People", path: "/dashboard/people" },
          { name: "Documents", path: "/dashboard/library" },
          // { name: "Your Account", path: "/dashboard/account" },
          // { name: "Inbox", path: "/dashboard/inbox" },
        ];
      } else {
        links = [
          { name: "Overview", path: "/dashboard/overview" },
          { name: "Your Vessels", path: "/dashboard/vessels" },
          { name: "Your People", path: "/dashboard/people" },
          { name: "Documents", path: "/dashboard/library" },
          { name: "Floors & Models", path: "/dashboard/floors&models" },
          { name: "Support", path: "/dashboard/support" },
          // { name: "Your Account", path: "/dashboard/account" },
        ];
      }
    }

    return (
      <Suspense fallback="">
        <Box marginBottom={7} className="burger-responsive">
          <NavBar links={links} currentLocation={location.pathname} />
        </Box>
      </Suspense>
    );
  };

  render() {
    return (
      <Box minWidth="100vw" minHeight="100vh" background={UI.colors.black}>
        <Box
          maxWidth="90%"
          paddingVertical={4}
          small={{
            "margin-right": "2.5% !important",
            "margin-left": "2.5% !important",
          }}

          style={{ margin: "auto" }}
        >
          <Box
            marginBottom={7}
            display="flex"
            justifyContent="between"
            alignItems="center"
            zIndex={500}
            minHeight="auto"
            small={{
              "flex-direction": "column",
              "min-height": "124px",
            }}
          >
            <img
              src={logo}
              height={80}
              alt="vesselfront-logo"
              onClick={this.goToOverview}
            />
            <CurrentUserBox />
          </Box>
          {this.renderNavBar()}
          <Switch>
            <Route exact path="/dashboard/overview" component={Overview} />
            <Route
              exact
              path="/dashboard/vessels/:vesselId/:scanCategory"
              component={Scan}
            />
            <Route
              exact
              path="/dashboard/vessels/:vesselId"
              component={Vessel}
            />
            <Route exact path="/dashboard/vessels/" component={Vessels} />
            <Route exact path="/dashboard/people/" component={People} />
            <Route exact path="/dashboard/support/" component={Zendesk} />
            <Route path="/dashboard/library/" component={Library} />
            <Route path="/dashboard/account/" component={Account} />
            <Route
              path="/dashboard/floors&models"
              component={FloorsAndModels}
            />
            {/*<Route path="/dashboard/inbox" component={Inbox} />*/}
          </Switch>
        </Box>
      </Box>
    );
  }
}

const mapStateToProps = (state) => {
  const user = getCurrentUser(state);
  return {
    userGroup: getUserGroupForUser(state, user),
    user: user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchUser: () => dispatch({ type: "USER_REQUEST" }),
  fetchVessels: () => dispatch({ type: "VESSELS_REQUEST" }),
  fetchAllUsers: () => dispatch({ type: "USERS_REQUEST" }),
  fetchAccounts: () => dispatch({ type: "ACCOUNTS_REQUEST" }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardRoutes);
