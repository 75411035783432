import React, { PureComponent } from "react";
import styled from "styled-components";
import { CSSTransition } from "react-transition-group";

class Crossfade extends PureComponent {
  render() {
    const { children, timeout } = this.props;
    return (
      <CSSTransition
        {...this.props}
        classNames={{
          appear: "crossfade-appear",
          appearActive: "crossfade-appear-active",
          enter: "crossfade-enter",
          enterActive: "crossfade-enter-active",
          exit: "crossfade-exit",
          exitActive: "crossfade-exit-active"
        }}
      >
        <CrossfadeContainer timeout={timeout}>{children}</CrossfadeContainer>
      </CSSTransition>
    );
  }
}

export default Crossfade;

const CrossfadeContainer = styled.div`
  width: 100%;
  opacity: 1;
  position: relative;
  z-index: 1000;

  &.crossfade-appear {
    opacity: 0.01;
  }
  &.crossfade-appear-active {
    opacity: 1;
    transition: all ${props => (props.timeout ? props.timeout + "ms" : "250ms")}
      cubic-bezier(0.45, 0.24, 0.66, 0.89);
  }
  &.crossfade-enter {
    opacity: 0.01;
  }

  &.crossfade-enter-active {
    opacity: 1;
    transition: all ${props => (props.timeout ? props.timeout + "ms" : "250ms")}
      cubic-bezier(0.45, 0.24, 0.66, 0.89);
  }

  &.crossfade-exit {
    opacity: 1;
  }

  &.crossfade-exit-active {
    opacity: 0.01;
    transition: all ${props => (props.timeout ? props.timeout + "ms" : "250ms")}
      cubic-bezier(0.45, 0.24, 0.66, 0.89);
  }
`;
