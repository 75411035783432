import React, { PureComponent } from "react";
import styled from "styled-components";

const CheckboxContainer = styled.div`
  position: relative;
  line-height: 1.618;
  margin-bottom: 16px;
`;

const CheckboxInput = styled.input.attrs({
  type: "checkbox",
  id: props => (props.name ? props.name : null),
  name: props => (props.name ? props.name : null)
})`
  opacity: 0;
  transition: all 250ms ease;

  + label::after {
    content: none;
  }

  &:checked + label::after {
    content: "";
  }

  &:checked + label::before {
    content: "";
    background: ${props =>
      props.theme === "light" || props.theme === "darkAlt"
        ? "#00a8e6"
        : "transparent"};
  }

  &:focus + label::before {
    border: 1px solid #ccc;
  }
`;

const CheckboxLabel = styled.label.attrs({
  htmlFor: props => (props.name ? props.name : null)
})`
  display: inline-block;
  padding-left: 48px;
  position: relative;
  user-select: none;
  outline: 0;
  font-family: "Roboto", sans-serif;
  font-size: ${props => (props.dashboard ? `16px` : `14px`)};
  color: ${props => (props.theme === "light" ? "#9B9B9B" : "#bdbdbd")};
  cursor: pointer;
  max-width: ${props => props.maxWidth ? props.maxWidth : 'unset'};

  &::before,
  &::after {
    content: "";
    position: absolute;
    display: inline-block;
    margin-top: 0;
  }

  &::before {
    /* top: 4px; */
    left: 0;
    width: 25px;
    height: 25px;
    background: ${props =>
      props.error
        ? "#4D2222"
        : props.theme === "light"
        ? "transparent"
        : "#383838"};
    border: 1px solid
      ${props =>
        props.error
          ? "#4D0F0F"
          : props.theme === "light"
          ? "#9B9B9B"
          : "#4d4d4d"};
    transition: all 50ms ease;
  }

  &::after {
    top: 8px;
    left: 6px;
    height: 6px;
    width: 12px;
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
    transform: rotate(-45deg);
  }
`;

class Checkbox extends PureComponent {
  static Label = CheckboxLabel;
  static Input = CheckboxInput;

  renderElements = () => {
    const { children, name, onChange, error, theme, dashboard } = this.props;
    return React.Children.map(children, child => {
      if (child.type === CheckboxInput) {
        return React.cloneElement(child, {
          name: name,
          onChange: onChange,
          theme: theme,
          dashboard: dashboard
          // type: type ? type : "checkbox"
        });
      } else {
        return React.cloneElement(child, {
          name: name,
          error: error,
          theme: theme,
          dashboard: dashboard
        });
      }
    });
  };

  render() {
    return <CheckboxContainer>{this.renderElements()}</CheckboxContainer>;
  }
}

export default Checkbox;
