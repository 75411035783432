import ReactDOM from "react-dom";
import { PureComponent } from "react";
import PropTypes from "prop-types";

const portalRoot = document.getElementById("portal-root");

class Portal extends PureComponent {
  element = document.createElement("div");

  componentDidMount() {
    portalRoot.appendChild(this.element);
  }

  componentWillUnmount() {
    portalRoot.removeChild(this.element);
  }

  render() {
    const { render, isOpen } = this.props;
    return ReactDOM.createPortal(
      render({
        isOpen: isOpen
      }),
      this.element
    );
  }
}

Portal.propTypes = {
  render: PropTypes.func.isRequired
};

export default Portal;
