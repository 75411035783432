import React, { PureComponent } from "react";
import { connect } from "react-redux";

import TrackPosition from "../../../components/Dashboard/TrackPosition/TrackPosition";

import TagBox from "./TagBox";

import { noteCreationStatus } from "../../../entities/scans/reducer";

const WINDOW = window;

class ScanBox extends PureComponent {
  state = {
    showcase: null,
    screenShot: null,
    screenPosition: null,
    showTooltip: false,
    tags: []
  };

  ifr = React.createRef();

  componentDidMount() {
    this.ifr.current.addEventListener("load", this.fetchMatterPortSpace);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      activateNote,
      deactivateNote,
      activateTooltip,
      deactivateTooltip,
      clearState
    } = this.props;

    if (prevState !== this.state) {
      if (this.state.screenShot) {
        activateNote();
        if (this.state.showTooltip) {
          activateTooltip();
        } else {
          deactivateTooltip();
        }
      } else {
        deactivateNote();
        this.timeout = setTimeout(() => clearState(), 3000);
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
    this.ifr.current.removeEventListener("load", this.fetchMatterPortSpace);
  }

  fetchMatterPortSpace = async () => {
    try {
      const showcase = await WINDOW.SHOWCASE_SDK.connect(
        this.ifr.current,
        "b7ff6f271cba44cda70feab5d5ce0392",
        "3.0.3"
      );
      const tags = await showcase.Mattertag.getData();
      this.setState({ showcase, tags });
    } catch (e) {
      console.error("MEGA ERROR: ", e);
    }
  };

  getSpacePose = async () => {
    try {
      const { showcase } = this.state;
      const screenShot = showcase.Camera.takeScreenShot();
      const screenPosition = showcase.Camera.getPose();
      const asyncResult = await Promise.all([screenShot, screenPosition]);
      this.setState({
        screenShot: asyncResult[0],
        screenPosition: asyncResult[1]
      });
    } catch (e) {
      console.log("Error: ", e);
    }
  };

  navigateToNote = async sid => {
    try {
      const { showcase } = this.state;
      await showcase.Mattertag.navigateToTag(sid);
    } catch (e) {
      console.log("Error: ", e);
    }
  };

  clearScreenShot = () => {
    const { showTooltip } = this.state;
    if (showTooltip) {
      this.closeTooltip();
    }
    this.setState({ screenShot: null });
  };

  onTagSubmit = data => {
    const { createViewNote } = this.props;
    createViewNote(data);
    this.clearScreenShot();
  };

  setTooltipOnClick = () => {
    this.setState({ showTooltip: true });
  };

  closeTooltip = () => {
    this.setState({ showTooltip: false });
  };

  renderMatterportSpace = () => {
    const { width, height, scan } = this.props;
    return (
      <iframe
        title="matterport"
        ref={this.ifr}
        frameBorder={0}
        allowFullScreen
        allow="vr"
        id="showcase_iframe"
        src={`https://my.matterport.com/show/?m=${scan.matterportId}&play=1`}
        style={{ position: "relative", height: height, width: width }}
      />
    );
  };

  renderScreenshot = () => {
    const { screenShot, screenPosition, showTooltip } = this.state;
    const { height, width, scan, noteStatus } = this.props;
    if (screenShot) {
      return (
        <TrackPosition
          render={state => (
            <TagBox
              currentPosition={state.currentPosition}
              lastPosition={state.lastPosition}
              screenShot={screenShot}
              height={height}
              width={width}
              clearScreenshot={this.clearScreenShot}
              onTagSubmit={this.onTagSubmit}
              noteStatus={noteStatus}
              matterportPosition={screenPosition.position}
              scanId={scan.id}
              showTooltip={showTooltip}
              closeTooltip={this.closeTooltip}
              setTooltipOnClick={this.setTooltipOnClick}
            />
          )}
        />
      );
    }
    return null;
  };

  render() {
    const { render, noteStatus } = this.props;
    const mergedState = {
      ...this.state,
      noteStatus
    };
    const renderMatterportSpace = this.renderMatterportSpace;
    return render(
      mergedState,
      renderMatterportSpace,
      this.renderScreenshot,
      this.getSpacePose,
      this.navigateToNote,
      this.clearScreenShot
    );
  }
}

ScanBox.defaultProps = {
  height: 600,
  width: 800
};

const mapStateToProps = state => ({
  noteStatus: noteCreationStatus(state)
});

const mapDispatchToProps = dispatch => ({
  createViewNote: data => {
    return dispatch({ type: "CREATE_VIEW_NOTE_REQUEST", payload: { ...data } });
  },
  activateNote: () => dispatch({ type: "NOTE_TAKING_ACTIVATED" }),
  deactivateNote: () => dispatch({ type: "NOTE_TAKING_DEACTIVATED" }),
  activateTooltip: () => dispatch({ type: "TOOLTIP_ACTIVATED" }),
  deactivateTooltip: () => dispatch({ type: "TOOLTIP_DEACTIVATED" }),
  clearState: () => dispatch({ type: "CLEAR_STATE" })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScanBox);
