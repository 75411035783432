import { connect } from "react-redux";
import {
  getCurrentUser,
  getProperty,
  getUserGroupForUser,
} from "../../../../entities/user/reducer";

import { Profile } from "../components";

const mapStateToProps = state => {
  const user = getCurrentUser(state);
  return {
    userId: user ? user.id : null,
    fullname: getProperty(user, "fullname"),
    email: getProperty(user, "email"),
    avatar: getProperty(user, 'avatar'),
    logo: getProperty(user, 'logo'),
    userGroup: getUserGroupForUser(state, user),
    isProfileUpdating: state.users.isUpdating.profile
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateUser: (userId, values) =>
      dispatch({
        type: "USER_UPDATE_REQUEST",
        payload: { userId, values },
        meta: "UPDATE_PROFILE"
      })
    ,
    updateAvatar: (userId, values) =>
      dispatch({
        type: "AVATAR_UPDATE_REQUEST",
        payload: { userId, values },
        meta: "UPDATE_PROFILE"
      })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Profile);
