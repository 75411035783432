import { combineReducers } from "redux";

const byId = (state = {}, action) => {
  switch (action.type) {
    case "VESSELS_SUCCESS":
    case "VESSEL_SUCCESS":
      return {
        ...state,
        ...action.payload.data.entities.flags
      };
    default:
      return state;
  }
};

const allIds = (state = [], action) => {
  switch (action.type) {
    case "VESSELS_SUCCESS":
    case "VESSEL_SUCCESS":
      return [
        ...new Set([
          ...state,
          ...Object.keys(action.payload.data.entities.flags).map(
            flagId => flagId
          )
        ])
      ];
    default:
      return state;
  }
};

const flags = combineReducers({
  byId,
  allIds
});

export default flags;

export const getFlag = (state, id) => state.flags.byId[id];
export const getAllFlagIds = state => state.flags.allIds;
export const getFlagsById = state => state.flags.byId;
