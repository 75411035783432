import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { Box, H2, Divider, UI, Portal, Text, Button, Spinner } from "../index";
import Crossfade from "../../Crossfade/Crossfade";

const LinkContainer = styled.div`
  display: block;
  position: relative;
  background: ${UI.colors.lightGray};
  width: 100%;
  height: 40px;
  padding: 8px;
  margin-bottom: 16px;

  &:after {
    content: "Link copied!";
    display: ${props => (props.copied ? "block" : "none")};
    position: absolute;
    top: -70%;
    right: 0;
    color: ${UI.colors.lightblue};
    padding: 4px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 14px;
  }
`;

const ShareBox = ({ toggle, toggler, title, link, loading, error }) => {
  const [copied, setCopy] = useState(false);

  const stopProp = event => {
    event.stopPropagation();
  };

  const onCopy = () => {
    setCopy(true);
  };

  return (
    <Portal
      isOpen={toggle}
      render={({ isOpen }) => (
        <Crossfade in={isOpen} timeout={250} unmountOnExit>
          <Box
            onMouseDown={toggler}
            position="fixed"
            display="flex"
            justify="center"
            alignItems="center"
            width="100%"
            height="100%"
          >
            <Box
              onMouseDown={stopProp}
              position="relative"
              minWidth={280}
              maxWidth="90%"
              maxHeight="90%"
              alignSelf="auto"
              background={UI.colors.darkGray}
              paddingVertical={4}
              paddingHorizontal={5}
              elevated
            >
              <H2>Share {title} View</H2>
              <Divider />
              {loading ? (
                <Box paddingBottom={2} paddingTop={2}>
                  <Spinner big />
                </Box>
              ) : error && error.status === 403 ? (
                <Box>
                  <Text color="smoke" center big>
                    You do not have the rights to share this particular view
                  </Text>
                </Box>
              ) : (
                <div>
                  <LinkContainer copied={copied}>
                    <Text>{link}</Text>
                  </LinkContainer>
                  <CopyToClipboard onCopy={onCopy} text={link}>
                    <Button secondary onClick={() => {}}>
                      Copy
                    </Button>
                  </CopyToClipboard>
                </div>
              )}
            </Box>
          </Box>
        </Crossfade>
      )}
    />
  );
};

ShareBox.propTypes = {
  toggle: PropTypes.bool.isRequired,
  toggler: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired
};

export default ShareBox;
