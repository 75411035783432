import { connect } from "react-redux";
import {
  getFullName,
  getProperty,
  getEmail,
} from "../../../../entities/user/reducer";

import { Card } from "../../../../components/Dashboard";

const mapStateToProps = (state, ownProps) => ({
  title: getFullName(ownProps.user),
  subTitle: getProperty(ownProps.user, "userGroup"),
  other: getEmail(ownProps.user),
  avatar: getProperty(ownProps.user, "avatar"),
  logo: getProperty(ownProps.user, "logo"),
  /*moreItems: [
    { name: "Message", path: "" },
    { name: "Edit Rights", path: "" },
    { name: "Delete person", path: "" },
  ],*/
});

export default connect(
  mapStateToProps,
  null
)(Card);
