import { combineEpics } from "redux-observable";

import authEpics from "../entities/auth/epics";
import userEpics from "../entities/user/epics";
import vesselEpics from "../entities/vessels/epics";
import accountEpics from "../entities/accounts/epics";
import documentsEpics from "../entities/documents/epics";

const rootEpic = combineEpics(
  authEpics,
  userEpics,
  vesselEpics,
  accountEpics,
  documentsEpics
);

export default rootEpic;
