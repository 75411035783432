import React, { PureComponent } from "react";

import { Box } from "../../../components/Dashboard";

import ScanListItem from "./ScanListItem";

class ScanList extends PureComponent {
  render() {
    const { scans, currentScan, vesselId } = this.props;
    return (
      <Box direction="col" display="flex">
        {scans.map(scan => {
          if (scan.id === currentScan.id) {
            return (
              <ScanListItem
                key={scan.id}
                scan={scan}
                vesselId={vesselId}
                active
              />
            );
          }
          return <ScanListItem key={scan.id} scan={scan} vesselId={vesselId} />;
        })}
      </Box>
    );
  }
}

export default ScanList;
