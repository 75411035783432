import React from "react";
import styled from "styled-components";

import { UI } from "../index";

const SwitchContainer = styled.div`
  display: block;
  box-sizing: border-box;
  position: relative;
  border-radius: 12px;
  flex-shrink: 0;
  height: 24px;
  width: 48px;
  border: 1px solid ${UI.colors.lightGray};
  background: ${UI.colors.darkGray};
`;

const Checkbox = styled.input.attrs({
  type: "checkbox",
  name: "test",
  id: "id"
})`
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
`;

const Slider = styled.div`
  position: absolute;
  box-sizing: border-box;
  height: 24px;
  width: 24px;
  border-radius: 100%;
  background: white;
  top: 0;
  left: 0;
  margin: -1px;
  transform: ${props =>
    props.switched ? "translateX(100%)" : "translateX(0%)"};
  transition: transform 125ms ease-in-out;
`;

const Switch = ({ switched, onChange }) => {
  const handleChange = event => {
    const { checked } = event.target;
    onChange({ event, value: checked });
  };
  return (
    <SwitchContainer>
      <Checkbox checked={switched} onChange={handleChange} />
      <Slider switched={switched} />
    </SwitchContainer>
  );
};

export default Switch;
