import React, { useState } from "react";

import { Box, Button } from "../../../../components/Dashboard";
import Textarea from "../../../../components/Input/TextArea";
import styled from "styled-components";
import Icon from "react-ionicons";

const AttachIcon = styled(Icon).attrs({
  fontSize: "24px",
  color: "white",
  icon: "ios-attach"
})``;

const ImageUploadIcon = styled(Icon).attrs({
  fontSize: "24px",
  color: "white",
  icon: "ios-image"
})``;

const MessageTextBox = ({ onSubmit, receiver, onDiscard }) => {
  const [message, setMessage] = useState("");

  const handleSubmit = event => {
    onSubmit(message);
    setMessage("");
  };

  const handleText = event => {
    setMessage(event.target.value);
  };

  return (
    <Box display="flex" direction="col" width="100%" paddingHorizontal={3}>
      <Textarea
        placeholder={`Reply to ${receiver}`}
        name="message"
        onChange={handleText}
        value={message}
      />
      <Box display="flex" direction="row" justify="between" marginBottom={2}>
        <Box>
          <ImageUploadIcon />
          <AttachIcon />
        </Box>
        <Box display="flex" justify="space-between">
          <Button small rounded inverted marginRight={1} onClick={onDiscard}>
            Discard
          </Button>
          <Button small secondary onClick={handleSubmit} rounded>
            Send
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

MessageTextBox.defaultProps = {
  onSubmit: msg => console.log(msg),
  receiver: "Pantelis Paliagkas",
  onDiscard: () => alert("A function should be passed")
};

export default MessageTextBox;
