import { connect } from "react-redux";
import { createSelector } from "reselect";

import {
  getSearchResults,
  isLoading,
} from "../../../../entities/documents/reducers";

import { Results } from "../components";

const mapStateToProps = (state) => {
  const documents = createSelector(getSearchResults, (search) =>
    search.sort((a, b) => b.id - a.id)
  );
  const loader = createSelector(isLoading, (isLoading) => isLoading);
  const notFound = createSelector(
    (state) => state.documents.errors.search.notFound,
    (notFound) => notFound
  );
  return {
    selectedVessels: state.documents.filter.selectedVessels,
    selectedAccounts: state.documents.filter.selectedAccounts,
    documents: documents(state),
    isLoading: loader(state),
    notFound: notFound(state),
  };
};
const mapDispatchToProps = (dispatch) => ({
  getDocuments: (page, accountIds) =>
    dispatch({
      type: "SEARCH_DOCUMENTS_REQUEST",
      payload: { accountIds },
      meta: { page: 0 },
    }),
  getMoreDocuments: () => dispatch({ type: "SEARCH_DOCUMENTS_FETCH_MORE" }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Results);
