import styled from "styled-components";

const StyledTextArea = styled.textarea`
  width: 100%;
  min-height: 96px;
  border: 1px solid ${props => (props.error ? "#4D0F0F" : "#4d4d4d")};
  background: ${props => (props.error ? "#4D2222" : "#383838")};
  padding: 16px;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: ${props => (props.dark ? "#fff" : props.light ? "#484848" : "#fff")};
  outline: none;
  box-shadow: none;
  -webkit-appearance: none;
  border-radius: 0;
  transition: all 0.3s cubic-bezier(0.45, 0.24, 0.66, 0.89);

  &:focus {
    border: 1px solid
      ${props => (props.dark ? "#ccc" : props.light ? "#0B39B2" : "#ccc")};
  }
`;

export default StyledTextArea;
