import React from "react";
import styled from "styled-components";
import Icon from "react-ionicons";

import { Box, UI, Text } from "../../../../components/Dashboard";

import pdfLogo from "../../../../assets/images/pdf.png";
import imgLogo from "../../../../assets/images/icon_1_image_x16.png";
import docLogo from "../../../../assets/images/msword.png";
import zipLogo from "../../../../assets/images/zip.png";
import xlLogo from "../../../../assets/images/excel.png";
import unknownLogo from "../../../../assets/images/undefined.png";

const UploadBox = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 64px;
  justify-content: center;
  align-items: center;
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 4px;
  border: 2px dashed
    ${props =>
      props.isOver || props.fileDropped
        ? UI.colors.lightblue
        : UI.colors.ghost};
  background: ${props =>
    props.isOver ? "rgba(0,168,230,0.25)" : "transparent"};
  transition: all 250ms ease-in-out;

  &:hover {
    cursor: pointer;
    border: 2px dashed ${UI.colors.lightblue};
  }
`;

const CloseIcon = styled(Icon).attrs({
  fontSize: "24px",
  color: UI.colors.darkGray,
  icon: "md-close"
})`
  position: absolute;
  top: -14px;
  right: -14px;
  background: ${UI.colors.smoke};
  border-radius: 50%;
  border: 2px solid ${UI.colors.smoke};
`;

const fileUploader = React.createRef();

const getFileType = fileType => {
  if (fileType === "application/pdf") {
    return pdfLogo;
  } else if (
    fileType ===
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  ) {
    return xlLogo;
  } else if (
    fileType === "image/jpeg" ||
    fileType === "image/jpg" ||
    fileType === "image/png"
  ) {
    return imgLogo;
  } else if (
    fileType ===
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  ) {
    return docLogo;
  } else if (fileType === "application/x-zip-compressed") {
    return zipLogo;
  } else {
    return unknownLogo;
  }
};

const Uploader = ({ onFileUpload }) => {
  const [files, setFiles] = React.useState([]);
  const handleFileUpload = event => {
    if (event.target.files.length) {
      setFiles([...files, ...event.target.files]);
      onFileUpload([...files, ...event.target.files]);
    }
  };

  const clearFiles = event => {
    event.preventDefault();
    event.stopPropagation();
    setFiles([]);
    onFileUpload([]);
  };

  const openFileBrowser = () => fileUploader.current.click();

  return (
    <UploadBox onClick={openFileBrowser} onTouchStart={openFileBrowser}>
      {files.length === 0 && (
        <Icon icon="md-cloud-upload" fontSize="24px" color={UI.colors.white} />
      )}
      {files.length !== 0 &&
        files.map(file => (
          <Box
            display="flex"
            alignItems="center"
            key={file.name}
            marginBottom={1}
          >
            <img src={getFileType(file.type)} alt={file.type} />
            <Text marginLeft={1} small semiBold color="offWhite">
              {file.name}
            </Text>
          </Box>
        ))}
      {files.length !== 0 && <CloseIcon onClick={clearFiles} />}
      <input
        type="file"
        style={{ display: "none" }}
        ref={fileUploader}
        onChange={handleFileUpload}
        name="file"
        multiple
      />
    </UploadBox>
  );
};

export default Uploader;
