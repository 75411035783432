import React, { Suspense, PureComponent } from "react";
import { Switch, Route, Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";

import Notification from "./components/Notifications/FullWidthNotification";
import Footer from "./components/Footers/Footer";
import GA from "./components/GoogleAnalytics/GoogleAnalytics";

import { logoWhite as logo } from "./utilities";

const Landing = React.lazy(() => import("./Pages/Landing/Landing"));
const TermsConditions = React.lazy(() =>
  import("./Pages/TermsConditions/TermsConditions")
);
const Readiness = React.lazy(() => import("./Pages/Readiness/Readiness"));
const SecureLogin = React.lazy(() => import("./Pages/SecureLogin/SecureLogin"));
const RequestAccess = React.lazy(() =>
  import("./Pages/RequestAccess/RequestAccess")
);
const PrivacyPolicy = React.lazy(() =>
  import("./Pages/PrivacyPolicy/PrivacyPolicy")
);
const AboutUs = React.lazy(() => import("./Pages/AboutUs/AboutUs"));

const IntegrationsPage = React.lazy(() =>
  import("./Pages/Integrations/Integrations")
);
const Preview = React.lazy(() => import("./Pages/Preview/Preview"));
const YourLibrary = React.lazy(() =>
  import("./Pages/DemoDashboard/DemoDashboard")
);

// #00A8E6

const pulse = keyframes`
  0% {
    opacity: 1;
  }

  75% {
    opacity: 0.75;
  }

  100% {
    opacity: 0;
  }
`;

const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100%;
  width: 100%;
  background: #1c1c1d;
  color: rgb(215, 215, 216);
  font-family: "Roboto", sans-serif;
  font-size: 24px;

  > img {
    height: 64px;
    width: auto;
    animation: ${pulse} 2s linear infinite;
  }
`;

class AppRoutes extends PureComponent {
  render() {
    return (
      <div>
        <Notification>
          Currently adding vessels;{" "}
          <Link to="/requestaccess">Request Access</Link> to get more
          information on use and integrations.
        </Notification>
        {GA.init() && <GA.RouteTracker />}
        <Switch>
          <Suspense
            fallback={
              <Loading>
                <img src={logo} alt="vesselfront-logo" />
              </Loading>
            }
          >
            <Route exact path="/" component={Landing} />
            <Route exact path="/aboutus" component={AboutUs} />
            <Route exact path="/integrations" component={IntegrationsPage} />
            <Route exact path="/readiness" component={Readiness} />
            <Route exact path="/securelogin" component={SecureLogin} />
            <Route exact path="/requestaccess" component={RequestAccess} />
            <Route exact path="/privacypolicy" component={PrivacyPolicy} />
            <Route exact path="/termsofservice" component={TermsConditions} />
            <Route exact path="/preview/:identifier" component={Preview} />
            <Route exact path="/yourlibrary" component={YourLibrary} />
          </Suspense>
        </Switch>
        <Footer />
      </div>
    );
  }
}

export default AppRoutes;
