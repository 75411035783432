import { useState } from "react";
import dayjs from "dayjs";

import { getDaysOfMonth } from "./utilities";

export function useCali() {
  const [date, setDate] = useState(dayjs());
  const [selectedDate, setSelectedDate] = useState();

  const nextMonth = () => {
    setDate(date.add(1, "month"));
  };

  const previousMonth = () => {
    setDate(date.subtract(1, "month"));
  };

  const selectDay = date => {
    setSelectedDate(date);
  };

  const getMonthDays = () => {
    return getDaysOfMonth(date);
  };

  return [
    date,
    selectedDate,
    nextMonth,
    previousMonth,
    selectDay,
    getMonthDays
  ];
}

export default useCali;
