import { createRequestTypes, createActions } from "../../global/utilities";

export const LOGIN = createRequestTypes("LOGIN");
export const LOGIN_WITH_PIN = createRequestTypes("LOGIN_WITH_PIN");

export const loginRequest = ({ username, password }) =>
  createActions(LOGIN["REQUEST"], { username, password });
export const loginSuccess = token => createActions(LOGIN["SUCCESS"], { token });
export const loginError = error => createActions(LOGIN["ERROR"], { ...error });

export const loginWithPinRequest = ({ username, pin }) =>
  createActions(LOGIN_WITH_PIN["REQUEST"], { username, pin });
export const loginWithPinSuccess = token =>
  createActions(LOGIN_WITH_PIN["SUCCESS"], { token });
export const loginWithPinError = error =>
  createActions(LOGIN_WITH_PIN["ERROR"], { ...error });

export const LOGOUT = createRequestTypes("LOGOUT");
