import { createRequestTypes, createActions } from "../../global/utilities";

export const USER = createRequestTypes("USER");
export const USERS = createRequestTypes("USERS");
export const USER_UPDATE = createRequestTypes("USER_UPDATE");
export const AVATAR_UPDATE = createRequestTypes("AVATAR_UPDATE");

export const userRequest = () => createActions(USER["REQUEST"]);
export const userSuccess = data => createActions(USER["SUCCESS"], { data });
export const userError = error => createActions(USER["ERROR"], { ...error });

export const usersRequest = () => createActions(USER["REQUEST"]);
export const usersSuccess = data => createActions(USERS["SUCCESS"], { data });
export const usersError = error => createActions(USERS["ERROR"], { ...error });

export const userUpdateRequest = data =>
  createActions(USER_UPDATE["REQUEST"], { data });
export const userUpdateSuccess = data =>
  createActions(USER_UPDATE["SUCCESS"], { data });
export const userUpdateError = error =>
  createActions(USER_UPDATE["ERROR"], { ...error });
