import { all, fork } from "redux-saga/effects";

import {
  watchVessels,
  watchSaveVessel,
  watchVessel
} from "../entities/vessels/sagas";
import { watchScans, watchSaveViewNote } from "../entities/scans/sagas";
import { watchPreview } from "../entities/preview/sagas";
// import documentsSagas from "../entities/documents/sagas";

function* rootSaga() {
  yield all([
    fork(watchVessels),
    fork(watchVessel),
    fork(watchSaveVessel),
    fork(watchScans),
    fork(watchSaveViewNote),
    fork(watchPreview)
    // fork(documentsSagas)
  ]);
}

export default rootSaga;
