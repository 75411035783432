import React from "react";

import styles from "./Footer.module.css";

import { logoWhiteText as logo } from "../../utilities";

const Footer = () => {
  return (
    <footer className={styles.footerContainer}>
      <div className={styles.footerTop}>
        <div>
          <img src={logo} alt="footer-logo" />
        </div>
        <div>
          <a
            className={styles.footerBookOurPlanners}
            href="mailto:info@vesselfront.com"
          >
            BOOK OUR TEAM NOW
          </a>
        </div>
      </div>
      <span className={styles.footerLine} />
      <div className={styles.footerBottom}>
        <span className={styles.tradeMark}>
          VesselFront™ is a U.S. SN 88008912 Trademark. All Rights Reserved. For
          more information or support, email us at{" "}
          <a className={styles.contactEmail} href="mailto:info@vesselfront.com">
            info@vesselfront.com
          </a>
          , call us at <a href="tel:+302130038247">+30-213-0038-247</a> / U.S.
          toll-free at
          <a href="tel:+18336639376"> 1-833-ONE-ZERO</a>{" "}
          <a href="tel:18336639376"> (1-833-663-9376)</a>.
        </span>
      </div>
    </footer>
  );
};

export default Footer;
