import React, { PureComponent } from "react";
import { connect } from "react-redux";

import { Box, H1, Divider } from "../../../components/Dashboard";

import { getVesselById } from "../../../global/rootReducer";
import { getType } from "../../../entities/types/reducer";
import { getFlag } from "../../../entities/flags/reducer";

import QuickEditBox from "./QuckEditBox";
import ScansBox from "./ScansBox";
import InfoBox from "./InfoBox";

class Vessel extends PureComponent {
  componentDidMount() {
    const { getVessel, hasGA } = this.props;
    if (!hasGA) {
      getVessel();
    }
  }
  render() {
    const { vesselName, vesselId, history, isFetching } = this.props;
    return (
      <Box
        marginBottom={4}
        medium={{
          textAlign: 'center',
          justifyContent: 'center'
        }}
      >
        <H1 bold>{vesselName}</H1>
        <Divider marginBottom={4} />
        <QuickEditBox
          history={history}
          vesselId={vesselId}
          loading={isFetching}
          height={640}
        />
        <H1>Vessel Info</H1>
        <Divider marginBottom={3} />
        <InfoBox vesselId={vesselId} />
        <H1>All Vessel Views</H1>
        <Divider marginBottom={3} />
        <ScansBox vesselId={vesselId} />
      </Box>
    );
  }
}

Vessel.defaultProps = {
  vesselName: "Vessel Name",
  vesselFlag: "Greece",
  vesselType: "Container ship"
};

const mapStateToProps = (state, props) => {
  const { match } = props;
  const vesselId = match.params.vesselId;
  const vessel = getVesselById(state, vesselId);
  const vesselName = vessel ? vessel.name : null;
  const vesselType = getType(state, vesselId);
  const vesselFlag = getFlag(state, vesselId);
  const hasGA = vessel && vessel.ga;
  return {
    vesselId,
    vesselName,
    vesselType: vesselType && vesselType.description,
    vesselFlag: vesselFlag && vesselFlag.description,
    isFetching: state.vessels.isFetching,
    hasGA: hasGA
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const { match } = props;
  const vesselId = match.params.vesselId;
  return {
    getVessel: () => dispatch({ type: "VESSEL_REQUEST", payload: { vesselId } })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Vessel);
