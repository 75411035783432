import { connect } from "react-redux";

import { getAllUsers, userCanAddPeople } from "../../../../global/rootReducer";

import { UsersBox } from "../components";

const mapStateToProps = state => ({
  allUsers: getAllUsers(state),
  canAddPeople: userCanAddPeople(state)
});

export default connect(mapStateToProps)(UsersBox);
