import styled, { keyframes } from "styled-components";
import { UI } from "../index";

const animation = keyframes`
  to { transform: rotate(360deg); }
`;

const Spinner = styled.div`
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: ${props => (props.small ? "16px" : props.big ? "64px" : "32px")};
  height: ${props => (props.small ? "16px" : props.big ? "64px" : "32px")};
  margin-top: ${props =>
    props.small ? "-8px" : props.big ? "-32px" : "-16px"};
  margin-left: ${props =>
    props.small ? "-8px" : props.big ? "-32px" : "-16px"};
  border-radius: 50%;
  border: ${props => (props.small ? "1px" : "2px")} solid ${UI.colors.lightGray};
  border-top-color: ${props =>
    props.alt ? UI.colors.white : UI.colors.lightblue};
  animation: ${animation} 0.6s linear infinite;
`;

export default Spinner;
