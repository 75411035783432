import { ofType, combineEpics } from "redux-observable";
import { map, pluck, switchMap, catchError } from "rxjs/operators";
import { normalize, schema } from "normalizr";

import { URL } from "../../global/utilities";
import * as actions from "./actions";
import { getToken } from "../auth/reducer";
import { of } from "rxjs";

const flag = new schema.Entity("flags");
const type = new schema.Entity("types");
const thumbnail = new schema.Entity("thumbnails");
const image = new schema.Entity("images");

// could be by name but will do it with id for now
// const scan = new schema.Entity("scans", {}, { idAttribute: "title" });
const view = new schema.Entity("views", {
  thumbnail: thumbnail
});

// Defines vessel entity
const vessel = new schema.Entity("vessels", {
  flag: flag,
  type: type,
  image: image,
  views: [view]
});

const vesselList = [vessel];

export const fetchVesselsEpic = (action$, state$, { ajax }) =>
  action$.pipe(
    ofType(actions.VESSELS.REQUEST),
    map(() => getToken(state$.value)),
    switchMap(token =>
      ajax({
        url: `${URL}/secured/vessels`,
        method: "GET",
        headers: {
          Authorization: token
        }
      }).pipe(
        pluck("response"),
        map(res => normalize(res, vesselList)),
        map(data => actions.vesselsSuccess(data)),
        catchError(e => of(actions.vesselsError(e)))
      )
    )
  );

export default combineEpics(fetchVesselsEpic);
