import styled from "styled-components";
import PropTypes from "prop-types";
import { getMargins, colors, getPaddings } from "../UI/ui";

const SimpleButton = styled.button`
  display: block;
  position: relative;

  font-size: ${props => (props.small ? "14px" : "16px")};
  font-family: "Roboto", sans-serif;
  font-weight: ${props =>
    props.bold ? "700" : props.semiBold ? "500" : "400"};
  text-align: center;
  background: transparent;
  color: ${props =>
    !props.secondary
      ? colors.lightblue
      : props.white
      ? colors.white
      : colors.ghost};

  /* Remove default styles */
  outline: 0;
  border: none;
  margin: 0;
  padding: 0;
  text-decoration: none;

  ${getMargins};
  ${getPaddings};

  &:hover {
    color: ${props => (!props.secondary ? colors.white : colors.lightblue)};
    text-decoration: none;
    transition: all 250ms ease-in-out;
    cursor: pointer;
  }

  &:focus {
    outline: 0;
  }

  &:disabled {
    opacity: 0.75;
    cursor: not-allowed;
  }
`;

SimpleButton.propTypes = {
  secondary: PropTypes.bool
};

export default SimpleButton;
