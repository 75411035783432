import { createRequestTypes, createActions } from "../../global/utilities";

export const DOCUMENTS = createRequestTypes("DOCUMENTS");
export const MORE_DOCUMENTS = createRequestTypes("MORE_DOCUMENTS");
export const DOCUMENTS_SEARCH = createRequestTypes("DOCUMENTS_SEARCH");
export const DOCUMENTS_UPLOAD = createRequestTypes("DOCUMENTS_UPLOAD");
export const DOCUMENT_UPDATE = createRequestTypes("DOCUMENT_UPDATE");
export const DOCUMENT_DELETE = createRequestTypes("DOCUMENT_DELETE");

export const documentsRequest = () => createActions(DOCUMENTS["REQUEST"]);
export const documentsSuccess = ({ entities, result }) =>
  createActions(DOCUMENTS["SUCCESS"], { entities, result });
export const documentsError = (error) =>
  createActions(DOCUMENTS["ERROR"], { error });

export const moreDocumentsRequest = (page) =>
  createActions(MORE_DOCUMENTS["REQUEST"], { page });
export const moreDocumentsSuccess = ({ entities, result }) =>
  createActions(MORE_DOCUMENTS["SUCCESS"], { entities, result });
export const moreDocumentsError = (error) =>
  createActions(MORE_DOCUMENTS["ERROR"], { error });

export const documentsSearchRequest = (keyword) =>
  createActions(DOCUMENTS_SEARCH["REQUEST"], { keyword });
export const documentsSearchSuccess = ({ entities, result }) =>
  createActions(DOCUMENTS_SEARCH["SUCCESS"], { entities, result });
export const documentsSearchError = (error) =>
  createActions(DOCUMENTS_SEARCH["ERROR"], { error });

export const documentsUploadRequest = (data) =>
  createActions(DOCUMENTS_UPLOAD["REQUEST"], { data });
export const documentsUploadSuccess = ({ entities, result }) =>
  createActions(DOCUMENTS_UPLOAD["SUCCESS"], { entities, result });
export const documentsUploadError = (error) =>
  createActions(DOCUMENTS_UPLOAD["ERROR"], { error });

export const documentUpdateRequest = (data) =>
  createActions(DOCUMENT_UPDATE["REQUEST"], data);
export const documentUpdateSuccess = ({ entities, result }) =>
  createActions(DOCUMENT_UPDATE["SUCCESS"], { entities, result });
export const documentUpdateError = (error) =>
  createActions(DOCUMENT_UPDATE["ERROR"], { error });

export const documentDeleteRequest = (id) =>
  createActions(DOCUMENT_DELETE["REQUEST"], id);
export const documentDeleteSuccess = ({ entities, result }) =>
  createActions(DOCUMENT_DELETE["SUCCESS"], { entities, result });
export const documentDeleteError = (error) =>
  createActions(DOCUMENT_DELETE["ERROR"], { error });

export const openDocumentsModal = () => ({
  type: "DOCUMENTS_OPEN_MODAL",
  payload: true,
});

export const closeDocumentsModal = () => ({
  type: "DOCUMENTS_CLOSE_MODAL",
  payload: false,
});
