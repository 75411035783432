import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Crossfade from "../../Crossfade/Crossfade";

import Box from "../Boxes/Box";
import Caret from "../Caret/Caret";

const StyledTooltip = styled.div`
  display: block;
  position: absolute;
  height: 120px;
  width: 120px;
  left: ${props => props.position.left}px;
  top: ${props => props.position.top}px;
  bottom: ${props => props.position.bottom}px;
  right: ${props => props.position.right}px;
  background: #4a4a4a;
  padding: 20px;
  box-shadow: 2px 4px 32px rgba(0, 0, 0, 0.25);
  border: 1px solid #3a3a3a;
  font-family: "Roboto", sans-serif;
  z-index: 999;
`;

class TooltipBox extends PureComponent {
  state = {
    toolTipPosition: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0
    },
    caretPosition: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0
    }
  };

  tooltipRef = React.createRef();

  componentDidMount() {
    const { position, anchor } = this.props;
    const targetBoundingClient = anchor.current.getBoundingClientRect();
    this.calculateSpace(targetBoundingClient, position);
  }

  componentDidUpdate(prevProps) {
    const { position, anchor } = this.props;
    if (prevProps.position !== position) {
      const targetBoundingClient = anchor.current.getBoundingClientRect();
      this.calculateSpace(targetBoundingClient, position);
    }
  }

  calculateSpace(target, position) {
    const toolTipBoundingRect = this.tooltipRef.current.getBoundingClientRect();
    let toolTipPosition = {};
    let caretPosition = {};
    switch (position) {
      case "bottom":
        toolTipPosition = {
          top: target.height + 16,
          bottom: 0,
          left: 0,
          right: 0
        };
        caretPosition = {
          top: -24,
          bottom: 0,
          left: toolTipBoundingRect.width / 2 - 12,
          right: 0
        };
        break;
      case "top":
        toolTipPosition = {
          top: -(toolTipBoundingRect.height + 16),
          bottom: 0,
          left: 0,
          right: 0
        };
        caretPosition = {
          top: toolTipBoundingRect.height - 2,
          bottom: 0,
          left: toolTipBoundingRect.width / 2 - 12,
          right: 0
        };
        break;
      case "left":
        toolTipPosition = {
          top: -((toolTipBoundingRect.height - target.height) / 2),
          bottom: 0,
          left: -(toolTipBoundingRect.width + 16),
          right: 0
        };
        caretPosition = {
          top: toolTipBoundingRect.height / 2 - 12,
          bottom: 0,
          left: toolTipBoundingRect.width - 2,
          right: 0
        };
        break;
      case "right":
        toolTipPosition = {
          top: -((toolTipBoundingRect.height - target.height) / 2),
          bottom: 0,
          left: target.width + 16,
          right: 0
        };
        caretPosition = {
          top: toolTipBoundingRect.height / 2 - 12,
          bottom: 0,
          left: -24,
          right: 0
        };
        break;
      default:
        toolTipPosition = {
          top: -(toolTipBoundingRect.height + 16),
          bottom: 0,
          left: 0,
          right: 0
        };
        break;
    }

    this.setState({
      toolTipPosition: toolTipPosition,
      caretPosition: caretPosition
    });
  }

  render() {
    const { toolTipPosition, caretPosition } = this.state;
    const { children, position } = this.props;
    return (
      <StyledTooltip position={toolTipPosition} ref={this.tooltipRef}>
        {children}
        <Caret
          direction={position}
          style={{
            position: "absolute",
            fill: "#4a4a4a",
            ...caretPosition
          }}
        />
      </StyledTooltip>
    );
  }
}

class Tooltip extends PureComponent {
  state = { show: false };

  triggerRef = React.createRef();

  handleShow = event => {
    const { show } = this.state;
    this.setState({ show: !show });
  };

  render() {
    const { children, position, render } = this.props;
    const { show } = this.state;
    return (
      <Box position="relative" zIndex="99999">
        {render(this.state.show, this.triggerRef)}
        {/* <Button innerRef={this.triggerRef} onClick={this.handleShow}>{text}</Button> */}
        <Crossfade timeout={250} in={show} unmountOnExit>
          <TooltipBox position={position} anchor={this.triggerRef}>
            {children}
          </TooltipBox>
        </Crossfade>
      </Box>
    );
  }
}

Tooltip.propTypes = {
  anchor: PropTypes.object,
  show: PropTypes.bool
};

export default Tooltip;
