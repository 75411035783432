import React from "react";
import styled from "styled-components";
import Icon from "react-ionicons";


import Box from "../Boxes/Box";


const SeachContainer = styled(Box)`
  border: none;
  border-color: ${props => (props.theme === "light" ? "#979797 !important" : "#d8d8d8 !important")};
  border-bottom: 1px solid;
`;

const StyledInput = styled.input`
  position: relative;
  display: block;
  width: 100%;
  height: 100%;

  /* themeing */
  /* background: ${props =>
    props.theme === "light" ? "#f7f7f7" : "#303031"}; */
  background: transparent;
  color: ${props => (props.theme === "light" ? "#9B9B9B" : "#9b9b9b")};
  /* fonts */
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  
  /* paddings */
  padding-left: 16px;
  padding-right: 16px;

  /* input specifics */
  outline: 0;
  border: none;
  
  &:focus {
    outline: 0;
  }
`;

const ArrowIcon = styled(Icon).attrs({
  fontSize: "30px",
  color: "#D8D8D8",
  icon: "ios-arrow-forward"
})`
  cursor: pointer;
`;

const SearchInput = (props) => {
  return (
    <SeachContainer
      display="flex"
      alignItems="center"
      height={64}
      paddingLeft={2}
      paddingRight={2}
      {...props}
    >
      <Icon icon="ios-search" fontSize="30px" color="#D8D8D8" />
      <StyledInput {...props} />
      <ArrowIcon onClick={props.onClose} />
    </SeachContainer>
  );
}

export default SearchInput;
