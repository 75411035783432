import React from "react";
import styled from "styled-components";
import Icon from "react-ionicons";

import { Box, UI } from "../../../../components/Dashboard";

const SearchContainer = styled(Box)`
  border-bottom: 1px solid ${UI.colors.smoke};
`;

const SearchInput = styled.input`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);

  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: ${UI.colors.smoke};

  outline: 0;
  border: 0;
  padding-left: 16px;
  padding-right: 16px;

  &:focus {
    border: 0;
  }
`;

const SearchIcon = styled(Icon).attrs({
  fontSize: "30px",
  color: "#D8D8D8",
  icon: "ios-search"
})``;

const Search = ({ onChange }) => {
  const handleChange = event => onChange(event.target.value);
  return (
    <SearchContainer display="flex" height={64} align="center">
      <SearchIcon />
      <SearchInput
        onChange={handleChange}
        placeholder="Type here to Search Documents"
      />
    </SearchContainer>
  );
};

export default Search;
