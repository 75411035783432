import { createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import createSagaMiddleware from "redux-saga";
import { createEpicMiddleware } from "redux-observable";
import logger from "redux-logger";
import { ajax } from "rxjs/ajax";

import rootReducer from "./rootReducer";
import rootSaga from "./rootSaga";
import rootEpic from "./rootEpics";

const persistorConfig = {
  key: "root",
  whitelist: ["auth"],
  storage
};

const persistedReducer = persistReducer(persistorConfig, rootReducer);
const sagaMiddleware = createSagaMiddleware();
const epicMiddleware = createEpicMiddleware({
  dependencies: { ajax }
});

const composeEnhancers =
  process.env.NODE_ENV === "development"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : compose;

const args =
  process.env.NODE_ENV === "development"
    ? [sagaMiddleware, epicMiddleware, logger]
    : [sagaMiddleware, epicMiddleware];

const configureStore = () => {
  const store = createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(...args))
  );
  return store;
};

const store = configureStore();
const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);
epicMiddleware.run(rootEpic);

export default { store, persistor };
