import React, { PureComponent } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import {
  getMargins,
  getPaddings,
  getAbsolutePositioning,
  getPixels,
  media,
} from "../UI/ui";
import { omit } from "../../../global/utilities";

const getBehaviour = (flex) => {
  if (flex) {
    if (flex === "grow") {
      return "1 1 auto";
    } else if (flex === "none") {
      return "0 0 auto";
    } else if (flex === "fix300") {
      return "1 1 300px";
    } else {
      return "0 1 auto";
    }
  }
  return null;
};

const justify = (attribute) => {
  switch (attribute) {
    case "around":
      return "space-around";
    case "between":
      return "space-between";
    case "start":
      return "flex-start";
    case "end":
      return "flex-end";
    case "center":
      return "center";
    case "evenly":
      return "space-evenly";
    default:
      return "start";
  }
};

const getDirection = (direction) => {
  switch (direction) {
    case "column":
    case "col":
      return "column";
    case "row":
      return "row";
    default:
      return "row";
  }
};

const getShape = (borderStyle) => {
  switch (borderStyle) {
    case "rounded":
      return "4px";
    case "circle":
      return "50%";
    case "pill":
      return "999px";
    default:
      return "0px";
  }
};

const getPosition = (position) => {
  switch (position) {
    case "absolute":
      return "absolute";
    case "static":
      return "static";
    case "fixed":
      return "fixed";
    default:
      return "relative";
  }
};

const StyledBox = styled.div`
  display: ${(props) => props.display || "block"};
  position: ${(props) => getPosition(props.position)};
  flex: ${(props) => getBehaviour(props.flex)};
  flex-basis: ${(props) => props.basis || "auto"};
  justify-content: ${(props) => justify(props.justifyContent || props.justify)};
  align-items: ${(props) => props.alignItems || props.align || "center"};
  align-self: ${(props) => props.alignSelf || "stretch"};
  align-content: ${(props) => props.alignContent || "center"};
  flex-direction: ${(props) =>
    getDirection(props.direction || props.flexDirection)};
  background: ${(props) => props.background || "transparent"};
  background-image: ${(props) => props.gradient || "none"};
  flex-wrap: ${(props) => (props.wrap ? "wrap" : "nowrap")};
  border-radius: ${(props) => getShape(props.shape)};
  border: ${(props) =>
    props.borderColor ? `1px solid ${props.borderColor}` : "none"};
  height: ${(props) => getPixels(props.height)};
  width: ${(props) => getPixels(props.width)};
  max-height: ${(props) => getPixels(props.maxHeight)};
  max-width: ${(props) => getPixels(props.maxWidth)};
  min-height: ${(props) => getPixels(props.minHeight)};
  min-width: ${(props) => getPixels(props.minWidth)};
  overflow: ${(props) => props.overflow || "visible"};
  overflow-y: ${(props) => props.overflowY || "visible"}; 
  overflow-x: ${(props) => props.overflowX || "visible"}; 
  ${getMargins}
  ${getPaddings}
  ${getAbsolutePositioning}
  box-shadow: ${(props) => props.elevated && "0 2px 11px 0 rgba(0,0,0,0.2)"};
  z-index: ${(props) =>
    props.zIndex && typeof props.zIndex === "number" ? props.zIndex : 'unset'};
  box-sizing: border-box;

  ${(props) => props.xlarge && media.xlarge`${props.xlarge}`};
  ${(props) => props.large && media.large`${props.large}`};
  ${(props) => props.medium && media.medium`${props.medium}`};
  ${(props) => props.small && media.small`${props.small}`};
  ${(props) => props.xsmall && media.xsmall`${props.xsmall}`};
  
  ${(props) => props.onClick && "&:hover { cursor: pointer; }"}

`;

class Box extends PureComponent {
  render() {
    const { children, _ref } = this.props;
    return (
      <StyledBox {...omit([children, _ref], this.props)} ref={_ref}>
        {children}
      </StyledBox>
    );
  }
}

Box.propTypes = {
  children: PropTypes.node,
  alignContent: PropTypes.string,
  alignItems: PropTypes.string,
  alignSelf: PropTypes.string,
  color: PropTypes.string,
  justifyContent: PropTypes.string,
  justify: PropTypes.string,
  direction: PropTypes.string,
  display: PropTypes.string,
  flex: PropTypes.string,
  basis: PropTypes.string,
  background: PropTypes.string,
  gradient: PropTypes.string,
  shape: PropTypes.string,
  wrap: PropTypes.bool,
  padding: PropTypes.number,
  overflow: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  elevated: PropTypes.bool,
  phone: PropTypes.string,
  desktop: PropTypes.string,
  tablet: PropTypes.string,
  onClick: PropTypes.func,
};

export default Box;
