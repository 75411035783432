import { combineReducers } from "redux";
import { createSelector } from "reselect";

import users, * as fromUsers from "../entities/user/reducer";
import auth from "../entities/auth/reducer";
import vessels, * as fromVessels from "../entities/vessels/reducer";
import accounts from "../entities/accounts/reducer";
import flags, * as fromFlags from "../entities/flags/reducer";
import types, * as fromTypes from "../entities/types/reducer";
import scans, * as fromScans from "../entities/scans/reducer";
import previews, * as fromPreviews from "../entities/preview/reducer";
import messages from "../entities/messages/reducer";
import documents from "../entities/documents/reducers";

const appReducer = combineReducers({
  accounts,
  users,
  auth,
  vessels,
  flags,
  types,
  scans,
  previews,
  messages,
  documents
});

const rootReducer = (state, action) => {
  switch (action.type) {
    case "LOGOUT_SUCCESS":
      return appReducer({}, action);
    default:
      return appReducer(state, action);
  }
};

export default rootReducer;

// Selectors for previews

export const getCurrentScan = state => fromPreviews.getCurrentScan(state);
export const getVesselofScan = state =>
  fromPreviews.getVesselofScan(
    state,
    getCurrentScan(state) && getCurrentScan(state).vessel
  );
// Selectors for users

export const getAllUsers = createSelector(
  [fromUsers.getAllUserIds],
  userIds => Object.keys(userIds).map(userId => userIds[userId])
);

export const getAllBrokers = createSelector(
  [fromUsers.getAllUserIds],
  userIds => {
    const result = Object.keys(userIds).reduce((users, userId) => {
      const user = userIds[userId];
      if (user.user_group === 5) {
        return [...users, user];
      }
      return users;
    }, []);
    return result;
  }
);

export const getUserGroupForUser = user =>
  fromUsers.getProperty(user, "userGroupId");

export const getUserGroupForCurrentUser = state =>
  getUserGroupForUser(fromUsers.getCurrentUser(state));

const userHasRights = createSelector(
  [getUserGroupForCurrentUser],
  userGroupId => {
    if (userGroupId) {
      if (userGroupId === 1 || userGroupId === 2 || userGroupId === 3) {
        return true;
      }
    }
    return false;
  }
);

export const userCanAddVessel = userHasRights;

export const userCanAddPeople = userHasRights;

// Selectors for Vessels

export const getVesselById = (state, vesselId) =>
  fromVessels.getVesselById(state, vesselId);

export const getVesselImage = (state, vessel) =>
  fromVessels.getVesselImage(state, vessel);

export const getAllVessels = createSelector(
  [fromVessels.getAllVesselIds, fromVessels.getAllVesselsById],
  (vesselIds, vesselsById) => {
    return vesselIds.map(vesselId => vesselsById[vesselId]);
  }
);

// Selectors for flags

export const getFlagsForDropdown = createSelector(
  [fromFlags.getAllFlagIds, fromFlags.getFlagsById],
  (flagIds, flagsById) =>
    flagIds.map(flagId => {
      return {
        id: flagId,
        value: flagsById[flagId].description
      };
    })
);

// Selectors for types

export const getTypesForDropdown = createSelector(
  [fromTypes.getAllTypeIds, fromTypes.getTypesById],
  (typeIds, typesById) =>
    typeIds.map(typeId => {
      return {
        id: typeId,
        value: typesById[typeId].description
      };
    })
);

// Selectors for scans

export const getScansByTitle = createSelector(
  [fromScans.getAllScanIds, fromScans.getScansById],
  (scanIds, scansById) =>
    scanIds.reduce((obj, scanId) => {
      const scanObj = scansById[scanId];
      return {
        ...obj,
        [scanObj.title.toLowerCase()]: {
          ...scanObj
        }
      };
    }, {})
);

export const makeGetScans = state =>
  createSelector(
    [fromVessels.getScanIdsForVessel],
    scanIds =>
      scanIds !== undefined
        ? scanIds.map(scanId => fromScans.getScanById(state, scanId))
        : []
  );

export const getVesselVisits = (state, vesselId) =>
  fromVessels.getScanIdsForVessel(state, vesselId).reduce((acc, scanId) => {
    acc = acc + fromScans.getScanById(state, scanId).visits;
    return acc;
  }, 0);

export const getNumberOfScansForVessel = (state, vesselId) =>
  fromVessels.getNumberOfScansForVessel(state, vesselId);

export const getScanLinks = (state, vesselId) =>
  makeGetScans(state)(state, vesselId).map(view => ({
    name: view.title,
    path: `/dashboard/vessels/${vesselId}/${view.title}`
  }));

export const selectScanByTitle = (state, vesselId, scanTitle) => {
  const scans = makeGetScans(state)(state, vesselId);
  return (
    scans.find(scan => scan.title.toLowerCase() === scanTitle.toLowerCase()) ||
    ""
  );
};

export const getShareableScanLink = (state, scanId) =>
  fromScans.getScanLink(state, scanId);

export const getScanById = (state, scanId) =>
  fromScans.getScanById(state, scanId);

export const getScanThumbnail = (state, thumbnailId) =>
  fromScans.getThumbnailForScan(state, thumbnailId);
