import React, { PureComponent } from "react";

import { UsersBox } from "../Overview/containers";

class People extends PureComponent {
  render() {
    return (
      <div>
        <UsersBox title="Your People" />;
      </div>
    );
  }
}

export default People;
