import React from "react";
import styled from "styled-components";

import { UI } from "../index";

const ProgressContainer = styled.div`
  width: 100%;
  height: 1px;
  background: ${UI.colors.smoke};
  margin-bottom: 48px;
`;

const Progress = styled.div`
  width: ${props => props.progress}%;
  height: 100%;
  background: ${UI.colors.lightblue};

  transition: width 250ms ease-in-out;
`;

const ProgressBar = ({ progress }) => (
  <ProgressContainer>
    <Progress progress={progress} />
  </ProgressContainer>
);

export default ProgressBar;
