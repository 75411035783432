import styled from "styled-components";

const HorizontalList = styled.div`
  display: flex;
  min-width: 100%;
  flex-direction: row;
  flex-wrap: no-wrap;
  overflow-x: auto;
  overflow-y: hidden;
  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  > div {
    margin-right: 16px;
  }
`;

export default HorizontalList;
