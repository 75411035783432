import { connect } from "react-redux";

import { Search } from "../components";

const mapDispatchToProps = dispatch => ({
  onChange: keyword =>
    dispatch({ type: "SET_SEARCH_KEYWORD", payload: { keyword } })
});

export default connect(
  null,
  mapDispatchToProps
)(Search);
