import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
// preferred way to import (from `v4`). Uses `animate__` prefix.
import "animate.css/animate.min.css";
import "antd/dist/antd.css";
// Alternate way to use classes without prefix like `animated fadeIn`

import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import store from "./global/store";

import UILibrary from "./Pages/UILibrary/UILibrary";

// Routes for different sections
import AppRoutes from "./AppRoutes";
import DashboardRoutes from "./DashboardRoutes";

const App = () => {
  return (
    <>
      <ReactNotification />
      <Provider store={store.store}>
        <PersistGate persistor={store.persistor} loading={null}>
          <Router>
            <Switch>
              <PrivateRoute path="/dashboard" component={DashboardRoutes} />
              <PrivateRoute path="/uilibrary" component={UILibrary} />
              <Route path="/" component={AppRoutes} />
            </Switch>
          </Router>
        </PersistGate>
      </Provider>
    </>
  );
};

export default App;
