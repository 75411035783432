import React, { PureComponent } from "react";
import styled from "styled-components";
import { CSSTransition } from "react-transition-group";
import { getAbsolutePositioning, getPixels } from "../Dashboard/UI/ui";
import { omit } from "../../global/utilities";

class SlideIn extends PureComponent {
  render() {
    const { children, timeout } = this.props;
    return (
      <CSSTransition
        {...omit([timeout, children], this.props)}
        timeout={timeout ? timeout : 250}
        classNames={{
          enter: "slidein-enter",
          enterActive: "slidein-enter-active",
          exit: "slidein-exit",
          exitActive: "slidein-exit-active"
        }}
      >
        <SlideInContainer {...omit([children], this.props)}>
          {children}
        </SlideInContainer>
      </CSSTransition>
    );
  }
}

export default SlideIn;

const SlideInContainer = styled.div`
  position: fixed;
  width: ${props => getPixels(props.width)};
  height: ${props => getPixels(props.height)};
  ${getAbsolutePositioning};
  z-index: 999;
  opacity: 1;
  &.slidein-enter {
    transform: translateX(100%);
  }
  &.slidein-enter-active {
    transform: translateX(0%);
    transition: all ${props => (props.timeout ? props.timeout : 250)}ms
      ease-in-out;
  }
  &.slidein-exit {
    transform: translateX(0%);
  }
  &.slidein-exit-active {
    transform: translateX(100%);
    transition: all ${props => (props.timeout ? props.timeout : 250)}ms
      ease-in-out;
  }
`;
