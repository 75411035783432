import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga";
import { Route } from "react-router-dom";

class GoogleAnalytics extends PureComponent {
  componentDidMount() {
    const { location } = this.props;
    this.logPageChange(location.pathname, location.search);
  }

  componentDidUpdate({ location: prevLocation }) {
    const {
      location: { pathname, search }
    } = this.props;
    const isDifferentPathname = pathname !== prevLocation.pathname;
    const isDifferentSearch = search !== prevLocation.search;

    if (isDifferentPathname || isDifferentSearch) {
      this.logPageChange(pathname, search);
    }
  }

  logPageChange(pathname, search = "") {
    const page = pathname + search;
    const { location } = window;
    const { options } = this.props;
    ReactGA.set({
      page,
      location: `${location.origin}${page}`,
      ...options
    });
    ReactGA.pageview(page);
  }

  render() {
    return null;
  }
}

GoogleAnalytics.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string
  }).isRequired,
  options: PropTypes.object
};

const RouteTracker = () => <Route component={GoogleAnalytics} />;

const init = (options = {}) => {
  const isGAEnabled = true;

  if (isGAEnabled) {
    ReactGA.initialize("UA-125125586-1", {
      debug: "false",
      ...options
    });
  }

  return isGAEnabled;
};

export default {
  GoogleAnalytics,
  RouteTracker,
  init
};
