import React, { PureComponent } from "react";
import styled from "styled-components";
import { withFormik } from "formik";
import Icon from "react-ionicons";

import { Box, UI } from "../../../components/Dashboard";

import Image from "../../../components/Image/Image";
import Crossfade from "../../../components/Crossfade/Crossfade";

import TagForm from "./TagForm";

const { colors } = UI;

const Back = styled(Icon).attrs({
  fontSize: "24px",
  color: colors.white,
  icon: "md-close"
})`
  cursor: pointer;
`;

const PointOfTag = styled.div`
  position: absolute;
  top: ${props => props.y - 12 + "px"};
  left: ${props => props.x - 12 + "px"};
  background-color: ${colors.lightblue};
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid ${colors.white};
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.75);
  transition: all 250ms ease-in-out;
`;

const TagFormContainer = styled(Box)`
  transition: all 250ms ease-in-out;
`;

const Form = withFormik({
  handleSubmit: (values, { props }) => {
    const { onSubmit } = props;
    onSubmit(values);
  },
  validate: (values, { props }) => {
    let errors = {};
    if (!values.title) {
      errors.title = "Required";
    }
    if (
      values.media &&
      !/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(
        values.media
      )
    ) {
      errors.media = "Incorrect url";
    }
    return errors;
  }
})(TagForm);

class TagBox extends PureComponent {
  screenshotRef = React.createRef();

  state = {
    showInfoBox: false,
    showTooltip: false,
    dimensions: { width: 0, height: 0 }
  };

  componentDidMount() {
    const screenshotBoundingBox = this.screenshotRef.current.getBoundingClientRect();
    const width = screenshotBoundingBox.width;
    const height = screenshotBoundingBox.height;
    this.setState({
      showInfoBox: true,
      dimensions: { width: width, height: height }
    });
  }

  componentDidUpdate(prevProps) {
    const { lastPosition, setTooltipOnClick } = this.props;
    if (lastPosition !== prevProps.lastPosition) {
      setTooltipOnClick();
    }
  }

  clearScreenshot = () => {
    const { clearScreenshot } = this.props;
    clearScreenshot();
  };

  closeTooltip = () => {
    const { closeTooltip } = this.props;
    closeTooltip();
  };

  handleClick = event => {
    event.stopPropagation();
  };

  handleMouseMove = event => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
  };

  handleBackButton = event => {
    event.stopPropagation();
    event.preventDefault();
    event.nativeEvent.stopImmediatePropagation();
    this.setState({ showInfoBox: false });
  };

  calculatePlacement = () => {
    const { lastPosition } = this.props;
    const { dimensions } = this.state;
    const tooltipWidth = 368;
    const tooltipHeight = 368;

    let tooltipPosition = {};

    if (lastPosition.x + tooltipWidth < dimensions.width) {
      tooltipPosition = {
        ...tooltipPosition,
        left: lastPosition.x + 16
      };
    } else if (lastPosition.x + tooltipWidth > dimensions.width) {
      tooltipPosition = {
        ...tooltipPosition,
        left: lastPosition.x - tooltipWidth - 16
      };
    } else {
      tooltipPosition = {
        ...tooltipPosition,
        left: lastPosition.x + 16
      };
    }

    if (lastPosition.y + tooltipHeight + 16 < dimensions.height) {
      tooltipPosition = {
        ...tooltipPosition,
        top: lastPosition.y + 16
      };
    } else if (lastPosition.y + tooltipHeight + 16 > dimensions.height) {
      tooltipPosition = {
        ...tooltipPosition,
        top:
          lastPosition.y > tooltipHeight
            ? lastPosition.y - tooltipHeight
            : tooltipHeight - lastPosition.y
      };
    } else {
      tooltipPosition = {
        ...tooltipPosition,
        top: lastPosition.y - tooltipHeight - 16
      };
    }

    return tooltipPosition;
  };

  render() {
    const {
      lastPosition,
      screenShot,
      height,
      width,
      onTagSubmit,
      matterportPosition,
      scanId,
      showTooltip
    } = this.props;
    const tooltipPosition = this.calculatePlacement();
    return (
      <Box
        height={height}
        width={width}
        position="absolute"
        top="0px"
        left="0px"
      >
        <Box
          position="absolute"
          top="0px"
          left="0px"
          onMouseMove={this.handleMouseMove}
          onMouseDown={this.handleMouseMove}
          height={48}
          display="flex"
          overflow="hidden"
          justify="center"
          alignItems="center"
          background="rgba(48,48,49,0.75)"
          paddingHorizontal={1}
        >
          <Back onClick={this.clearScreenshot} />
        </Box>
        <Crossfade in={showTooltip} timeout={250} unmountOnExit>
          <PointOfTag x={lastPosition.x} y={lastPosition.y} />
          <TagFormContainer
            display="flex"
            flexDirection="col"
            justify="center"
            onMouseMove={this.handleMouseMove}
            onMouseDown={this.handleMouseMove}
            onClick={this.handleClick}
            position="absolute"
            top={tooltipPosition.top}
            left={tooltipPosition.left}
            background={colors.darkGray}
            elevated
            paddingHorizontal={3}
            paddingVertical={3}
          >
            <Form
              onSubmit={onTagSubmit}
              cancel={this.clearScreenshot}
              snapshot={screenShot}
              imagePosition={lastPosition}
              matterportPosition={matterportPosition}
              scanId={scanId}
            />
          </TagFormContainer>
        </Crossfade>
        <Box
          position="absolute"
          top="0px"
          left="0px"
          bottom="0px"
          right="0px"
          zIndex={-2}
          _ref={this.screenshotRef}
        >
          <Image alt="screenshot" src={screenShot} />
        </Box>
      </Box>
    );
  }
}

export default TagBox;
