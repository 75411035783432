import React, {useState, useRef, useEffect} from "react";
import { Formik } from "formik";
import axios from "axios";
import { Link } from "react-router-dom";
import {Box, H1, Spinner, UI} from "../../../components/Dashboard";
import styled from "styled-components";
import Icon from "react-ionicons";
import Input from "../../../components/Input/Input";
import TextArea from "../../../components/Input/TextArea";
import Dropdown from "../../../components/Input/Dropdown";
import Button from "../../../components/Button/ButtonRectangle";
import Checkbox from "../../../components/Input/Checkbox";
import { colors } from "../../../components/Dashboard/UI/ui";
import Uploader from "../Library/components/Uploader";
import { Text } from "./../../../components/Dashboard/Typography/Typography";
import { URL_NEW } from "../../../global/utilities";
import { store } from "react-notifications-component";

const SuccessIcon = styled(Icon).attrs({
  color: UI.colors.white,
  icon: "md-checkmark",
})`
  height: 35px;
  width: 24px;
`;

const StyledLink = styled(Link)`
  color: ${colors.white};
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  transition: all 125ms ease;

  &:hover {
    color: ${colors.lightblue};
    text-decoration: none;
  }
`;

const services = [
  { value: "Camera Support" },
  { value: "Control & Operations Rooms" },
  { value: "3D Scan/Model" },
  { value: "Payment/Invoicing" },
  { value: "Accounts & rights access" },
  { value: "Other" },
];
const priority = [{ value: "Normal" }, { value: "High" }, { value: "Urgent" }];

const Zendesk = () => {
  const [currentValues, setCurrentValues] = useState();
  const URL = URL_NEW;
  const [file, setFile] = useState();
  const [isFormFocus, setIsFormFocus] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);

  const handleFileUpload = (files, setFieldValue) => {
    setFieldValue("files", files);
    setFile(files);
  };
  const tok = localStorage.getItem("authToken");

  const submitForm = async (values, { resetForm }) => {
    let formData = new FormData();
    setIsUpdate(true);
    formData.append("service", values.service.value);
    formData.append("email", values.email);
    if (values.files) {
      values.files.forEach(f => {
        formData.append("files", f);
      })
    }
    if (values.priority) {
      formData.append("priority", values.priority.value.toLowerCase());
    }
    formData.append("subject", values.subject);
    formData.append("message", values.message);
    try {
      await axios.post(`${URL}/secured/support`, formData, {
        headers: {
          authorization: tok,
        },
      });
      setIsFormFocus(false);
      store.addNotification({
        title: "Thank you for reaching out!",
        message:
          "An email with your request has been sent to the appropriate service agent and you should have a solution in thepipeline as soon as possible!",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeInRight"],
        animationOut: ["animate__animated", "animate__fadeOutRight"],
        dismiss: {
          duration: 5000,
          delay: 1000,
          showIcon: true,
        },
        slidingEnter: {
          duration: 2000,
        },
      });
      resetForm();
      setIsUpdate(false);
    } catch (e) {
      console.warn("SOMETHING WENT WRONG!");
    }
  };

  const validateForm = (values) => {
    let errors = {};
      if (!values.service) {
        errors.service = "Required";
      }
      if (!values.message) {
        errors.message = "Required";
      }
      if (!values.subject) {
        errors.subject = "Required";
      }
      if (!values.email) {
        errors.email = "Required";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
      ) {
        errors.email = "Invalid email address";
      }
    return errors;
  };

  useEffect(() => {
    if (isFormFocus) {
      window.scrollTo({
        top: document.body.scrollHeight,
        left: 0,
        behavior: 'smooth'
      });
    }
  }, [isFormFocus])

  return (
    <>
      <H1>Vesselfront Support</H1>
      <Text marginBottom={2}>
        Our visualization solutions for mission-critical marine applications
        include Control & Operation Rooms with top-of-the-line display and
        controller capabilities, appropriate camera installations and IP feeds
        which allow headquarters secure and easy live visual access to critical
        areas of the vessel and 3D models and virtual walk through capabilities
        in order for documents, images, videos, notes, comments and files to be
        accessed and shared directly.Let us know how we can serve you best.
      </Text>
      <Formik
        initialValues={{
          services: '',
          email: '',
          subject: '',
          message: '',
          priority: '',
        }}
        validate={validateForm}
        onSubmit={submitForm}
        onChange={({ nextValues }) => { console.log(nextValues); setCurrentValues(nextValues); }}
        render={({
          handleSubmit,
          handleChange,
          setFieldValue,
          handleBlur,
          errors,
          touched,
          isValid,
          values,
        }) => (
          <form onSubmit={handleSubmit} onClick={() => {
            setIsFormFocus(true);
          }}>
            <div className="row justify-content-center">
              <div className="col-lg-6 ">
                <Dropdown
                  label="Please select a service that best describes the problem. *"
                  name="service"
                  options={services}
                  placeholder="Select service"
                  value={ values ? values.service : ''}
                  onChange={setFieldValue}
                  onBlur={handleBlur}
                  error={errors.service && touched.service && errors.service}
                />
                <Input
                  type="email"
                  label="Your email address? *"
                  name="email"
                  value={ values ? values.email : ''}
                  error={errors.email && touched.email && errors.email}
                  placeholder="Enter your email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Input
                  type="text"
                  label="Subject *"
                  name="subject"
                  value={ values ? values.subject : ''}
                  error={errors.subject && touched.subject && errors.subject}
                  placeholder="Enter your subject"
                  onBlur={handleBlur}
                  onChange={handleChange}
                />

                <TextArea
                  label="Tell us more *"
                  placeholder="Type your message..."
                  name="message"
                  value={ values ? values.message : ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.message && touched.message && errors.message}
                />
                <Dropdown
                  label="Please select priority. "
                  name="priority"
                  options={priority}
                  placeholder="Select priority"
                  value={ values ? values.priority : ''}
                  onChange={setFieldValue}
                  onBlur={handleBlur}
                  error={errors.country && touched.country && errors.country}
                />
                <Uploader
                  onFileUpload={(files) => {
                    handleFileUpload(files, setFieldValue);
                  }}
                />
                <Button type="submit" position='relative' disabled={!isValid}>
                  {isUpdate ? (
                    <Spinner small alt />
                  ) :  (
                    "SEND"
                  )}
                </Button>
              </div>
              {/* <div className="col-lg-6 justify-center">



              </div> */}
            </div>
          </form>
        )}
      />
    </>
  );
};

export default Zendesk;
