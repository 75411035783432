import { normalize, schema } from "normalizr";

const asset = new schema.Entity("assets");
const model = new schema.Entity("models");
const modelView = new schema.Entity("modelViews");
const documentSchema = new schema.Entity("documents", {
  asset,
  model,
  modelView
});

const responseSchema = new schema.Array(documentSchema);

const dropdownSchema = new schema.Entity(
  "documents",
  {},
  {
    idAttribute: "id",
    processStrategy: e => ({
      id: e.id,
      displayName: e.displayName ? e.displayName : e.asset.filename,
      assetId: e.assetId
    })
  }
);

export const documentNormalizr = input => normalize(input, responseSchema);
export const singleDocumentNormalizr = input =>
  normalize(input, documentSchema);
export const testSerializr = input => normalize(input, [dropdownSchema]);
