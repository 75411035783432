import axios from "axios";
import { put, take, select, fork, call } from "redux-saga/effects";

import * as actions from "./actions";
import { getToken } from "../auth/reducer";
import { URL_NEW } from "../../global/utilities";

const URL = URL_NEW;

const fetchShareableScanLink = (token, scanId) => {
  return axios.get(`${URL}/secured/vesselView/${scanId}/share`, {
    headers: {
      Authorization: token
    }
  });
};

const createViewNote = (token, data) => {
  const config = {
    headers: {
      Authorization: token,
      "Access-Control-Allow-Origin": "*"
      // "Content-Type": "application/x-www-form-urlencoded"
    }
    // proxy: {
    //   host: "163.172.215.2",
    //   port: 443
    // }
  };

  let formData = new FormData();

  formData.set("snapshotBase64", data.snapshot);
  formData.set("title", data.title);
  formData.set("details", data.details);
  formData.set("media", data.media);
  formData.set("imagePosition", JSON.stringify(data.imagePosition));
  formData.set("matterportPosition", JSON.stringify(data.matterportPosition));
  formData.set("attachment", data.attachment);
  formData.set("assetId", data.assetId);

  return axios(`${URL}/secured/vesselView/${data.scanId}/notes`, {
    method: "POST",
    headers: config.headers,
    data: formData
  });
};

function* getShareableScanLink({ payload }) {
  try {
    const token = yield select(getToken);
    const { scanId } = payload;
    const res = yield call(fetchShareableScanLink, token, scanId);
    yield put(actions.scanLinkSuccess(res.data));
  } catch (e) {
    yield put(actions.scanLinkError(e));
  }
}

export function* watchScans() {
  while (true) {
    const action = yield take("SCAN_LINK_REQUEST");
    yield fork(getShareableScanLink, action);
  }
}

function* saveViewNote(payload) {
  try {
    const token = yield select(getToken);
    const newPayload = {
      ...payload
    };
    const res = yield call(createViewNote, token, newPayload);
    yield put(actions.createViewNoteSuccess(res));
  } catch (e) {
    yield put(actions.createViewNoteError(e));
  }
}

export function* watchSaveViewNote() {
  while (true) {
    const { payload } = yield take(actions.CREATE_VIEW_NOTE.REQUEST);
    yield fork(saveViewNote, payload);
  }
}
