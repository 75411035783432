import React from "react";
import PropTypes from "prop-types";
import { CSSTransition } from "react-transition-group";
import styled from "styled-components";

import { colors } from "../UI/ui";

import Box from "../Boxes/Box";
import SearchInput from "./SearchInput";
import Checkbox from "../../Input/Checkbox";

const categories = [
  "Bridge",
  "Main Deck",
  "Other Deks",
  "Accomodation",
  "Tanks",
  "Kitchens",
  "Boiler",
];

const SearchBox = styled.div`
  position: absolute;
  top: 205px;
  right: 0px;
  z-index: 999;
  opacity: 1;

  &.searchbox-enter {
    transform: translateX(100%);
  }
  &.searchbox-enter-active {
    transform: translateX(0%);
    transition: all ${(props) => (props.timeOut ? props.timeOut : 250)}ms
      ease-in-out;
  }
  &.searchbox-exit {
    transform: translateX(0%);
  }
  &.searchbox-exit-active {
    transform: translateX(100%);
    transition: all ${(props) => (props.timeOut ? props.timeOut : 250)}ms
      ease-in-out;
  }
`;

const Search = ({ categories, theme, isOpen, timeOut, onClose }) => {
  const handleClose = (event) => {
    event.preventDefault();
    onClose();
  };

  const getBackgrounds = () => {
    if (theme === "light") {
      return colors.white;
    }
    return colors.darkGray;
  };

  return (
    <CSSTransition
      in={isOpen}
      timeout={timeOut}
      unmountOnExit
      classNames={{
        enter: "searchbox-enter",
        enterActive: "searchbox-enter-active",
        exit: "searchbox-exit",
        exitActive: "searchbox-exit-active",
      }}
    >
      <SearchBox>
        <Box width={500}>
          <Box
            display="flex"
            direction="column"
            background={getBackgrounds}
            elevated
            width="100%"
          >
            <Box background={getBackgrounds}>
              <SearchInput
                placeholder="Search"
                theme={theme}
                onClose={handleClose}
              />
            </Box>
            <Box
              display="flex"
              direction="column"
              alignItems="start"
              paddingVertical={3}
              paddingHorizontal={3}
              background={getBackgrounds}
            >
              {categories.map((category) => (
                <Checkbox
                  key={category}
                  name={category}
                  theme={theme === "dark" ? "darkAlt" : "light"}
                  dashboard
                >
                  <Checkbox.Input />
                  <Checkbox.Label>{category}</Checkbox.Label>
                </Checkbox>
              ))}
            </Box>
          </Box>
        </Box>
      </SearchBox>
    </CSSTransition>
  );
};

Search.defaultProps = {
  onClose: () => {},
  theme: "light",
  categories: categories,
};

Search.propTypes = {
  theme: PropTypes.string,
  categories: PropTypes.arrayOf(PropTypes.string),
  onClose: PropTypes.func.isRequired,
  timeOut: PropTypes.number,
};

export default Search;
